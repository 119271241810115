import VideoPlayer, {useVideoPlayer} from 'components/videoPlayer';
import {useState} from 'preact/hooks';
import {genClassName} from 'utils';
import css from './videoTutorial.less';

const cls = genClassName(css);

const VideoCard = (props) => {
    const {title, cover, onClick} = props;

    return (
        <div onClick={onClick} className={cls('video-card')} style={{backgroundImage: `url(${cover})`}}>
            <img className={cls('play-btn')} src={require('assets/bml/play.svg').default} />
            <div className={cls('title')}>{title}</div>
        </div>
    );
};

export const VideoTutorial = (props) => {
    const {visible, open, close} = useVideoPlayer();
    const [src, setSrc] = useState('');
    const openVideo = (url) => {
        setSrc(url);
        open();
    };

    return (
        <div className={cls('video-tutorial')}>
            <VideoPlayer src={src} visible={visible} onClose={close} autoPlay />

            {props.videos.map((v) => (
                <VideoCard key={`${v.name}-${v.url}`} cover={v.cover} title={v.name} onClick={() => openVideo(v.url)} />
            ))}
        </div>
    );
};
