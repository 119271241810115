import {useEffect, useState} from 'preact/hooks';
import {getBceAuthInfo} from 'utils';
import {Ioc} from '@baidu/bce-decorators';
import {UDynamicService} from '@baidu/bce-services';

import userinfo from '~/utils/userInfo';
import {header} from '~/locals/wenxin/index';
import {ULoginModal} from './comp/ULoginModal';
import {UActivateModal} from './comp/UActivateModal';
import {ajax, sendMonitor} from '~/utils/util';
import {getEnv} from '~/utils/getEnv';
import {pushProduct2Crm} from '~/utils/pushProduct2Crm';

const offset = 10;
const env = getEnv();

const ArrowIcon = (
    <svg
        width="10px"
        height="10px"
        viewBox="0 0 10 10"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="menu-parent-svg"
    >
        <title>编组 8</title>
        <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="PC导航-胶囊控件展开位置" transform="translate(-356.000000, -25.000000)">
                <g id="编组-6" transform="translate(32.000000, 12.000000)">
                    <g id="导航" transform="translate(190.000000, 0.000000)">
                        <g id="编组-3" transform="translate(0.000000, 6.000000)">
                            <g id="编组-8" transform="translate(134.000000, 7.000000)">
                                <rect id="矩形" x="0" y="0" width="10" height="10"></rect>
                                <polyline
                                    id="路径-3"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    points="1.45243696 3.50317465 5 7.05073769 8.54756304 3.50317465"
                                ></polyline>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
const isConsleA = (ele) => {
    return (
        ele.tagName === 'A' &&
        (ele.getAttribute('href')?.includes('console.bce.baidu.com') ||
            ele.getAttribute('href')?.includes('bcetest.baidu.com'))
    );
};
// 查找console超链接的href
const findConsoleHref = (ele) => {
    if (isConsleA(ele)) {
        return ele.getAttribute('href');
    }
    const parentNode = ele.parentNode;
    if (!parentNode) {
        return null;
    }
    if (isConsleA(parentNode)) {
        return parentNode.getAttribute('href');
    }
    if (parentNode.tagName === 'BODY') {
        return null;
    }
    return findConsoleHref(parentNode);
};

const Header = (props) => {
    const [userInfo, setUserInfo] = useState(null);
    const [isNamed, setIsNamed] = useState(true);
    const {bannerRef} = props;
    const {logo, buttonGroupRight} = header;
    const handleMouseEnter = () => {
        const target = document.querySelector('.menu-popover');
        target.classList.add('hover-cls');
    };

    const handleMouseLeave = () => {
        const target = document.querySelector('.menu-popover');
        target.classList.remove('hover-cls');
    };

    const checkAccount = () => {
        ajax({
            url: '/api/account/status',
            type: 'GET',
            dataType: 'json',
            contentType: 'application/json',
            success: (response) => {
                try {
                    if (typeof response === 'string') {
                        response = JSON.parse(response);
                    }
                    if (response && response.success && response.result && response.result.code === 201) {
                        // 未激活
                        Ioc(UDynamicService).open({
                            component: UActivateModal,
                            props: {
                                maskClosable: false,
                                onOk: () => {
                                    sendMonitor({
                                        category: 'iframe',
                                        name: '激活',
                                        value: '完成激活',
                                        action: 'popup'
                                    });
                                }
                            }
                        });
                        sendMonitor({
                            category: 'iframe',
                            name: '激活',
                            value: '弹出激活',
                            action: 'popup'
                        });
                    }
                } catch (e) {
                    console.error('账号状态获取', e);
                }
            }
        });
    };

    // 登录
    const handleLogin = (e, href) => {
        e.preventDefault();
        Ioc(UDynamicService).open({
            component: ULoginModal,
            props: {
                maskClosable: true,
                href,
                onOk: (info) => {
                    sendMonitor({
                        category: 'iframe',
                        name: '登录',
                        value: `完成登录${info.activateSuccess ? '并完成激活' : ''}`,
                        action: 'popup'
                    });
                    userinfo.init(
                        () => {
                            if (info.activateSuccess || info.regSuccess) {
                                // 直接在iam的登录流程里完成了激活，不需要再打开激活弹层，直接推送线索
                                // 如果是UC注册，一定会激活，直接推送线索
                                pushProduct2Crm();
                            } else {
                                checkAccount();
                            }
                        },
                        false,
                        true
                    );
                },
                onCancel: () => {
                    userinfo.init(
                        (info) => {
                            info.hasLogin && checkAccount();
                        },
                        false,
                        true
                    );
                }
            }
        });
        sendMonitor({
            category: 'iframe',
            name: '登录',
            value: '弹出登录',
            action: 'popup'
        });
    };

    // 获取用户登录信息
    useEffect(() => {
        userinfo.init((info) => {
            info.hasLogin && setUserInfo(info);
            // loginSuccess && loginSuccess(info);
        });
    }, []);

    useEffect(() => {
        const loginHref = `https://login.bce${env === 'SANDBOX' ? 'test' : ''}.baidu.com/${
            props.needQualify ? '?needQualify=1' : ''
        }`;
        // 如果是跳转到console页面，打开登录弹窗后再跳转
        const clickHandler = (e) => {
            const consoleHref = findConsoleHref(e.target);
            if (consoleHref) {
                e.preventDefault();
                Ioc(UDynamicService).open({
                    component: ULoginModal,
                    props: {
                        maskClosable: true,
                        href: loginHref,
                        onOk: (info) => {
                            if (info.activateSuccess || info.regSuccess) {
                                userinfo.init(
                                    () => {
                                        pushProduct2Crm().finally(() => {
                                            location.href = consoleHref;
                                            // window.open(consoleHref, '_blank');
                                        });
                                    },
                                    false,
                                    true
                                );
                            } else {
                                location.href = consoleHref;
                            }
                        }
                    }
                });
            }
        };
        // if (userInfo) {
        //     ajax({
        //         url: '/api/account/real_name_info',
        //         type: 'POST',
        //         dataType: 'json',
        //         success: (res) => {
        //             try {
        //                 if (typeof res === 'string') {
        //                     res = JSON.parse(res);
        //                 }
        //                 setIsNamed(res.result.primePassFlag);
        //             } catch (error) {
        //                 setIsNamed(false);
        //             }
        //         },
        //         fail: (e) => {
        //             setIsNamed(false);
        //         }
        //     });
        // } else {
        //     document.addEventListener('click', clickHandler);
        // }
        if (!userInfo?.hasLogin) {
            document.addEventListener('click', clickHandler);
        }
        return () => {
            document.removeEventListener('click', clickHandler);
        };
    }, [userInfo]);

    useEffect(() => {
        const header = document.querySelector('.wx-header');
        const headerWrapper = document.querySelector('.wx-header-wrapper');
        const banner = bannerRef.current.base.getBoundingClientRect();
        const handleScrollFn = () => {
            const body = document.querySelector('body');
            // 弹窗登录框出现时，scrollTop为0；导致header位置不对
            if (body.classList.contains('header-body-no-scroll')) {
                return;
            }
            var scrollTop = document.documentElement.scrollTop;
            if (scrollTop > offset) {
                header.classList.add('wx-header-fixed');
            } else {
                header.classList.remove('wx-header-fixed');
            }
            if (scrollTop >= banner.height - 100 && scrollTop < banner.height) {
                header.style = `position: absolute;
                    top: ${12 - (scrollTop - banner.height + 100)}px`;
            } else if (scrollTop >= banner.height) {
                header.style = `position: absolute;
                    top: -62px`;
            } else if (scrollTop === 0) {
                header.style = 'position: fixed;top: 0';
            } else {
                header.style = 'position: fixed;top: 12px';
            }

            if (scrollTop > 10) {
                headerWrapper.style = 'height: 0px';
            } else {
                headerWrapper.style = 'height: 60px';
            }
        };
        window.addEventListener('scroll', handleScrollFn);
    }, []);
    return (
        <div className="no_rem-wx-header wx-header-wrapper">
            <div className="wx-header">
                <div className="header-left">
                    <img src={logo} alt="logo" className="logo" />
                </div>
                <div className="header-right">
                    {buttonGroupRight.map((item) => {
                        // 已登录态，显示用户名
                        if (item.id === 1 && userInfo?.hasLogin && userInfo.displayName) {
                            return (
                                <div
                                    className="header-user"
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <div className="menu-root">
                                        <span className="name-logo name-logo-dark">
                                            {userInfo?.displayName?.slice(0, 1)}
                                        </span>
                                        <span className="menu-parent-title-user">{userInfo?.displayName}</span>
                                        {ArrowIcon}
                                        <div className="menu-popover">
                                            <div className="menu-popover-box">
                                                <a
                                                    className="menu-popover-item"
                                                    href={`https://login.bce${
                                                        env === 'SANDBOX' ? 'test' : ''
                                                    }.baidu.com/logout?redirect=${window.location.href}`}
                                                    rel="noopener noreferrer"
                                                >
                                                    退出
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                        // 登录态没有注册按钮
                        if (item.id === 2 && userInfo?.hasLogin) {
                            return;
                        }
                        return (
                            <a
                                key={item.id}
                                href={item.href}
                                target={item.id !== 0 ? '_self' : '_blank'}
                                rel="noopener noreferrer"
                                className={item.class}
                                {...item.trackInfo}
                                onClick={(e) => {
                                    if (item.id === 1) {
                                        const url = `https://login.bce${env === 'SANDBOX' ? 'test' : ''}.baidu.com/${
                                            props.needQualify ? '?needQualify=1' : ''
                                        }`;
                                        // 登录
                                        handleLogin(e, url);
                                    } else if (item.id === 2) {
                                        const url = `https://login.bce${
                                            env === 'SANDBOX' ? 'test' : ''
                                        }.baidu.com/new-reg`;
                                        // 注册
                                        handleLogin(e, url);
                                    }
                                }}
                            >
                                {item.text}
                            </a>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Header;
