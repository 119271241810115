import {useEffect, useState} from 'preact/hooks';
import {Swiper, SwiperSlide} from 'swiper/react';
import style from './BannerSwiper.less';

export function BannerSwiper({bmlBanner}) {
    const [swiperConfig] = useState({
        slidesPerView: 1,
        loop: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        pagination: {
            clickable: true,
            type: 'bullets',
            bulletClass: style['mb-pagination-bullet'],
            bulletActiveClass: style['mb-pagination-bullet-active']
        }
    });
    return (
        <div className={style['bml-banner']}>
            <Swiper {...swiperConfig}>
                {bmlBanner.map(({href, bg}) => (
                    <SwiperSlide key={href}>
                        <a href={href}>
                            <img src={bg} />
                        </a>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}
