import style from './Features.less';
const features = [
    {
        title: '算力性价比高',
        num: '6',
        h2: '节点分布式训练',
        ls: [
            '支持多机多卡分布式训练',
            '提供丰富算力机器资源选型',
            '按需获取资源，灵活便捷'
        ]
    },
    {
        title: '建模方式丰富',
        num: '5',
        h2: '类训练方式灵活使用',
        ls: [
            '预置模型调参及作业建模方式',
            '超越原生Jupyter Notebook的云端开发环境',
            '可视化建模、自动化建模等高级建模方式'
        ]
    },
    {
        title: '模型精度高',
        num: '80+',
        h2: '百度自研高精度预训练模型',
        ls: [
            '百度独家超大规模数据预训练模型及文心大模型',
            '百度独家飞桨自研高精度模型',
            '自动超参搜索、自动数据增强、不平衡数据处理'
        ]
    },
    {
        title: '标注成本低',
        num: '80%+',
        h2: '采集/标注效率提升',
        ls: [
            '在线标注、智能标注与多人标注大幅提升效率',
            '首个端云一体的数据采集方案',
            '强大的数据清洗、数据质检机制'
        ]
    },
    {
        title: '预测服务高效',
        num: '99.99%',
        h2: '服务级别协议覆盖',
        ls: [
            '支持弹性伸缩、自动启停、流量切分',
            '开放的产品功能接口，业务应用无缝对接',
            '服务编排、流控管理等高级功能，降低成本'
        ]
    },
    {
        title: '适配硬件广泛',
        num: '30+',
        h2: 'AI芯片适配',
        ls: [
            '公有云部署，支持高并发请求 ',
            '边缘计算，极致推理性能',
            '6款软硬一体方案，高中低全矩阵覆盖'
        ]
    }
];

const Features = ({name} = {}) => {
    return (
        <div className={style['enp-features']}>
            <div className={style['enp-features-h1']}>BML兼具性能与性价比</div>
            <div className={style['enp-features-container']}>
                {features.map(({title, num, h2, ls}) => {
                    return (
                        <div className={style['enp-features-panel']} key={title}>
                            <div className={style['enp-features-panel-title']}>{title}</div>
                            <div>
                                <div className={style['enp-features-panel-h1']}>
                                    <div className={style['enp-features-panel-num']}>{num}</div>
                                    <div className={style['enp-features-panel-h2']}>{h2}</div>
                                </div>
                                <div className={style['enp-features-panel-ls']}>
                                    {ls.map((x) => (
                                        <div key={x}>{x}</div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

Features.displayName = 'Features';

export default Features;
