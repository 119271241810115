import {footer} from '~/locals/wenxin/index';

const Footer = () => {
    const {about, resources, service, qrCode} = footer;
    return (
        <div className="no_rem-wx-footer">
            <div className="footer-container">
                <div className="footer-link">
                    <div className="about-us">
                        <h3>{about.title}</h3>
                        {about.des.map((item) => {
                            return <p key={item.id}>{item.text}</p>;
                        })}
                    </div>
                    <div className='footer-right'>
                        <div className="resources">
                            <h4>{resources.title}</h4>
                            <ul>
                                {resources.list.map((item) => {
                                    return (
                                        <li key={item.id}>
                                            <a href={item.href} target="_blank" rel="noreferrer" {...item.trackInfo}>
                                                {item.text}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="service">
                            <h4>{service.title}</h4>
                            <ul>
                                {service.list.map((item) => {
                                    return (
                                        <li key={item.id}>
                                            {item.href ? (
                                                <a
                                                    href={item.href}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    {...item.trackInfo}
                                                >
                                                    {item.text}
                                                </a>
                                            ) : (
                                                <span>{item.text}</span>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="QR-code">
                            <img src={qrCode} alt="二维码" />
                        </div>
                    </div>
                </div>
                <div className="copy-right">
                    <span> © {new Date().getFullYear()} Baidu 使用百度前必读</span>
                    <span> 京ICP证030173号</span>
                    {/* <a
                        className="space"
                        target="_blank"
                        rel="noreferrer"
                        href="http://www.baidu.com/duty/"
                        data-track-category="千帆产品页PC-底部导航"
                        data-track-name="底部栏"
                        data-track-value="使用百度前必读"
                    >
                        © {new Date().getFullYear()} Baidu 使用百度前必读
                    </a>
                    <a
                        className="space"
                        target="_blank"
                        rel="noreferrer"
                        href="https://beian.miit.gov.cn/"
                        data-track-category="千帆产品页PC-底部导航"
                        data-track-name="底部栏"
                        data-track-value="京ICP证030173号"
                    >
                        京ICP证030173号
                    </a> */}
                </div>
            </div>
        </div>
    );
};

export default Footer;
