// import {useEffect, useState, useRef} from 'preact/hooks';
// import {Swiper, SwiperSlide} from 'swiper/react';

export default function TypicalCase(props) {
    const {typicalCase} = props;
    return (
        <div className="typicalCase" id="typicalCase">
            <h2 className="typicalCase-title">
                预置典型应用范式，构建<span>下一代智能应用</span>
            </h2>
            <div className="typicalCase-main">
                <div className="typicalCase-main-left">
                    <div className="typicalCase-main-left-top typicalCase-main-top">
                        <p className="typicalCase-main-title">{typicalCase[0].title}</p>
                        <p className="typicalCase-main-content no_rem_fs_16">{typicalCase[0].content}</p>
                    </div>
                    <div className="typicalCase-main-bottom typicalCase-left-bottom">
                        <div className="typicalCase-main-bottom-item">
                            <p className="typicalCase-main-bottom-item-title">{typicalCase[2].title}</p>
                            <p className="typicalCase-main-bottom-item-content no_rem_fs_16">
                                {typicalCase[2].content}
                            </p>
                        </div>
                        <div className="typicalCase-main-bottom-item">
                            <p className="typicalCase-main-bottom-item-title">{typicalCase[3].title}</p>
                            <p className="typicalCase-main-bottom-item-content no_rem_fs_16">
                                {typicalCase[3].content}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="typicalCase-main-right">
                    <div className="typicalCase-main-right-top typicalCase-main-top">
                        <p className="typicalCase-main-title">{typicalCase[1].title}</p>
                        <p className="typicalCase-main-content no_rem_fs_16">{typicalCase[1].content}</p>
                    </div>
                    <div className="typicalCase-main-bottom typicalCase-right-bottom">
                        <div className="typicalCase-main-bottom-item typicalCase-medical">
                            <p className="typicalCase-main-bottom-item-title">{typicalCase[4].title}</p>
                            <p className="typicalCase-main-bottom-item-content no_rem_fs_16">
                                {typicalCase[4].content}
                            </p>
                        </div>
                        <div className="typicalCase-main-bottom-item typicalCase-financial">
                            <p className="typicalCase-main-bottom-item-title">{typicalCase[5].title}</p>
                            <p className="typicalCase-main-bottom-item-content no_rem_fs_16">
                                {typicalCase[5].content}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
