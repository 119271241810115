/*
 * @FilePath: consoleModal.js
 */
export const consoleModaltitle = '选择模型类型';
export const parentTypes = ['nlp', 'vision', 'audio', 'video', 'structured_data', 'retail', 'ocr', 'vqa'];

export const consoleType = [
    {
        name: '图像分类',
        icon: require('assets/modal/image-distribute.png').default,
        type: 'vision',
        href: process.env.IMAGE_CONTROL,
        local: false
    },
    {
        name: '物体检测',
        icon: require('assets/modal/image-object.png').default,
        type: 'vision',
        href: process.env.DETECTION,
        local: false
    },
    {
        name: '图像分割',
        icon: require('assets/modal/image-division.png').default,
        type: 'vision',
        href: process.env.SEGMENT,
        local: false
    },
    {
        name: '文本分类-单标签',
        icon: require('assets/modal/text-label.png').default,
        type: 'nlp',
        href: process.env.TEXT,
        local: false
    },
    {
        name: '文本分类-多标签',
        icon: require('assets/modal/text-labels.png').default,
        type: 'nlp',
        href: process.env.TEXTMULTI,
        local: false
    },
    {
        name: '短文本相似度',
        icon: require('assets/modal/text-similar.png').default,
        type: 'nlp',
        href: process.env.TEXTSIM,
        local: false
    },
    {
        name: '情感倾向分析',
        icon: require('assets/modal/text-emotion.png').default,
        type: 'nlp',
        href: process.env.SENTIMENT,
        local: false
    },
    {
        name: '文本创作',
        icon: require('assets/modal/text-ernie.png').default,
        type: 'nlp',
        href: process.env.ERNIE,
        local: false,
        isNew: true
    },
    {
        name: '文本实体抽取',
        icon: require('assets/modal/text-extract.png').default,
        type: 'nlp',
        href: process.env.TEXTENTITYEXTRACT,
        local: false
    },
    {
        name: '文本实体关系抽取',
        icon: require('assets/modal/relation-text-extract.png').default,
        type: 'nlp',
        href: process.env.RELATIONTEXTENTITYEXTRACT,
        local: false
    },
    {
        name: '评论观点抽取',
        icon: require('assets/modal/comment-extract.svg').default,
        type: 'nlp',
        href: process.env.COMMENTEXTRACT,
        local: false,
    },
    {
        name: '语音识别',
        icon: require('assets/modal/audio-distinguish.png').default,
        type: 'audio',
        href: process.env.SOUNDIDENTIFY,
        local: false
    },
    {
        name: '声音分类',
        icon: require('assets/modal/audio-classification.png').default,
        type: 'audio',
        href: process.env.SOUNDCLS,
        local: false
    },
    {
        name: '视频分类',
        icon: require('assets/modal/video-classification.png').default,
        type: 'video',
        href: process.env.VIDEOCLS,
        local: false
    },
    {
        name: '目标跟踪',
        icon: require('assets/modal/target-track.svg').default,
        type: 'video',
        href: process.env.TRACK,
        local: false
    },
    {
        name: '表格数据预测',
        icon: require('assets/modal/structdata.png').default,
        type: 'structured_data',
        href: process.env.ML,
        local: false
    },
    {
        name: '时序预测',
        icon: require('assets/modal/tsf.svg').default,
        type: 'structured_data',
        href: process.env.TSF,
        local: false
    },
    {
        name: '零售行业版',
        icon: require('assets/modal/retail.png').default,
        type: 'retail',
        href: process.env.COMMODITYIDENTIFICATION,
        local: false
    },
    {
        name: 'OCR',
        icon: require('assets/modal/ocr.svg').default,
        type: 'ocr',
        href: process.env.OCR,
        local: false
    },
    {
        name: '图文匹配',
        icon: require('assets/modal/vqa.png').default,
        type: 'vqa',
        href: process.env.VQA,
        local: false,
        isNew: true
    },
    {
        name: '飞桨EasyDL',
        icon: require('assets/modal/paddle.png').default,
        type: 'vision',
        href: process.env.PADDLE,
        local: true,
        isNew: true
    }
];

export const consoleLegend = [
    {name: '图像', value: '38C25D', code: 'vision', suffix: {name: '文心大模型', class: 'wenxin'}},
    {name: '文本', value: '6B63FA', code: 'nlp', suffix: {name: '文心大模型', class: 'wenxin'}},
    {name: '语音', value: '1978FF', code: 'audio'},
    {name: '视频', value: '00B8FF', code: 'video'},
    {name: '结构化数据', value: 'FF6180', code: 'structured_data'},
    {name: '行业版', value: 'FF993F', code: 'retail'},
    {name: '跨模态', value: '02DFDF', code: 'vqa', suffix: {name: '文心大模型', class: 'wenxin'}},
    {name: 'OCR', value: 'F479FB', code: 'ocr'}
];
