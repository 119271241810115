import {useState} from 'preact/hooks';

import VideoPlayer, {useVideoPlayer} from 'components/videoPlayer';
import {AButtonGroup, AButton} from 'components/base/aButton';
import {genClassName, innerHtml} from 'utils';
import style from './CardAD.less';

const classname = genClassName(style);


export function CardAD({cardAD, buttonGroup}) {
    const [videoSrc, setVideoSrc] = useState('');
    const {visible, open, close} = useVideoPlayer();

    const visibleVideo = (src) => {
        setVideoSrc(src);
        open();
    };

    const data = cardAD.ad.length > 2 ? [cardAD.ad.slice(0, 2), cardAD.ad.slice(2)] : [cardAD.ad];
    return (
        <div className={style.cardAD}>
            <div className={style['cardAD-header']}>
                <div>
                    <h3>{cardAD.title}</h3>
                    <img src={cardAD.iconVideo} alt="cardAD.title" onClick={() => visibleVideo(cardAD.video)} />
                </div>
                <div className={style['cardAD-btn']}>
                    <AButtonGroup offset={20} align='right'>
                        {buttonGroup.map(({name, ...prop}) => {
                            return <AButton {...prop} key={name}>{name}</AButton>;
                        })}
                    </AButtonGroup>
                </div>
            </div>
            <div className={style['cardAD-con']} style={{backgroundImage: `url(${cardAD.bg})`}}>
                <div className={style['cardAD-con-intro']}>
                    {
                        cardAD.intro.map(({img, title, href, target}) => (
                            <a href={href} target={target} key={title}>
                                <img src={img} alt={title} />
                                <p>{title}</p>
                            </a>
                        ))
                    }
                </div>
                <div className={style['cardAD-con-ad']}>
                    {
                        data[0].map(({tag, desc, href}) => (
                            <a href={href} target="_blank" key={tag} rel="noreferrer">
                                <span
                                    style={{backgroundImage: `url(${cardAD.txtbg})`}}
                                    className={style['cardAD-con-ad-tag']}
                                >
                                    {tag}
                                </span>
                                <span>{desc}</span>
                            </a>
                        ))
                    }
                </div>
                <div className={style['cardAD-con-ad']}>
                    {
                        data[1].map(({tag, desc, href}) => (
                            <a href={href} target="_blank" key={tag} rel="noreferrer">
                                <span
                                    style={{backgroundImage: `url(${cardAD.txtbg})`}}
                                    className={style['cardAD-con-ad-tag']}
                                >
                                    {tag}
                                </span>
                                <span>{desc}</span>
                            </a>
                        ))
                    }
                </div>
            </div>
            <VideoPlayer src={videoSrc} visible={visible} onClose={close} autoplay />
        </div>
    );
}