import ConsoleModal, {useConsoleModal} from 'components/consoleModal';

export default function GetHeaderFooterLayout(props) {
    const [consoleModalVisible, consoleModalOnClose] = useConsoleModal();
    const {children} = props;
    return (
        <>
            <div id="static" className="getHF-container">
                {children}
            </div>
            <ConsoleModal visible={consoleModalVisible} onClose={consoleModalOnClose} />
        </>
    );
}
