import {ComponentChildren} from 'preact';
import {useEffect, useState, useRef, useMemo} from 'preact/hooks';
import {createPortal} from 'preact/compat';
import {createPopper, Placement} from '@popperjs/core';
import throttle from 'lodash/throttle';
import {useGlobalTooltipContext} from './GlobalTooltipContext';

type IProps = {
    children: ComponentChildren;
    popperContent: ComponentChildren;
    placement?: Placement;
    className?: string;
    popperClassName?: string;
    trigger?: 'hover' | 'click';
    popperAppendToBody?: Boolean;
};

export default function Tooltip(props: IProps) {
    const {
        children,
        popperContent,
        placement = 'top',
        className = '',
        popperClassName = '',
        trigger = 'hover',
        popperAppendToBody = true
    } = props;
    const [popoverVisible, setPopoverVisible] = useState(false);
    const referenceElementRef = useRef<HTMLDivElement>(null);
    const popperElementRef = useRef<HTMLDivElement>(null);

    const container = useGlobalTooltipContext();

    useEffect(() => {
        let popperInstance = null;
        if (popoverVisible && referenceElementRef.current && popperElementRef.current && container) {
            popperInstance = createPopper(referenceElementRef.current, popperElementRef.current, {
                placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8]
                        }
                    }
                ]
            });
            popperInstance.forceUpdate();
        }
        return () => {
            if (popperInstance) {
                popperInstance.destroy();
                popperInstance = null;
            }
        };
    }, [popoverVisible, placement, container]);

    const timer = useRef(void 0);
    const handleMouseEnter = throttle(() => {
        if (trigger !== 'hover') {
            return;
        }
        if (timer) {
            clearTimeout(timer.current);
        }
        setPopoverVisible(true);
    }, 100);
    const handleMouseLeave = () => {
        if (trigger !== 'hover') {
            return;
        }
        timer.current = setTimeout(() => {
            setPopoverVisible(false);
            timer.current = void 0;
        }, 50);
    };

    const togglePopover = () => {
        setPopoverVisible(prev => !prev);
    };
    const handleOutsideClick = (event) => {
        if (trigger !== 'click') {
            return;
        }
        if (referenceElementRef.current && referenceElementRef.current.contains(event.target)) {
            return;
        }
        if (popperElementRef.current && !popperElementRef.current.contains(event.target)) {
            togglePopover();
        }
    };
    useEffect(() => {
        if (trigger !== 'click') {
            return;
        }
        if (popoverVisible) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [popoverVisible]);

    const triggerElement = useMemo<ComponentChildren>(() => {
        switch (trigger) {
            case 'hover':
                return (
                    <div
                        ref={referenceElementRef}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className={`tooltip-popper-trigger ${className}`}
                    >
                        {children}
                    </div>
                );
            case 'click':
                return (
                    <div
                        ref={referenceElementRef}
                        onClick={togglePopover}
                        className={`tooltip-popper-trigger ${className}`}
                    >
                        {children}
                    </div>
                );
            default:
                return <div ref={referenceElementRef}>{children}</div>;
        }
    }, [className, children]);

    const popper = useMemo(() => {
        const content = (
            <div
                ref={popperElementRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={`tooltip-popper ${popperClassName} ${popoverVisible ? '' : 'tooltip-popper-hidden'}`}
            >
                {popperContent}
                <div className="arrow" data-popper-arrow></div>
            </div>
        );
        return container && popperAppendToBody ? createPortal(content, container) : content;
    }, [popperAppendToBody, popperClassName, popperContent, container, popoverVisible]);

    return (
        <>
            <div className="tooltip-popper-trigger-container">{triggerElement}</div>
            {popper}
        </>
    );
}
