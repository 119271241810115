import Banner from 'components/banner';

import localData from 'locals/easydl/ocr';
import Affix from 'components/affix';
import Section, {
    TrackType,
    WithComponent,
    CardList,
    UseStep,
    HighLightNumber,
    TableCase,
    ProductForm
} from 'components/sections';
import useGenerateLayout from 'utils/useGenerateLayout';

const SubSection = WithComponent(Section)({
    subSection: true
});

export default function OCR() {
    useGenerateLayout(true);
    const {config, banner, feature, step, scene, recommend, productForm} = localData;
    return (
        <TrackType.Provider value={config}>
            <Banner {...banner} />
            <SubSection title={feature.title}>
                <HighLightNumber data={feature.data} />
            </SubSection>
            <SubSection title={step.title} desc={step.desc}>
                <UseStep data={step.data} />
            </SubSection>
            <SubSection title={scene.title}>
                <TableCase data={scene.data} />
            </SubSection>
            <SubSection title={productForm.title}>
                <ProductForm data={productForm.data} />
            </SubSection>
            <SubSection title={recommend.title}>
                <CardList {...recommend} />
            </SubSection>
            <Affix target={'bml'} />
        </TrackType.Provider>
    );
}
