import {TrackType} from '~/components/sections';
import {config} from '~/locals/wenxin/index';
import '~/style/wenxin/index.less';
import {useEffect} from 'preact/hooks';
import BasePage from './basePage';

export default function Wenxin() {
    return (
        <TrackType.Provider value={config}>
            <BasePage />
        </TrackType.Provider>
    );
}
