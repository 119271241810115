/**
 * Created by shenqiqi on 2018/12/14.
 *
 * @file js/help/userInfo.js
 * @author leeight
 */
import {getParamValue, getCookie, setCookie, escapeHtml, ajax, delCookie} from './util';
// 用户状态控制js
const Index = () => {
    if (typeof window !== 'undefined') {
        // 除bce域外的其他接口都启动v2
        var domains = ['bce.baidu.com', 'bcetest.baidu.com'];
        var enable_account_v2 =
            domains.indexOf(location.host) === -1 &&
            location.host.indexOf('localhost') === -1 &&
            !(location.port && location.search.indexOf('env=dev') > -1);

        var exports = {};
        const REQUEST_BEFORE = 'request_bofore'; // 未发送请求
        const REQUESTED = 'requested'; // 已经发送请求
        const REQUESTING = 'requesting'; // 请求中
        let status = REQUEST_BEFORE; // 接口调用状态
        let successHandlers = []; // 成功处理函数
        const info = {}; // 用户信息

        // 一般外部域名为 .baidu.com ，内部域名为 .baidu-int.com；但有的不符合这个规律，则结合domainList来综合判断
        var domainList = [
            'cloudtest.baidu.com',
            'qasandbox.bcetest.baidu.com',
            'wenxinworkshop-test.now.baidu.com',
            'qianfan-portal-sandbox.baidu-int.com'
        ];
        const onlineDomainList = ['aipe-eng.baidu-int.com', 'qianfan.cloud.baidu.com'];
        var apiHost =
            (domainList.indexOf(location.host) === -1 && location.host.indexOf('.baidu.com') > -1) ||
            onlineDomainList.indexOf(location.host) > -1
                ? 'bce.baidu.com'
                : 'bcetest.baidu.com';
        var options = enable_account_v2
            ? {url: '//' + apiHost + '/api/account/v2/displayName', dataType: 'jsonp', withCredentials: true}
            : {url: '/api/account/displayName', type: 'POST', dataType: 'json'};

        exports.init = function (loginSuccessCb, isImmediately = false, once = false) {
            // 如果页面的url里面有 ?t= 的参数，就加载一个 apiHost 域名下面的页面，把参数
            // 传递过去，后台会自己完成设置 cookie 的逻辑
            loginSuccessCb && successHandlers.push({loginSuccessCb, once});
            if (status === REQUESTED && isImmediately) {
                loginSuccessCb(info);
                return;
            }
            if (status === REQUESTING) {
                return;
            }
            status = REQUESTING;
            var localUriTrack = getParamValue(window.location.href, 'track');
            if (enable_account_v2 && localUriTrack) {
                var iframeEle = document.createElement('iframe');
                const query = location.search ? location.search : '?' + location.hash.split('?')[1];
                var iframeUrl = '//' + apiHost + '/helper/x.html' + query + '&.stamp=' + new Date().getTime();
                iframeEle.src = iframeUrl;
                iframeEle.style.display = 'none';
                document.body.appendChild(iframeEle);
            }

            let callBackName = 'headerDisplayNameCallBack' + new Date().getTime();

            window[callBackName] = function (e) {
                status = REQUESTED;
                try {
                    if (typeof e === 'string') {
                        e = JSON.parse(e);
                    }
                } catch (ex) {
                    e = {result: {}};
                }
                var hasLogin = e.result.hasLogin === true;

                if (enable_account_v2) {
                    // XXX 既然每次都是从 jsonp 获取登录信息，那么把 cookie 写入到
                    // cloud.baidu.com 下面有啥意义呢？
                    // 貌似主要的意义是 access_log 里面能有，不过好用也没啥用
                    for (var key in e.result.cookies) {
                        if (!e.result.cookies.hasOwnProperty(key)) {
                            continue;
                        }

                        // 过一段儿时间，同步 tracking cookie 的逻辑就可以去掉了
                        // 这里主要是保证老用户的 tracking cookie 从 bce 同步 cloud
                        var isTracking = key === 'CAMPAIGN_TRACK' || key === 'CAMPAIGN_TRACK_TIME';
                        if (isTracking && getCookie(key)) {
                            // 如果已经有了，就不需要覆盖了
                            continue;
                        }
                        var value = e.result.cookies[key];
                        var host = window.location.hostname;
                        if (value) {
                            if (isTracking) {
                                // 好像 java 那边也是大概 90 天的有效期??
                                setCookie(key, value, host, 90);
                            } else {
                                setCookie(key, value, host);
                            }
                        } else if (!isTracking) {
                            delCookie(key, host);
                        }
                    }
                }

                const displayName = escapeHtml(e.result.displayName || '');
                info.displayName = displayName;
                info.accountId = e.result.accountId;
                info.hasLogin = hasLogin;
                successHandlers.forEach((item) => {
                    item.loginSuccessCb && item.loginSuccessCb(info);
                });
                successHandlers = successHandlers.filter((item) => !item.once);

                window[callBackName] = undefined;
            };

            options.success = enable_account_v2 ? callBackName : window[callBackName];
            // options.fail = function (status) {}

            ajax(options);
        };

        return exports;
    }
};

const result = Index();
export default result;
