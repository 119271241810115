export const config = {
    trackType: '百度智能云千帆大模型平台',
    layout: 'wenxin',
    page: 'wenxin'
};

export const notice = {
    title: '最新通知',
    desc: '千帆大模型平台上线文心大模型ERNIE 4.0 Turbo，速度更快，效果更好'
};

export const header = {
    logo: require('assets/wenxin/logo.png').default,
    buttonGroupRight: [
        {
            text: '返回百度智能云',
            href: 'https://cloud.baidu.com/',
            type: 'primary',
            class: 'back-btn',
            id: 0,
            trackInfo: {
                'data-track-category': '千帆产品页PC-顶部导航',
                'data-track-name': '返回百度智能云',
                'data-track-value': 'EMPTY'
            }
        },
        {
            text: '登录',
            href: 'https://login.bce.baidu.com/',
            // href: 'https://login.bce.baidu.com/?redirect=https%3A%2F%2Fcloud.baidu.com%2Fproduct%2Fwenxinworkshop',
            class: 'login-btn',
            id: 1,
            trackInfo: {
                'data-track-category': '千帆产品页PC-顶部导航',
                'data-track-name': '登录',
                'data-track-value': 'EMPTY'
            }
        },
        {
            text: '免费注册',
            href: 'https://login.bce.baidu.com/new-reg?tpl=bceplat&amp;from=portal',
            class: 'regist-btn',
            id: 2,
            trackInfo: {
                'data-track-category': '千帆产品页PC-顶部导航',
                'data-track-name': '免费注册',
                'data-track-value': 'EMPTY'
            }
        }
    ]
};

export const banner = {
    title: '百度智能云千帆大模型平台',
    des: '文心大模型企业级服务唯一入口，一站式企业级大模型平台，提供先进的生成式AI生产及应用全流程开发工具链',
    buttonGroup: [
        {
            text: '立即使用',
            href: 'https://console.bce.baidu.com/qianfan/overview',
            type: 'primary',
            class: 'primary-btn',
            id: 0,
            trackInfo: {
                'data-track-category': '千帆产品页PC-banner',
                'data-track-name': '主按钮',
                'data-track-value': '立即使用'
            },
            trackInfo_m: {
                'data-track-category': '千帆产品页wise-banner',
                'data-track-name': '主按钮',
                'data-track-value': '立即使用'
            }
        },
        {
            text: '在线体验',
            href: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest',
            // href: 'https://qasandbox.bcetest.baidu.com/qianfan/modelcenter/model/buildIn/list',
            type: 'default',
            class: 'default-btn',
            id: 1,
            trackInfo: {
                'data-track-category': '千帆产品页PC-banner',
                'data-track-name': '主按钮',
                'data-track-value': '在线体验'
            },
            trackInfo_m: {
                'data-track-category': '千帆产品页wise-banner',
                'data-track-name': '主按钮',
                'data-track-value': '在线体验'
            }
        },
        {
            text: '企业咨询',
            href: 'https://cloud.baidu.com/survey/qianfanmb.html',
            type: 'default',
            class: 'default-btn',
            id: 2,
            trackInfo: {
                'data-track-category': '千帆产品页PC-banner',
                'data-track-name': '主按钮',
                'data-track-value': '企业咨询'
            },
            trackInfo_m: {
                'data-track-category': '千帆产品页wise-banner',
                'data-track-name': '主按钮',
                'data-track-value': '企业咨询'
            }
        }
    ]
};

export const productTitle = [
    {
        id: 0,
        title: '产品架构',
        hash: 'productFeatures'
    },
    {
        id: 1,
        title: '核心功能',
        hash: 'fucFeatures'
    },
    {
        id: 2,
        title: '产品优势',
        hash: 'productAdvantages'
    },
    {
        id: 3,
        title: '应用场景',
        hash: 'typicalCase'
    },
    {
        id: 4,
        title: '交流支持',
        hash: 'support'
    },
    {
        id: 5,
        title: '服务模式',
        hash: 'serviceMode'
    },
    {
        id: 6,
        title: '生态产品',
        hash: 'products'
    }
];

export const productNav = {
    title: '百度智能云千帆大模型平台',
    nav: productTitle,
    buttonGroup: [
        {
            text: '企业咨询',
            href: 'https://cloud.baidu.com/survey/qianfanmb.html',
            type: 'default',
            class: 'default-btn',
            id: 0,
            trackInfo: {
                'data-track-category': '千帆产品页PC-nav',
                'data-track-name': '主按钮',
                'data-track-value': '企业咨询'
            },
            trackInfo_m: {
                'data-track-category': '千帆产品页wise-nav',
                'data-track-name': '主按钮',
                'data-track-value': '企业咨询'
            }
        },
        {
            text: '在线体验',
            href: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest',
            type: 'primary',
            class: 'primary-btn',
            id: 1,
            trackInfo: {
                'data-track-category': '千帆产品页PC-nav',
                'data-track-name': '主按钮',
                'data-track-value': '在线体验'
            },
            trackInfo_m: {
                'data-track-category': '千帆产品页wise-nav',
                'data-track-name': '主按钮',
                'data-track-value': '在线体验'
            }
        }
    ]
};

export const productTitle_m = [
    {
        id: 0,
        title: '产品架构',
        hash: 'product-features'
    },

    {
        id: 1,
        title: '产品功能',
        hash: 'fuc-features'
    },
    {
        id: 2,
        title: '大模型服务',
        hash: 'model'
    },
    {
        id: 3,
        title: '应用范式',
        hash: 'typical-normal'
    },
    {
        id: 4,
        title: '应用场景',
        hash: 'usecase'
    },
    {
        id: 5,
        title: '产品优势',
        hash: 'product-superiority'
    },
    {
        id: 6,
        title: '服务模式',
        hash: 'deployment'
    },
    {
        id: 7,
        title: '支持交流',
        hash: 'interchange'
    },
    {
        id: 8,
        title: '相关产品',
        hash: 'product-list'
    }
];

export const model = {
    data: [
        {
            name: 'Meta-Llama-3-8B-Instruct',
            // icon: require('assets/wenxin/ernie-bot.png').default,
            nameDesc:
                '模型由Meta开源， <a href="https://huggingface.co/meta-llama/Meta-Llama-3-8B" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Meta-Llama-3-8B-Instruct" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由Meta开源，， <a href="https://huggingface.co/meta-llama/Meta-Llama-3-8B" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Meta-Llama-3-8B-Instruct" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['Meta (开源)'],
            type: '文本生成',
            // tag: '高性能',
            // tagClass: 'performance',
            // eslint-disable-next-line max-len
            desc: 'Meta-Llama-3-8B是Meta AI于2024年4月18日发布的Meta Llama 3系列的8B参数大语言模型，擅长语言细微差别、上下文理解、代码生成以及翻译和对话生成等复杂任务。Meta-Llama-3-8B-Instruct是8B参数的指令微调版本。',
            support: '支持训练调优与部署调用',
            // onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/ERNIE-4.0-8K',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/ilv62om62'
        },
        {
            name: 'Meta-Llama-3-70B-Instruct',
            nameOverflow: true,
            // icon: require('assets/wenxin/ernie-bot.png').default,
            nameDesc:
                '模型由Meta开源， <a href="https://huggingface.co/meta-llama/Meta-Llama-3-70B" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Meta-Llama-3-70B-Instruct" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由Meta开源，， <a href="https://huggingface.co/meta-llama/Meta-Llama-3-70B" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Meta-Llama-3-70B-Instruct" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['Meta (开源)'],
            type: '文本生成',
            // tag: '旗舰级',
            // tagClass: 'flagship',
            // eslint-disable-next-line max-len
            desc: 'Meta-Llama-3-70B是Meta AI于2024年4月18日发布的Meta Llama 3系列的70B参数大语言模型，擅长语言细微差别、上下文理解、代码生成以及翻译和对话生成等复杂任务。Meta-Llama-3-70B-Instruct是70B参数的指令微调版本。',
            support: '支持部署调用',
            // onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/ERNIE-4.0-8K',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/3lv6364k8'
        },
        {
            name: 'ERNIE 4.0 Turbo',
            icon: require('assets/wenxin/ernie-bot.png').default,
            nameDesc: '',
            brand: ['百度'],
            type: '文本生成',
            tag: '旗舰级',
            tagClass: 'flagship',
            desc: '百度自研的旗舰级超大规模⼤语⾔模型，综合效果表现出色，广泛适用于各领域复杂任务场景；支持自动对接百度搜索插件，保障问答信息时效。相较于ERNIE 4.0在性能表现上更优秀',
            support: '支持部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/ERNIE-4.0-Turbo-8K',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/7lxwwtafj'
        },
        {
            name: 'ERNIE 4.0',
            icon: require('assets/wenxin/ernie-bot.png').default,
            nameDesc: '',
            brand: ['百度'],
            type: '文本生成',
            tag: '旗舰级',
            tagClass: 'flagship',
            desc: '百度自研的旗舰级超大规模⼤语⾔模型，相较ERNIE 3.5实现了模型能力全面升级，广泛适用于各领域复杂任务场景；支持自动对接百度搜索插件，保障问答信息时效。',
            support: '支持部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/ERNIE-4.0-8K',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/clntwmv7t'
        },
        {
            name: 'ERNIE 3.5',
            icon: require('assets/wenxin/ernie-bot.png').default,
            nameDesc: '',
            brand: ['百度'],
            type: '文本生成',
            tag: '旗舰级',
            tagClass: 'flagship',
            desc: '百度自研的旗舰级大规模⼤语⾔模型，覆盖海量中英文语料，具有强大的通用能力，可满足绝大部分对话问答、创作生成、插件应用场景要求；支持自动对接百度搜索插件，保障问答信息时效。',
            support: '支持部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/ERNIE-3.5-8K',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/jlil56u11'
        },
        {
            name: 'ERNIE Speed',
            icon: require('assets/wenxin/ernie-bot.png').default,
            nameDesc: '',
            brand: ['百度'],
            type: '文本生成',
            tag: '高性能',
            tagClass: 'performance',
            desc: '百度2024年最新发布的自研高性能大语言模型，通用能力优异，适合作为基座模型进行精调，更好地处理特定场景问题，同时具备极佳的推理性能。',
            support: '支持训练调优与部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/ERNIE-Speed-8K',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/11852',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/klqx7b1xf'
        },
        {
            name: 'ERNIE Lite',
            icon: require('assets/wenxin/ernie-bot.png').default,
            nameDesc: '',
            brand: ['百度'],
            type: '文本生成',
            tag: '轻量级',
            tagClass: 'performance',
            desc: '百度自研的轻量级大语言模型，兼顾优异的模型效果与推理性能，适合低算力AI加速卡推理使用。',
            support: '支持训练调优与部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/ERNIE-Lite-8K',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/2',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/dltgsna1o'
        },
        {
            name: 'ERNIE Tiny',
            icon: require('assets/wenxin/ernie-bot.png').default,
            brand: ['百度'],
            type: '文本生成',
            tag: '超高性能',
            tagClass: 'performance',
            desc: '百度自研的超高性能大语言模型，部署与精调成本在文心系列模型中最低。',
            support: '支持训练调优与部署调用',
            // onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/ERNIE Tiny',  // 3.18 上线
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/am-xgk30z76e4gs',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/7lsvf68uw'
        },
        {
            name: 'ERNIE Character',
            icon: require('assets/wenxin/ernie-bot.png').default,
            brand: ['百度'],
            type: '文本生成',
            tag: '专用版',
            tagClass: 'special',
            // eslint-disable-next-line max-len
            desc: '百度自研的垂直场景大语言模型，适合游戏NPC、客服对话、对话角色扮演等应用场景，人设风格更为鲜明、一致，指令遵循能力更强，推理性能更优。',
            support: '支持部署调用',
            // applyTest: 'https://cloud.baidu.com/survey/model-evaluating.html',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/ERNIE-Character-8K',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Eltxyhpve'
        },
        {
            name: 'ERNIE Functions',
            icon: require('assets/wenxin/ernie-bot.png').default,
            brand: ['百度'],
            type: '文本生成',
            tag: '专用版',
            tagClass: 'special',
            desc: '百度自研的垂直场景大语言模型，适合对话问答中的外部工具使用和业务函数调用场景，结构化回答合成能力更强、输出格式更稳定，推理性能更优。',
            support: '支持部署调用',
            // applyTest: 'https://cloud.baidu.com/survey/model-evaluating.html',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/ERNIE-Functions-8K',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/5ltxyqupn'
        },
        // {
        //     name: 'ERNIE Speed-AppBuilder',
        //     icon: require('assets/wenxin/ernie-bot.png').default,
        //     brand: ['百度'],
        //     type: '文本生成',
        //     tag: '专用版',
        //     tagClass: 'special',
            // eslint-disable-next-line max-len
        //     desc: '千帆AppBuilder专用版模型，针对企业级大模型应用进行了专门的指令调优，在问答场景、智能体相关场景可以获得同等规模模型下更好的效果，需配合“百度智能云千帆AppBuilder”产品使用或结合“AppBuilder-SDK”单独使用',
        //     support: '支持部署调用',
        //     onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/ERNIE Speed-AppBuilder',
        //     doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Alp0kdm0n'
        // },
        {
            name: '文心一格',
            icon: require('assets/wenxin/ernie-bot.png').default,
            brand: ['百度'],
            type: '图像生成',
            tag: '旗舰级',
            tagClass: 'flagship',
            desc: '百度自主研发的跨模态图像生成大模型，创新知识增强的混合专家建模，是全球首个知识增强的AI作画大模型，在语义控制、图像细节、中文理解等方面优势显著，已作为基础模型应用在文心一格等相关业务上。',
            support: '支持训练调优与部署调用',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/clqx8tbl0'
        },
        {
            name: 'Embedding-V1',
            nameDesc: '',
            brand: ['百度'],
            type: '文本表示',
            desc: 'Embedding-V1是基于百度文心大模型技术的文本表示模型，将文本转化为用数值表示的向量形式，用于文本检索、信息推荐、知识挖掘等场景。',
            support: '支持部署调用',
            // onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/Embedding-V1',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/alj562vvu'
        },
        {
            name: 'Qianfan-BLOOMZ-7B-compressed',
            // pc模型名称超出一行
            nameOverflow: true,
            nameDesc:
                '模型基于BigScience开源模型增强，<br /> <a href="https://huggingface.co/bigscience/bloomz-7b1-mt" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Qianfan-BLOOMZ-7B-compressed" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a> ',
            nameDesc_m:
                '模型基于BigScience开源模型增强， <a href="https://huggingface.co/bigscience/bloomz-7b1-mt" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Qianfan-BLOOMZ-7B-compressed" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a> ',
            brand: ['百度(增强)', 'BigScience(开源)'],
            type: '文本生成',
            desc: '千帆团队在BLOOMZ-7B基础上的压缩版本，融合量化、稀疏化等技术，显存占用降低30%以上',
            support: '支持部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/Qianfan-BLOOMZ-7B-compressed',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/3/5595',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/nllyzpcmp'
        },
        {
            name: 'Qianfan-Chinese-Llama-2-7B',
            nameOverflow: true,
            nameDesc:
                '模型基于Meta开源模型增强， <a href="https://huggingface.co/meta-llama/Llama-2-7b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Qianfan-Chinese-Llama-2-7B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型基于Meta开源模型增强， <a href="https://huggingface.co/meta-llama/Llama-2-7b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Qianfan-Chinese-Llama-2-7B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['百度 (增强)', 'Meta (开源)'],
            type: '文本生成',
            desc: '千帆团队在Llama-2-7b基础上的中文增强版本，在CMMLU、C-EVAL等中文数据集上表现优异',
            support: '支持训练调优与部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/Qianfan-Chinese-Llama-2-7B',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/2258/5600',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Sllyztytp'
        },
        {
            name: 'Qianfan-Chinese-Llama-2-7B-32K',
            nameOverflow: true,
            nameDesc:
                '模型基于Meta开源模型增强， <a href="https://huggingface.co/meta-llama/Llama-2-7b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Qianfan-Chinese-Llama-2-7B-32K" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型基于Meta开源模型增强， <a href="https://huggingface.co/meta-llama/Llama-2-7b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Qianfan-Chinese-Llama-2-7B-32K" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['百度 (增强)', 'Meta (开源)'],
            type: '文本生成',
            desc: '千帆团队在Qianfan-Chinese-Llama-2-7B基础上的增强版本，支持32K上下文',
            support: '支持训练调优与部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/2258/16098',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Wlrimb8aw'
        },
        {
            name: 'Qianfan-Chinese-Llama-2-13B-v2',
            nameOverflow: true,
            nameDesc:
                '模型基于Meta开源模型框架进行预训练， <a href="https://huggingface.co/meta-llama/Llama-2-7b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Qianfan-Chinese-Llama-2-13B-v2" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型基于Meta开源模型框架进行预训练， <a href="https://huggingface.co/meta-llama/Llama-2-7b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Qianfan-Chinese-Llama-2-13B-v2" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['百度 (增强)', 'Meta (开源)'],
            type: '文本生成',
            desc: '千帆团队基于Llama2架构开发的130亿参数预训练大语言模型，在1T+ tokens的高质量自有语料上从零开始训练，支持中英双语，上下文窗口长度为4K。推理效果整体优于同参数量级开源模型',
            support: '支持训练调优与部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/am-p6rz53tkgc7e',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/zltpk9tto'
        },
        {
            name: 'Qianfan-Chinese-Llama-2-13B-v1',
            nameOverflow: true,
            nameDesc:
                '模型基于Meta开源模型增强， <a href="https://huggingface.co/meta-llama/Llama-2-13b-hf" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Qianfan-Chinese-Llama-2-13B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型基于Meta开源模型增强， <a href="https://huggingface.co/meta-llama/Llama-2-13b-hf" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Qianfan-Chinese-Llama-2-13B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['百度 (增强)', 'Meta (开源)'],
            type: '文本生成',
            desc: '千帆团队在Llama-2-13b基础上的中文增强版本，在CMMLU、C-EVAL等中文数据集上表现优异',
            support: '支持训练调优与部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/Qianfan-Chinese-Llama-2-13B',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/2259/9166',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/8lo479b4b'
        },
        {
            name: 'Qianfan-Chinese-Llama-2-70B',
            nameOverflow: true,
            nameDesc:
                '模型基于Meta开源模型增强， <a href="https://huggingface.co/meta-llama/Llama-2-13b-hf" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Qianfan-Chinese-Llama-2-70B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型基于Meta开源模型增强， <a href="https://huggingface.co/meta-llama/Llama-2-13b-hf" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Qianfan-Chinese-Llama-2-70B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['百度 (增强)', 'Meta (开源)'],
            type: '文本生成',
            desc: '千帆团队在Llama-2-70b基础上的中文增强版本，在CMMLU、C-EVAL等中文数据集上表现优异',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/am-rwhr5173mxre',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/cltpkdcwy'
        },
        {
            name: 'Qianfan-Llama-2-70B-compressed',
            nameOverflow: true,
            nameDesc:
                '模型基于Meta开源模型增强， <a href="https://huggingface.co/meta-llama/Llama-2-70b-chat-hf" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Qianfan-Llama-2-70B-compressed" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型基于Meta开源模型增强， <a href="https://huggingface.co/meta-llama/Llama-2-70b-chat-hf" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Qianfan-Llama-2-70B-compressed" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['百度 (增强)', 'Meta (开源)'],
            type: '文本生成',
            desc: 'Qianfan-Llama2-70B-compressed是千帆团队在Llama2-70B基础上的压缩版本，融合量化、稀疏化、算子优化等压缩加速技术，大幅降低模型推理资源占用。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/2260/12616',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/floqj7kuh'
        }
    ]
};
export const model1 = {
    data: [
        {
            name: 'ERNIE 4.0',
            icon: require('assets/wenxin/ernie-bot.png').default,
            nameDesc: '',
            brand: ['百度'],
            type: '文本生成',
            tag: '旗舰级',
            tagClass: 'flagship',
            desc: '百度自研的旗舰级超大规模⼤语⾔模型，相较ERNIE 3.5实现了模型能力全面升级，广泛适用于各领域复杂任务场景；支持自动对接百度搜索插件，保障问答信息时效。',
            support: '支持部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/ERNIE-4.0-8K',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/clntwmv7t'
        },
        {
            name: 'ERNIE 3.5',
            icon: require('assets/wenxin/ernie-bot.png').default,
            nameDesc: '',
            brand: ['百度'],
            type: '文本生成',
            tag: '旗舰级',
            tagClass: 'flagship',
            desc: '百度自研的旗舰级大规模⼤语⾔模型，覆盖海量中英文语料，具有强大的通用能力，可满足绝大部分对话问答、创作生成、插件应用场景要求；支持自动对接百度搜索插件，保障问答信息时效。',
            support: '支持部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/ERNIE-3.5-8K',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/jlil56u11'
        },
        {
            name: 'ERNIE Speed',
            icon: require('assets/wenxin/ernie-bot.png').default,
            nameDesc: '',
            brand: ['百度'],
            type: '文本生成',
            tag: '高性能',
            tagClass: 'performance',
            desc: '百度2024年最新发布的自研高性能大语言模型，通用能力优异，适合作为基座模型进行精调，更好地处理特定场景问题，同时具备极佳的推理性能。',
            support: '支持训练调优与部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/ERNIE-Speed-8K',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/11852',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/klqx7b1xf'
        },
        {
            name: 'ERNIE Lite',
            icon: require('assets/wenxin/ernie-bot.png').default,
            nameDesc: '',
            brand: ['百度'],
            type: '文本生成',
            tag: '轻量级',
            tagClass: 'performance',
            desc: '百度自研的轻量级大语言模型，兼顾优异的模型效果与推理性能，适合低算力AI加速卡推理使用。',
            support: '支持训练调优与部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/ERNIE-Lite-8K',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/2',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/dltgsna1o'
        },
        // 2024.3.8
        {
            name: 'ERNIE Tiny',
            icon: require('assets/wenxin/ernie-bot.png').default,
            brand: ['百度'],
            type: '文本生成',
            tag: '超高性能',
            tagClass: 'performance',
            desc: '百度自研的超高性能大语言模型，部署与精调成本在文心系列模型中最低。',
            support: '支持训练调优与部署调用',
            // onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/ERNIE Tiny',  // 3.18 上线
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/am-xgk30z76e4gs',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/7lsvf68uw'
        },
        {
            name: 'ERNIE Character',
            icon: require('assets/wenxin/ernie-bot.png').default,
            brand: ['百度'],
            type: '文本生成',
            tag: '专用版',
            tagClass: 'special',
            // eslint-disable-next-line max-len
            desc: '百度自研的垂直场景大语言模型，适合游戏NPC、客服对话、对话角色扮演等应用场景，人设风格更为鲜明、一致，指令遵循能力更强，推理性能更优。',
            support: '支持部署调用',
            applyTest: 'https://cloud.baidu.com/survey/model-evaluating.html',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Eltxyhpve'
        },
        {
            name: 'ERNIE Functions',
            icon: require('assets/wenxin/ernie-bot.png').default,
            brand: ['百度'],
            type: '文本生成',
            tag: '专用版',
            tagClass: 'special',
            desc: '百度自研的垂直场景大语言模型，适合对话问答中的外部工具使用和业务函数调用场景，结构化回答合成能力更强、输出格式更稳定，推理性能更优。',
            support: '支持部署调用',
            applyTest: 'https://cloud.baidu.com/survey/model-evaluating.html',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/5ltxyqupn'
        },
        {
            name: 'ERNIE Speed-AppBuilder',
            icon: require('assets/wenxin/ernie-bot.png').default,
            brand: ['百度'],
            type: '文本生成',
            tag: '专用版',
            tagClass: 'special',
            // eslint-disable-next-line max-len
            desc: '千帆AppBuilder专用版模型，针对企业级大模型应用进行了专门的指令调优，在问答场景、智能体相关场景可以获得同等规模模型下更好的效果，需配合“百度智能云千帆AppBuilder”产品使用或结合“AppBuilder-SDK”单独使用',
            support: '支持部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/ERNIE Speed-AppBuilder',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Alp0kdm0n'
        },
        {
            name: '文心一格',
            icon: require('assets/wenxin/ernie-bot.png').default,
            brand: ['百度'],
            type: '图像生成',
            tag: '旗舰级',
            tagClass: 'flagship',
            desc: '百度自主研发的跨模态图像生成大模型，创新知识增强的混合专家建模，是全球首个知识增强的AI作画大模型，在语义控制、图像细节、中文理解等方面优势显著，已作为基础模型应用在文心一格等相关业务上。',
            support: '支持训练调优与部署调用',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/clqx8tbl0'
        },
        {
            name: 'Stable-Diffusion-XL',
            icon: require('assets/wenxin/stableDiffusion.png').default,
            nameDesc:
                '模型由StabilityAI开源， <a href="https://huggingface.co/stabilityai/stable-diffusion-xl-base-1.0" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Stable-Diffusion-XL" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由StabilityAI开源， <a href="https://huggingface.co/stabilityai/stable-diffusion-xl-base-1.0" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Stable-Diffusion-XL" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['Stability AI (开源)'],
            type: '图像生成',
            desc: '业内知名的跨模态大模型，由StabilityAI研发并开源，有着业内领先的图像生成能力。',
            support: '支持训练调优与部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/7409',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/Stable-Diffusion-XL?modelType=SD',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Klkqubb9w'
        },
        {
            name: 'Fuyu-8B',
            nameDesc:
                '模型由Adept AI开源， <a href="https://huggingface.co/adept/fuyu-8b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Fuyu-8B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由Adept AI开源， <a href="https://huggingface.co/adept/fuyu-8b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Fuyu-8B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['Adept AI (开源)'],
            type: '图像理解',
            desc: 'Fuyu-8B是由Adept AI训练的多模态图像理解模型，可以支持多样的图像分辨率，回答图形图表有关问题。模型在视觉问答和图像描述等任务上表现良好。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/10949',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Qlq4l7uw6'
        },
        {
            name: 'Gemma-2B-it',
            nameDesc:
                '模型由Google开源， <a href="https://huggingface.co/google/gemma-2b-it" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Gemma-2B-it" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由Google开源， <a href="https://huggingface.co/google/gemma-2b-it" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Gemma-2B-it" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['Google (开源)'],
            type: '文本生成',
            desc: 'Gemma是Google开发的一系列轻量级、尖端的开源文本生成模型，采用与Gemini模型相同的技术构建，适合用于各种文本生成任务，能够在资源量较小的端侧设备部署。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/am-i51rme6486f9',
            // onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/Gemma-2B-it',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/3ltpgl5zf'
        },
        {
            name: 'Gemma-7B-it',
            nameDesc:
                '模型由Google开源， <a href="https://huggingface.co/google/gemma-7b-it" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Gemma-7B-it" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由Google开源， <a href="https://huggingface.co/google/gemma-7b-it" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Gemma-7B-it" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['Google (开源)'],
            type: '文本生成',
            desc: 'Gemma是Google开发的一系列轻量级、尖端的开源文本生成模型，采用与Gemini模型相同的技术构建，适合用于各种文本生成任务，能够在资源量较小的端侧设备部署。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/am-37mgfffr3qr0',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/Gemma-7B-It',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/8ltpglkbl'
        },
        {
            name: 'Yi-34B',
            nameDesc:
                '模型由零一万物开源， <a href="https://huggingface.co/01-ai/Yi-34B" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Yi-34B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由零一万物开源， <a href="https://huggingface.co/01-ai/Yi-34B" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Yi-34B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['零一万物 (开源)'],
            type: '文本生成',
            desc: 'Yi-34B是由零一万物开发并开源的双语大语言模型，使用4K序列长度进行训练，在推理期间可扩展到32K；模型在多项评测中全球领跑，取得了多项 SOTA 国际最佳性能指标表现。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/10178',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Ilphtu4k6'
        },
        {
            name: 'Yi-34B-Chat',
            nameDesc:
                '模型由零一万物开源， <a href="https://huggingface.co/01-ai/Yi-34B-Chat" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Yi-34B-Chat" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由零一万物开源， <a href="https://huggingface.co/01-ai/Yi-34B-Chat" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Yi-34B-Chat" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['零一万物 (开源)'],
            type: '文本生成',
            // eslint-disable-next-line max-len
            desc: 'Yi-34B是由零一万物开发并开源的双语大语言模型，使用4K序列长度进行训练，在推理期间可扩展到32K；模型在多项评测中全球领跑，取得了多项 SOTA 国际最佳性能指标表现，该版本为支持对话的chat版本。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/10178/13584',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/Yi-34B-Chat',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/vlpteyv3c'
        },
        {
            name: 'Embedding-V1',
            nameDesc: '',
            brand: ['百度'],
            type: '文本表示',
            desc: 'Embedding-V1是基于百度文心大模型技术的文本表示模型，将文本转化为用数值表示的向量形式，用于文本检索、信息推荐、知识挖掘等场景。',
            support: '支持部署调用',
            // onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/Embedding-V1',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/alj562vvu'
        },
        {
            name: 'BLOOMZ-7B',
            nameDesc:
                '模型由BigScience开源， <a href="https://huggingface.co/bigscience/bloomz-7b1-mt" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="BLOOMZ-7B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a> ',
            nameDesc_m:
                '模型由BigScience开源， <a href="https://huggingface.co/bigscience/bloomz-7b1-mt" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="BLOOMZ-7B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a> ',
            brand: ['BigScience (开源)'],
            type: '文本生成',
            desc: '业内知名的⼤语⾔模型，由BigScience研发并开源，能够以46种语⾔和13种编程语⾔输出⽂本。',
            support: '支持训练调优与部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/BLOOMZ-7B',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/3',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Jljcadglj'
        },
        {
            name: 'Qianfan-BLOOMZ-7B-compressed',
            // pc模型名称超出一行
            nameOverflow: true,
            nameDesc:
                '模型基于BigScience开源模型增强，<br /> <a href="https://huggingface.co/bigscience/bloomz-7b1-mt" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Qianfan-BLOOMZ-7B-compressed" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a> ',
            nameDesc_m:
                '模型基于BigScience开源模型增强， <a href="https://huggingface.co/bigscience/bloomz-7b1-mt" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Qianfan-BLOOMZ-7B-compressed" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a> ',
            brand: ['百度(增强)', 'BigScience(开源)'],
            type: '文本生成',
            desc: '千帆团队在BLOOMZ-7B基础上的压缩版本，融合量化、稀疏化等技术，显存占用降低30%以上',
            support: '支持部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/Qianfan-BLOOMZ-7B-compressed',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/3/5595',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/nllyzpcmp'
        },
        // 1.11
        {
            name: 'Mixtral-8x7B-Instruct',
            nameDesc:
                '模型由Mistral AI开源， <a href="https://huggingface.co/mistralai/Mixtral-8x7B-Instruct-v0.1" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Mixtral-8x7B-Instruct" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a> ',
            nameDesc_m:
                '模型由Mistral AI开源， <a href="https://huggingface.co/mistralai/Mixtral-8x7B-Instruct-v0.1" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Mixtral-8x7B-Instruct" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a> ',
            brand: ['Mistral AI (开源)'],
            type: '文本生成',
            // eslint-disable-next-line max-len
            desc: '由Mistral AI发布的首个高质量稀疏专家混合模型 (MOE)，模型由8个70亿参数专家模型组成，在多个基准测试中表现优于Llama-2-70B及GPT3.5，能够处理32K上下文，在代码生成任务中表现尤为优异。',
            support: '支持部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/Mixtral-8x7B-Instruct',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/11838/14674',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Rlqx7c834'
        },
        {
            name: 'Mistral-7B-Instruct',
            nameDesc:
                '模型由Mistral AI开源， <a href="https://huggingface.co/mistralai/Mistral-7B-Instruct-v0.1" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Mistral-7B-Instruct" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a> ',
            nameDesc_m:
                '模型由Mistral AI开源， <a href="https://huggingface.co/mistralai/Mistral-7B-Instruct-v0.1" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Mistral-7B-Instruct" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a> ',
            brand: ['Mistral AI(开源)'],
            type: '文本生成',
            desc: '由Mistral AI研发并开源的7B参数大语言模型，具备强大的推理性能和效果，对硬件需求更少、在各项评测基准中超越同规模模型。该版本为Mistral-7B-v0.1基础上的微调版本。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/6980/8637',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/0lnu3sxhm'
        },
        {
            name: 'Llama-2-7b-chat',
            icon: require('assets/wenxin/Llama.png').default,
            nameDesc:
                '模型由Meta开源， <a href="https://huggingface.co/meta-llama/Llama-2-7b-chat-hf" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Llama-2-7b-chat" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由Meta开源， <a href="https://huggingface.co/meta-llama/Llama-2-7b-chat-hf" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Llama-2-7b-chat" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['Meta (开源)'],
            type: '文本生成',
            desc: '由Meta AI研发并开源，在编码、推理及知识应用等场景表现优秀，Llama-2-7b-chat是高性能原生开源版本，适用于对话场景。',
            support: '支持部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/Llama-2-7B-Chat',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/2258',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Rlki1zlai'
        },
        {
            name: 'Llama-2-13b-chat',
            icon: require('assets/wenxin/Llama.png').default,
            nameDesc:
                '模型由Meta开源， <a href="https://huggingface.co/meta-llama/Llama-2-13b-chat-hf" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Llama-2-13b-chat" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由Meta开源， <a href="https://huggingface.co/meta-llama/Llama-2-13b-chat-hf" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Llama-2-13b-chat" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['Meta (开源)'],
            type: '文本生成',
            desc: '由Meta AI研发并开源，在编码、推理及知识应用等场景表现优秀，Llama-2-13b-chat是性能与效果均衡的原生开源版本，适用于对话场景。',
            support: '支持部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/Llama-2-13B-Chat',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/2259',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/2lki2us1e'
        },
        {
            name: 'Llama-2-70b-chat',
            icon: require('assets/wenxin/Llama.png').default,
            nameDesc:
                '模型由Meta开源， <a href="https://huggingface.co/meta-llama/Llama-2-70b-chat-hf" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Llama-2-70b-chat" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由Meta开源， <a href="https://huggingface.co/meta-llama/Llama-2-70b-chat-hf" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Llama-2-70b-chat" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['Meta (开源)'],
            type: '文本生成',
            desc: '由Meta AI研发并开源，在编码、推理及知识应用等场景表现优秀，Llama-2-70b-chat是高精度效果的原生开源版本。',
            support: '支持部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/Llama-2-70B-Chat',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/2260',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/8lkjfhiyt'
        },
        {
            name: 'Qianfan-Chinese-Llama-2-7B',
            nameOverflow: true,
            nameDesc:
                '模型基于Meta开源模型增强， <a href="https://huggingface.co/meta-llama/Llama-2-7b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Qianfan-Chinese-Llama-2-7B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型基于Meta开源模型增强， <a href="https://huggingface.co/meta-llama/Llama-2-7b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Qianfan-Chinese-Llama-2-7B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['百度 (增强)', 'Meta (开源)'],
            type: '文本生成',
            desc: '千帆团队在Llama-2-7b基础上的中文增强版本，在CMMLU、C-EVAL等中文数据集上表现优异',
            support: '支持训练调优与部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/Qianfan-Chinese-Llama-2-7B',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/2258/5600',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Sllyztytp'
        },
        {
            name: 'Qianfan-Chinese-Llama-2-7B-32K',
            nameOverflow: true,
            nameDesc:
                '模型基于Meta开源模型增强， <a href="https://huggingface.co/meta-llama/Llama-2-7b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Qianfan-Chinese-Llama-2-7B-32K" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型基于Meta开源模型增强， <a href="https://huggingface.co/meta-llama/Llama-2-7b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Qianfan-Chinese-Llama-2-7B-32K" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['百度 (增强)', 'Meta (开源)'],
            type: '文本生成',
            desc: '千帆团队在Qianfan-Chinese-Llama-2-7B基础上的增强版本，支持32K上下文',
            support: '支持训练调优与部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/2258/16098',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Wlrimb8aw'
        },
        {
            name: 'Qianfan-Chinese-Llama-2-13B',
            nameOverflow: true,
            nameDesc:
                '模型基于Meta开源模型增强， <a href="https://huggingface.co/meta-llama/Llama-2-13b-hf" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Qianfan-Chinese-Llama-2-13B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型基于Meta开源模型增强， <a href="https://huggingface.co/meta-llama/Llama-2-13b-hf" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Qianfan-Chinese-Llama-2-13B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['百度 (增强)', 'Meta (开源)'],
            type: '文本生成',
            desc: '千帆团队在Llama-2-13b基础上的中文增强版本，在CMMLU、C-EVAL等中文数据集上表现优异',
            support: '支持训练调优与部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/Qianfan-Chinese-Llama-2-13B',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/2259/9166',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/8lo479b4b'
        },
        {
            name: 'Qianfan-Llama-2-70B-compressed',
            nameOverflow: true,
            nameDesc:
                '模型基于Meta开源模型增强， <a href="https://huggingface.co/meta-llama/Llama-2-70b-chat-hf" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Qianfan-Llama-2-70B-compressed" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型基于Meta开源模型增强， <a href="https://huggingface.co/meta-llama/Llama-2-70b-chat-hf" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Qianfan-Llama-2-70B-compressed" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['百度 (增强)', 'Meta (开源)'],
            type: '文本生成',
            desc: 'Qianfan-Llama2-70B-compressed是千帆团队在Llama2-70B基础上的压缩版本，融合量化、稀疏化、算子优化等压缩加速技术，大幅降低模型推理资源占用。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/2260/12616',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/floqj7kuh'
        },
        {
            name: 'Linly-Chinese-LLaMA-2-7B',
            icon: require('assets/wenxin/Llama.png').default,
            nameDesc:
                '模型由深圳大学开源， <a href="https://github.com/CVI-SZU/Linly" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Linly-Chinese-LLaMA-2-7B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由深圳大学开源， <a href="https://github.com/CVI-SZU/Linly" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Linly-Chinese-LLaMA-2-7B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['深圳大学（开源）'],
            type: '文本生成',
            desc: '由深圳大学CV研究所Linly项目进行中文增强训练的Llama-2 7b参数版本。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/2258',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/qlkqu3j29'
        },
        {
            name: 'Linly-Chinese-LLaMA-2-13B',
            icon: require('assets/wenxin/Llama.png').default,
            nameOverflow: true,
            nameDesc:
                '模型由深圳大学开源， <a href="https://github.com/CVI-SZU/Linly" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Linly-Chinese-LLaMA-2-13B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由深圳大学开源， <a href="https://github.com/CVI-SZU/Linly" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Linly-Chinese-LLaMA-2-13B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['深圳大学（开源）'],
            type: '文本生成',
            desc: '由深圳大学CV研究所Linly项目进行中文增强训练的Llama-2 13b参数版本。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/2259',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/ulkqu7kvl'
        },
        // 迭代四
        {
            name: 'ChatGLM3-6B',
            icon: require('assets/wenxin/ChatGLM.png').default,
            nameDesc:
                '模型由智谱AI开源， <a href="https://huggingface.co/THUDM/chatglm3-6b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="ChatGLM3-6B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由智谱AI开源， <a href="https://huggingface.co/THUDM/chatglm3-6b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="ChatGLM3-6B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['智谱AI (开源)'],
            type: '文本生成',
            // eslint-disable-next-line max-len
            desc: '智谱AI与清华KEG实验室发布的中英双语对话模型，相比前两代，具备更强大的基础模型，同时原生支持工具调用（Function Call）、代码执行（Code Interpreter）和 Agent 任务等复杂场景。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/9009',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Wloqvw3iq'
        },
        {
            name: 'ChatGLM2-6B',
            icon: require('assets/wenxin/ChatGLM.png').default,
            nameDesc:
                '模型由智谱AI开源， <a href="https://huggingface.co/THUDM/chatglm2-6b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="ChatGLM2-6B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由智谱AI开源， <a href="https://huggingface.co/THUDM/chatglm2-6b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="ChatGLM2-6B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['智谱AI (开源)'],
            type: '文本生成',
            desc: '智谱AI与清华KEG实验室发布的中英双语对话模型，具备强大的推理性能、效果、较低的部署门槛及更长的上下文，在MMLU、CEval等数据集上相比初代有大幅的性能提升。',
            support: '支持训练调优与部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/2562',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/ilkqu9pib'
        },
        {
            name: 'ChatGLM2-6B-32K',
            icon: require('assets/wenxin/ChatGLM.png').default,
            nameDesc:
                '模型由智谱AI开源， <a href="https://huggingface.co/THUDM/chatglm2-6b-32k" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="ChatGLM2-6B-32K" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由智谱AI开源， <a href="https://huggingface.co/THUDM/chatglm2-6b-32k" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="ChatGLM2-6B-32K" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['智谱AI (开源)'],
            type: '文本生成',
            desc: '在ChatGLM2-6B的基础上进一步强化了对于长文本的理解能力，能够更好的处理最多32K长度的上下文。',
            support: '支持部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/ChatGLM2-6B-32K',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/2562/5598',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Bllz001ff'
        },
        {
            name: 'ChatGLM2-6B-INT4',
            icon: require('assets/wenxin/ChatGLM.png').default,
            nameDesc:
                '模型由智谱AI开源， <a href="https://huggingface.co/THUDM/chatglm2-6b-int4" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="ChatGLM2-6B-INT4" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由智谱AI开源， <a href="https://huggingface.co/THUDM/chatglm2-6b-int4" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="ChatGLM2-6B-INT4" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['智谱AI (开源)'],
            type: '文本生成',
            desc: '在ChatGLM2-6B的基础上进行INT4 量化，6G 显存支持的对话长度由 1K 提升到了 8K。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/2562/5599',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/sllz01g72'
        },
        // 迭代四
        {
            name: 'Baichuan2-7B-Chat',
            icon: require('assets/wenxin/Baichuan2.png').default,
            nameDesc:
                '模型由百川智能开源， <a href="https://huggingface.co/baichuan-inc/Baichuan2-7B-Chat" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Baichuan2-7B-Chat" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由百川智能开源， <a href="https://huggingface.co/baichuan-inc/Baichuan2-7B-Chat" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Baichuan2-7B-Chat" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['百川智能 (开源)'],
            type: '文本生成',
            // eslint-disable-next-line max-len
            desc: 'Baichuan 2 是百川智能推出的新一代开源大语言模型，采用2.6万亿Tokens的高质量语料训练，在权威的中文和英文 benchmark 上均取得同尺寸领先的效果。该版本为70亿参数规模的Chat版本。',
            support: '支持训练调优与部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/9010',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/nloqvxnp0'
        },
        {
            name: 'Baichuan2-13B-Chat',
            icon: require('assets/wenxin/Baichuan2.png').default,
            nameDesc:
                '模型由百川智能开源， <a href="https://huggingface.co/baichuan-inc/Baichuan2-13B-Chat" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Baichuan2-13B-Chat" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由百川智能开源， <a href="https://huggingface.co/baichuan-inc/Baichuan2-13B-Chat" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Baichuan2-13B-Chat" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['百川智能 (开源)'],
            type: '文本生成',
            // eslint-disable-next-line max-len
            desc: 'Baichuan 2是百川智能推出的新一代开源大语言模型，采用2.6万亿Tokens的高质量语料训练，在权威的中文和英文 benchmark 上均取得同尺寸领先的效果。该版本为130亿参数规模的Chat版本',
            support: '支持训练调优与部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/8137/10042',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/jlofcjru7'
        },
        {
            name: 'XVERSE-13B-Chat',
            icon: require('assets/wenxin/XVERSE.png').default,
            nameDesc:
                '模型由元象科技开源， <a href="https://huggingface.co/xverse/XVERSE-13B-Chat" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="XVERSE-13B-Chat" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由元象科技开源， <a href="https://huggingface.co/xverse/XVERSE-13B-Chat" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="XVERSE-13B-Chat" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['元象科技 (开源)'],
            type: '文本生成',
            desc: '由深圳元象科技自主研发的支持多语言的大语言模型，支持8k上下文、40多种语言，具备训练效率高、稳定性强、算力利用率高等特点。该版本为130亿参数规模的Chat版本。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/8138/10043',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Dloff2783'
        },
        // 11.24 （支持在线测试）
        {
            name: 'SQLCoder-7B',
            nameDesc:
                '模型由智谱AI开源， <a href="https://huggingface.co/defog/sqlcoder-7b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="SQLCoder-7B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由智谱AI开源， <a href="https://huggingface.co/defog/sqlcoder-7b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="SQLCoder-7B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['Defog (开源)'],
            type: '代码生成',
            desc: '由Defog研发、基于Mistral-7B微调的语言模型，用于将自然语言问题转换为SQL语句，具备优秀的生成效果。',
            support: '支持训练调优与部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/SQLCoder-7B',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/7410',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Hlo472sa2'
        },
        // 11.24 （支持在线测试）
        {
            name: 'CodeLlama-7b-Instruct',
            icon: require('assets/wenxin/Llama.png').default,
            nameDesc:
                '模型由Meta开源， <a href="https://huggingface.co/codellama/CodeLlama-7b-Instruct-hf" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="CodeLlama-7b-Instruct" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由Meta开源， <a href="https://huggingface.co/codellama/CodeLlama-7b-Instruct-hf" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力" data-track-name="CodeLlama-7b-Instruct" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['Meta (开源)'],
            type: '代码生成',
            desc: 'Code Llama是由Meta AI研发并开源的一系列文本生成模型，旨在用于一般代码合成和理解。该模型参数规模为70亿。',
            support: '支持部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/CodeLlama-7B-Instruct',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/8139/10044',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/ylo47d03k'
        },
        // 11.24
        {
            name: 'XuanYuan-70B-Chat-4bit',
            icon: require('assets/wenxin/XuanYuan.png').default,
            nameDesc:
                '模型由度小满开源， <a href="https://huggingface.co/Duxiaoman-DI/XuanYuan-70B-Chat-4bit" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="XuanYuan-70B-Chat-4bit" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由度小满开源， <a href="https://huggingface.co/Duxiaoman-DI/XuanYuan-70B-Chat-4bit" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力" data-track-name="XuanYuan-70B-Chat-4bit" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['度小满 (开源)'],
            type: '文本生成',
            // eslint-disable-next-line max-len
            desc: '由度小满开发，基于Llama2-70B模型进行中文增强的金融行业大模型，通用能力显著提升，在CMMLU/CEVAL等各项榜单中排名前列；金融域任务超越领先通用模型，支持金融知识问答、金融计算、金融分析等各项任务。',
            support: '支持部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/XuanYuan-70B-Chat-4bit',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/9257',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Ylp88e5jc'
        },
        {
            name: 'DISC-MedLLM',
            nameDesc:
                '模型由复旦大学数据智能与社会计算实验室开源， <a href="https://github.com/FudanDISC/DISC-MedLLM" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="DISC-MedLLM" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由复旦大学数据智能与社会计算实验室开源， <a href="https://github.com/FudanDISC/DISC-MedLLM" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力" data-track-name="DISC-MedLLM" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['复旦大学 (开源)'],
            type: '文本生成',
            desc: 'DISC-MedLLM是一个专门针对医疗健康对话式场景而设计的医疗领域大模型，由复旦大学数据智能与社会计算实验室 (Fudan-DISC)开发并开源。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/9011',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/jloqvyssc'
        },
        // 12.07
        {
            name: 'ChatLaw',
            icon: require('assets/wenxin/ChatLaw.png').default,
            brand: ['壹万卷 (闭源)'],
            type: '文本生成',
            desc: '由壹万卷公司与北大深研院研发的法律行业大模型，在开源版本基础上进行了进一步架构升级，融入了法律意图识别、法律关键词提取、CoT推理增强等模块，实现了效果提升，以满足法律问答、法条检索等应用需求。',
            support: '支持部署调用',
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/ChatLaw',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Qlphtigbf'
        },
        {
            name: 'Falcon-7B',
            nameDesc:
                '模型由OpenBuddy开源， <a href="https://huggingface.co/OpenBuddy/openbuddy-falcon-7b-v5-fp16" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Falcon-7B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由OpenBuddy开源， <a href="https://huggingface.co/OpenBuddy/openbuddy-falcon-7b-v5-fp16" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Falcon-7B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['OpenBuddy (开源)'],
            type: '文本生成',
            desc: '由TII研发、在精选语料库增强的1500B tokens上进行训练。由OpenBuddy调优并开源，提升了处理复杂对话任务的能力与表现。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/2564',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/nlkqulm5g'
        },
        {
            name: 'Falcon-40B-Instruct',
            nameDesc:
                '模型由TII开源， <a href="https://huggingface.co/tiiuae/falcon-40b-instruct" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Falcon-40B-Instruct" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由TII开源， <a href="https://huggingface.co/tiiuae/falcon-40b-instruct" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Falcon-40B-Instruct" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['TII (开源)'],
            type: '文本生成',
            desc: '由TII研发的仅使用解码器的模型，并在Baize的混合数据集上进行微调，具备优异的推理效果。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/4579/5603',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Ulkquqcwq'
        },
        {
            name: 'AquilaChat-7B',
            icon: require('assets/wenxin/Aquila.png').default,
            nameDesc:
                '模型由智源研究院开源， <a href="https://huggingface.co/BAAI/AquilaChat-7B" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="AquilaChat-7B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由智源研究院开源， <a href="https://huggingface.co/BAAI/AquilaChat-7B" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="AquilaChat-7B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['智源研究院 (开源)'],
            type: '文本生成',
            desc: '由智源研究院研发，基于Aquila-7B训练的对话模型，支持流畅的文本对话及多种语言类生成任务，通过定义可扩展的特殊指令规范，实现 AquilaChat对其它模型和工具的调用，且易于扩展。',
            support: '支持部署调用',
            // 9.3
            onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/AquilaChat-7B',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/4578/5601',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/ollz02e7i'
        },
        {
            name: 'AquilaCode-multi',
            icon: require('assets/wenxin/Aquila.png').default,
            nameDesc:
                '模型由智源研究院开源， <a href="https://huggingface.co/BAAI/AquilaCode-multi" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="AquilaCode-multi" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由智源研究院开源， <a href="https://huggingface.co/BAAI/AquilaCode-multi" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="AquilaCode-multi" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['智源研究院 (开源)'],
            type: '代码生成',
            desc: '由智源研究院研发，基于Aquila-7B训练的代码生成模型，使用经过高质量过滤且有合规开源许可的代码数据进行训练，数据量约为其他开源代码生成模型的10～40%。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/4578/5602',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Zllz03hey'
        },
        {
            name: 'bge-large-zh',
            nameDesc:
                '模型由智源研究院开源， <a href="https://huggingface.co/BAAI/bge-large-zh" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="bge-large-zh" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由智源研究院开源， <a href="https://huggingface.co/BAAI/bge-large-zh" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="bge-large-zh" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['智源研究院 (开源)'],
            type: '文本表示',
            desc: '由智源研究院研发的中文版文本表示模型，可将任意文本映射为低维稠密向量，以用于检索、分类、聚类或语义匹配等任务，并可支持为大模型调用外部知识。',
            support: '支持部署调用',
            // 9.3
            // onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/bge-large-zh',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/dllz04sro'
        },
        {
            name: 'bge-large-en',
            nameDesc:
                '模型由智源研究院开源， <a href="https://huggingface.co/BAAI/bge-large-en" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="bge-large-en" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由智源研究院开源， <a href="https://huggingface.co/BAAI/bge-large-en" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="bge-large-en" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['智源研究院 (开源)'],
            type: '文本表示',
            desc: '由智源研究院研发的英文版文本表示模型，可将任意文本映射为低维稠密向量，以用于检索、分类、聚类或语义匹配等任务，并可支持为大模型调用外部知识。',
            support: '支持部署调用',
            // onlineTest: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest/bge-large-en',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/mllz05nzk'
        },
        // 12.21 （推迟）
        {
            name: 'tao-8k',
            nameDesc:
                '模型由amu开源， <a href="https://huggingface.co/amu/tao-8k" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="tao-8k" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由amu开源， <a href="https://huggingface.co/amu/tao-8k" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="tao-8k" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['amu (开源)'],
            type: '文本表示',
            desc: 'tao-8k是由Huggingface开发者amu研发并开源的长文本向量表示模型，支持8k上下文长度，模型效果在C-MTEB上居前列，是当前最优的中文长文本embeddings模型之一。',
            support: '支持部署调用',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/7lq0buxys'
        },
        {
            name: 'RWKV-4-World',
            nameDesc:
                '模型由RWKV（BlinkDL）开源，<br /> <a href="https://huggingface.co/BlinkDL/rwkv-4-world" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="RWKV-4-World" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由RWKV（BlinkDL）开源， <a href="https://huggingface.co/BlinkDL/rwkv-4-world" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="RWKV-4-World" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['RWKV (开源)'],
            type: '文本生成',
            desc: '由香港大学物理系校友彭博研发并开源，结合了Transformer与RNN的优点，具备优秀的推理性能与效果。RWKV-4-World在100多种语言上进行训练，具备优秀的英语零样本与上下文学习能力。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/2561',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/plkque69d'
        },
        {
            name: 'RWKV-4-pile-14B',
            nameDesc:
                '模型由RWKV（BlinkDL）开源， <a href="https://huggingface.co/BlinkDL/rwkv-4-pile-14b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="RWKV-4-pile-14B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由RWKV（BlinkDL）开源， <a href="https://huggingface.co/BlinkDL/rwkv-4-pile-14b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="RWKV-4-pile-14B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['RWKV (开源)'],
            type: '文本生成',
            // eslint-disable-next-line max-len
            desc: '由香港大学物理系校友彭博研发并开源，结合了Transformer与RNN的优点，具备优秀的推理性能与效果。RWKV-4-pile-14B为在 Pile 数据集上训练的 L40-D5120 因果语言模型。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/4577/5596',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Rlkqufn6w'
        },
        {
            name: 'RWKV-Raven-14B',
            nameDesc:
                '模型由RWKV开源， <a href="https://huggingface.co/RWKV/rwkv-raven-14b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="RWKV-Raven-14B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由RWKV开源， <a href="https://huggingface.co/RWKV/rwkv-raven-14b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="RWKV-Raven-14B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['RWKV (开源)'],
            type: '文本生成',
            // eslint-disable-next-line max-len
            desc: '由香港大学物理系校友彭博研发并开源，结合了Transformer与RNN的优点，具备优秀的推理性能与效果。RWKV-Raven-14B为在Pile数据集上训练，并在Alpaca、CodeAlpaca等上进行微调的Chat版本。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/4577/5597',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/ylkqujafk'
        },
        {
            name: 'OpenLLaMA-7B',
            nameDesc:
                '模型由OpenBuddy开源， <a href="https://huggingface.co/OpenBuddy/openbuddy-openllama-7b-v5-fp16" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="OpenLLaMA-7B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由OpenBuddy开源， <a href="https://huggingface.co/OpenBuddy/openbuddy-openllama-7b-v5-fp16" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="OpenLLaMA-7B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['OpenBuddy (开源)'],
            type: '文本生成',
            desc: '在Meta AI研发的Llama模型基础上，OpenBuddy进行调优，涵盖了更广泛的词汇、通用字符与token嵌入，具备与Llama相当的性能与推理效果。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/2563',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Ylkquk74n'
        },
        {
            name: 'Dolly-12B',
            nameDesc:
                '模型由Databricks开源， <a href="https://huggingface.co/databricks/dolly-v2-12b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Dolly-12B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由Databricks开源， <a href="https://huggingface.co/databricks/dolly-v2-12b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Dolly-12B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['Databricks (开源)'],
            type: '文本生成',
            desc: '由Databricks训练的指令遵循大语言模型。基于pythia-12b，由InstructGPT论文的能力域中生成的约15k指令/响应微调记录训练。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/2565',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/clkquqy0d'
        },
        {
            name: 'MPT-7B-Instruct',
            nameDesc:
                '模型由MosaicML开源， <a href="https://huggingface.co/mosaicml/mpt-7b-instruct" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="MPT-7B-Instruct" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由MosaicML开源， <a href="https://huggingface.co/mosaicml/mpt-7b-instruct" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="MPT-7B-Instruct" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['MosaicML (开源)'],
            type: '文本生成',
            // eslint-disable-next-line max-len
            desc: 'MPT-7B-Instruct是一种短格式指令遵循模型，由MosaicML研发，基于MPT-7B模型在Databricks Dolly-15k、HH-RLHF数据集上调优的版本，采用经过修改的仅使用解码器的transformer架构。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/detail/2566',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/2lkqurg0f'
        },
        {
            name: 'MPT-30B-instruct',
            nameDesc:
                '模型由MosaicML开源， <a href="https://huggingface.co/mosaicml/mpt-30b-instruct" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="MPT-30B-instruct" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由MosaicML开源， <a href="https://huggingface.co/mosaicml/mpt-30b-instruct" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="MPT-30B-instruct" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['MosaicML (开源)'],
            type: '文本生成',
            desc: 'MPT-30M-Instruct是一种短格式指令遵循模型，由MosaicML研发，基于MPT-7B模型在更为丰富的数据集上调优的版本，采用经过修改的仅使用解码器的transformer架构。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/4580/5604',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Elkqus29q'
        },
        {
            name: 'Cerebras-GPT-13B',
            nameDesc:
                '模型由Cerebras开源， <a href="https://huggingface.co/cerebras/Cerebras-GPT-13B" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Cerebras-GPT-13B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由Cerebras开源， <a href="https://huggingface.co/cerebras/Cerebras-GPT-13B" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Cerebras-GPT-13B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['Cerebras (开源)'],
            type: '文本生成',
            desc: '由Cerebras研发并开源，使用 Chinchilla 公式进行训练的13B参数GPT模型，可为给定的计算预算提供最高的准确性，具备更低的训练成本与功耗。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/4581/5605',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/alkquxtae'
        },
        {
            name: 'Pythia-12B',
            nameDesc:
                '模型由EleutherAI开源， <a href="https://huggingface.co/EleutherAI/pythia-12b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="Pythia-12B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由EleutherAI开源， <a href="https://huggingface.co/EleutherAI/pythia-12b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="Pythia-12B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['EleutherAI (开源)'],
            type: '文本生成',
            desc: '由EleutherAI研发并开源，在Pile数据集上训练的12B参数transformer语言模型。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/4582/5606',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/wlkquzut1'
        },
        {
            name: 'GPT-J-6B',
            nameDesc:
                '模型由EleutherAI开源， <a href="https://huggingface.co/EleutherAI/gpt-j-6b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="GPT-J-6B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由EleutherAI开源， <a href="https://huggingface.co/EleutherAI/gpt-j-6b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="GPT-J-6B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['EleutherAI (开源)'],
            type: '文本生成',
            desc: 'EleutherAI开发的6B参数transformer模型，基于 Mesh Transformer JAX 训练。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/4583/5608',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Flkqv5qpm'
        },
        {
            name: 'GPT-NeoX-20B',
            nameDesc:
                '模型由EleutherAI开源， <a href="https://huggingface.co/EleutherAI/gpt-neox-20b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="GPT-NeoX-20B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由EleutherAI开源， <a href="https://huggingface.co/EleutherAI/gpt-neox-20b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="GPT-NeoX-20B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['EleutherAI (开源)'],
            type: '文本生成',
            desc: '由EleutherAI开发，使用GPT-NeoX库，基于Pile训练的200亿参数自回归语言模型，模型结构与GPT-3、GPT-J-6B类似。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/4584/5610',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/jlkqv6bsg'
        },
        {
            name: 'OA-Pythia-12B-SFT-4',
            nameDesc:
                '模型由OpenAssistant开源， <a href="https://huggingface.co/OpenAssistant/oasst-sft-4-pythia-12b-epoch-3.5" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="OA-Pythia-12B-SFT-4" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由OpenAssistant开源， <a href="https://huggingface.co/OpenAssistant/oasst-sft-4-pythia-12b-epoch-3.5" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="OA-Pythia-12B-SFT-4" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['OpenAssistant (开源)'],
            type: '文本生成',
            desc: '基于Pythia12B，Open-Assistant项目的第4次SFT迭代模型。该模型在 https://open-assistant.io/ 收集的人类反馈的对话数据上进行微调。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/4582/5607',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Ulkqv6tvr'
        },
        {
            name: 'GPT4All-J',
            nameDesc:
                '模型由Nomic AI开源， <a href="https://huggingface.co/nomic-ai/gpt4all-j" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="GPT4All-J" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由Nomic AI开源， <a href="https://huggingface.co/nomic-ai/gpt4all-j" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="GPT4All-J" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['Nomic AI (开源)'],
            type: '文本生成',
            desc: '由Nomic AI研发、基于GPT-J在包括文字问题、多轮对话、代码、诗歌、歌曲和故事在内的大量语料上进行精调训练生成的模型。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/4583/5609',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Flkqv7bwj'
        },
        {
            name: 'StarCoder',
            nameDesc:
                '模型由BigCode开源， <a href="https://huggingface.co/bigcode/starcoder" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="StarCoder" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由BigCode开源， <a href="https://huggingface.co/bigcode/starcoder" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="StarCoder" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['BigCode(开源)'],
            type: '代码生成',
            desc: '由BigCode研发的15.5B参数模型，基于The Stack (v1.2)的80+编程语言训练，训练语料来自Github。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/4585/5611',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Jlkqv7szj'
        },
        {
            name: 'StableLM-Alpha-7B',
            nameDesc:
                '模型由StabilityAI开源， <a href="https://huggingface.co/stabilityai/stablelm-base-alpha-7b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页PC-内置丰富优质的大模型能力"  data-track-name="StableLM-Alpha-7B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            nameDesc_m:
                '模型由StabilityAI开源， <a href="https://huggingface.co/stabilityai/stablelm-base-alpha-7b" target="_blank" rel="noopener noreferrer" data-track-category="千帆产品页wise-内置丰富优质的大模型能力"  data-track-name="StableLM-Alpha-7B" data-track-value="了解更多">了解更多<i class="tzfont tzfont-arrow-right" ></i></a>',
            brand: ['StabilityAI (开源)'],
            type: '文本生成',
            desc: 'Stability AI开发的7B参数的NeoX transformer架构语言模型，支持4k上下文。',
            support: '支持部署调用',
            deployment: 'https://console.bce.baidu.com/qianfan/modelcenter/model-version/detail/4586/5612',
            doc: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/7lkqv8ccs'
        }
    ]
};

export const productFeature = {
    title: '功能覆盖大模型全生命周期'
};

export const typicalCase = [
    {
        title: '电商',
        content: '大模型帮助电商平台优化用户体验，提升销售额度，降低运营成本。',
        img: require('assets/wenxin/e-commerce.jpg').default,
        imgMobile: require('assets/wenxin/commercial_m.jpg').default,
        fontColor: '#fff'
    },
    {
        title: '短视频',
        content: '大模型辅助生成图文内容，启迪创意灵感，提高创作效率。',
        img: require('assets/wenxin/short-video.jpg').default,
        imgMobile: require('assets/wenxin/video_m.jpg').default,
        fontColor: '#fff'
    },
    {
        title: '教育',
        content: '大模型辅助个性化教育发展规划，推动精准决策，提升教学效果。',
        img: require('assets/wenxin/education.jpg').default,
        imgMobile: require('assets/wenxin/education_m.jpg').default,
        fontColor: '#000'
    },
    {
        title: '游戏',
        content: '大模型辅助游戏设计、玩法，优化游戏体验，提升用户日活。',
        img: require('assets/wenxin/game.jpg').default,
        imgMobile: require('assets/wenxin/game_m.jpg').default,
        fontColor: '#000'
    },
    {
        title: '医疗',
        content: '大模型辅助医疗诊断并提供治疗建议，提供更准确、全面的疾病检测与预测。',
        img: require('assets/wenxin/medical-treatment.jpg').default,
        imgMobile: require('assets/wenxin/medical_m.jpg').default,
        fontColor: '#fff'
    },
    {
        title: '金融',
        content: '大模型辅助银行与金融机构进行风险评估、市场预测与投资决策。',
        img: require('assets/wenxin/financial.jpg').default,
        imgMobile: require('assets/wenxin/finance_m.jpg').default,
        fontColor: '#fff'
    }
];

export const support = {
    name: '完善的技术支持与社区交流',
    nameMobile: '技术支持与社区交流',
    arr: [
        {
            title: '帮助手册',
            content: '探索和发现千帆平台大模型能力，体验大模型',
            links: [
                {
                    text: '千帆平台文档',
                    url: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Slfmc9dds'
                },
                {
                    text: '定价说明',
                    url: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/Blfmc9dlf'
                }
            ]
        },
        {
            title: '技术指南',
            content: '千帆大模型技术开发文档，帮助使用API或SDK',
            links: [
                {
                    text: 'API文档',
                    url: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/flfmc9do2'
                },
                {
                    text: 'SDK文档',
                    url: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/wlmhm7vuo'
                }
            ]
        },
        {
            title: '千帆社区',
            content: '了解大模型最新动态，与开发者一起交流学习',
            links: [
                {
                    text: '千帆社区',
                    url: 'https://cloud.baidu.com/qianfandev'
                }
            ]
        }
    ]
};

export const products = {
    name: '新一代人工智能生态产品',
    arr: [
        {
            title: '百度智能云千帆AppBuilder',
            content: '分钟级超低门槛AI原生应用搭建',
            links: [
                {
                    text: '了解更多',
                    url: 'https://cloud.baidu.com/product/AppBuilder'
                }
            ]
        },
        {
            title: 'BML全功能AI开发平台',
            content: '基于文心大模型完成一站式AI开发',
            links: [
                {
                    text: '了解更多',
                    url: 'https://ai.baidu.com/bml/'
                }
            ]
        },
        {
            title: '智能代码助手 Baidu Comate',
            content: '提升编码效率，释放“十倍”软件生产力',
            links: [
                {
                    text: '了解更多',
                    url: 'https://cloud.baidu.com/product/comate-public.html'
                }
            ]
        },
        {
            title: '增值服务',
            content: '提供培训、咨询等技术支持服务',
            links: [
                {
                    text: '了解更多',
                    url: 'https://cloud.baidu.com/product/llmservice.html'
                }
            ]
        }
    ]
};

export const MODEL_FEATURE = {
    title: '可以体验以下功能',
    types: [
        {
            title: '大模型推理',
            list: [
                {
                    key: 1,
                    img: require('assets/wenxin/model/1.png').default,
                    label: '创建应用获取密钥'
                },
                {
                    key: 2,
                    img: require('assets/wenxin/model/2.png').default,
                    label: '进入体验中心进行交互式快速体验'
                },
                {
                    key: 3,
                    img: require('assets/wenxin/model/3.png').default,
                    label: '调用API或SDK进行业务集成'
                },
                {
                    key: 4,
                    img: require('assets/wenxin/model/4.png').default,
                    label: '查看调用监控实时数据，确保服务稳定'
                }
            ],
            btns: [
                {
                    key: 1,
                    label: '在线体验',
                    href: 'https://console.bce.baidu.com/qianfan/ais/console/onlineTest'
                },
                {
                    key: 2,
                    label: '使用推理服务',
                    href: 'https://console.bce.baidu.com/qianfan/ais/console/onlineService'
                }
            ]
        },
        {
            title: '大模型精调',
            list: [
                {
                    key: 1,
                    img: require('assets/wenxin/model/1.png').default,
                    label: '准备训练数据，洞察+处理，打造高质量数据'
                },
                {
                    key: 2,
                    img: require('assets/wenxin/model/2.png').default,
                    label: '确定基础模型、精调方法和训练参数，启动训练'
                },
                {
                    key: 3,
                    img: require('assets/wenxin/model/3.png').default,
                    label: '模型训练完成，人工或自动评估模型效果'
                },
                {
                    key: 4,
                    img: require('assets/wenxin/model/4.png').default,
                    label: '模型部署为公有云服务，在线体验或接口调用'
                }
            ],
            btns: [
                {
                    key: 1,
                    label: '使用SFT',
                    href: 'https://console.bce.baidu.com/qianfan/train/sft/list'
                },
                {
                    key: 2,
                    label: '使用Post-pretrain',
                    href: 'https://console.bce.baidu.com/qianfan/train/postPretrain/list'
                }
            ]
        }
    ]
};

export const reason = {
    title: '选择我们的理由',
    list: [
        {
            key: 1,
            label1: '更全面',
            label2: '最全工具链',
            label3: '覆盖大模型全生命周期',
            img: require('assets/wenxin/model/comprehensive.png').default,
            imgMobile: require('assets/wenxin/model/comprehensive_m.png').default
        },
        {
            key: 2,
            label1: '更精准',
            label2: '最佳中文理解',
            label3: '凝聚文心大模型最佳实践',
            img: require('assets/wenxin/model/precision.png').default,
            imgMobile: require('assets/wenxin/model/precision_m.png').default
        },
        {
            key: 3,
            label1: '更高速',
            label2: '集成先进的加速框架',
            label3: '训练与推理性能大幅提升',
            img: require('assets/wenxin/model/high.png').default,
            imgMobile: require('assets/wenxin/model/high_m.png').default
        },
        {
            key: 4,
            label1: '更经济',
            label2: '成本最高降低50%',
            label3: '训练与推理成本大幅降低',
            img: require('assets/wenxin/model/economies.png').default,
            imgMobile: require('assets/wenxin/model/economies_m.png').default
        }
    ]
};

export const serviceMode = [
    {
        title: '私有化',
        content: '适合强数据安全与隐私需求的场景',
        content_icon: require('assets/wenxin/contact-us.png').default,
        desc: ['独立软件平台交付，灵活可靠', '资源数据自有可控，安全保障', '深度集成企业系统，统一管理'],
        btn: '我要咨询',
        link: 'https://cloud.baidu.com/survey/consulting.html'
    },
    {
        title: '公有云',
        content: '适合快速体验效果并训练模型的场景',
        content_icon: require('assets/wenxin/contact-us-white.png').default,
        desc: ['公共资源服务推理，超高性价比', '私有资源服务托管，保障请求并发', '7 × 24 小时工单支持，在线技术服务'],
        btn: '立即使用',
        link: process.env.WX_TEST_HREF
    },
    {
        title: '软硬一体',
        content_icon: require('assets/wenxin/contact-us.png').default,
        content: '适合开箱即用且高可用需求的场景',
        desc: ['软硬一体交付，高可用保证', '多算力资源适配，国产化支持', '开箱即用，安全高效'],
        btn: '我要咨询',
        link: 'https://cloud.baidu.com/survey/consulting.html'
    }
];

export const serviceMode_m = [
    {
        title: '公有云',
        content: '适合快速体验效果并训练模型的场景',
        content_icon: require('assets/wenxin/contact-us-white-m.png').default,
        desc: ['公共资源服务推理，超高性价比', '私有资源服务托管，保障请求并发', '7 × 24 小时工单支持，在线服务'],
        desc_icon: require('assets/wenxin/delivery-check.png').default,
        btn: '立即使用',
        link: process.env.WX_TEST_HREF
    },
    {
        title: '私有化',
        content: '适合强数据安全与隐私需求的场景',
        content_icon: require('assets/wenxin/contact-us-m.png').default,
        desc: ['独立软件平台交付，灵活可靠', '资源数据自有可控，安全保障', '深度集成企业系统，统一管理'],
        desc_icon: require('assets/wenxin/delivery-check.png').default,
        btn: '我要咨询',
        link: 'https://cloud.baidu.com/survey/consulting.html'
    },
    {
        title: '软硬一体',
        content_icon: require('assets/wenxin/contact-us-m.png').default,
        content: '适合开箱即用且高可用需求的场景',
        desc: ['软硬一体交付，高可用保证', '多算力资源适配，国产化支持', '开箱即用，安全高效'],
        desc_icon: require('assets/wenxin/delivery-check.png').default,
        btn: '我要咨询',
        link: 'https://cloud.baidu.com/survey/consulting.html'
    }
];

export const footer = {
    about: {
        title: '关于我们',
        des: [
            {
                text: '百度智能云千帆大模型平台，一站式企业级大模型开发及服务运行平台',
                id: 0
            },
            {
                text: '文心大模型企业级服务唯一入口，并且涵盖丰富的第三方开源大模型',
                id: 1
            },
            {
                text: '提供先进的生成式AI生产及应用全流程开发工具链和整套开发环境',
                id: 2
            },
            {
                text: '从数据管理到服务云端部署，完备的流程助力各行业生成式AI应用需求落地',
                id: 3
            }
        ]
    },
    resources: {
        title: '社区与资源',
        list: [
            {
                id: 0,
                text: '千帆社区',
                href: 'https://cloud.baidu.com/qianfandev',
                trackInfo: {
                    'data-track-category': '千帆产品页PC-底部导航',
                    'data-track-name': '社区与资源',
                    'data-track-value': '千帆社区'
                },
                trackInfo_m: {
                    'data-track-category': '千帆产品页wise-底部导航',
                    'data-track-name': '社区与资源',
                    'data-track-value': '千帆社区'
                }
            },
            {
                id: 1,
                text: '专家服务',
                href: 'https://cloud.baidu.com/doc/support/index.html#expert',
                trackInfo: {
                    'data-track-category': '千帆产品页PC-底部导航',
                    'data-track-name': '社区与资源',
                    'data-track-value': '专家服务'
                },
                trackInfo_m: {
                    'data-track-category': '千帆产品页wise-底部导航',
                    'data-track-name': '社区与资源',
                    'data-track-value': '专家服务'
                }
            },
            {
                id: 2,
                text: '文档中心',
                href: 'https://cloud.baidu.com/doc/WENXINWORKSHOP/s/7ltgucw50',
                trackInfo: {
                    'data-track-category': '千帆产品页PC-底部导航',
                    'data-track-name': '社区与资源',
                    'data-track-value': '文档中心'
                },
                trackInfo_m: {
                    'data-track-category': '千帆产品页wise-底部导航',
                    'data-track-name': '社区与资源',
                    'data-track-value': '文档中心'
                }
            },
            {
                id: 3,
                text: 'SDK中心',
                href: 'https://cloud.baidu.com/doc/Developer/index.html',
                trackInfo: {
                    'data-track-category': '千帆产品页PC-banner',
                    'data-track-name': '主按钮',
                    'data-track-value': 'SDK中心'
                },
                trackInfo_m: {
                    'data-track-category': '千帆产品页wise-banner',
                    'data-track-name': '主按钮',
                    'data-track-value': 'SDK中心'
                }
            },
            {
                id: 4,
                text: 'API平台',
                href: 'https://cloud.baidu.com/doc/API/index.html',
                trackInfo: {
                    'data-track-category': '千帆产品页PC-底部导航',
                    'data-track-name': '社区与资源',
                    'data-track-value': 'API平台'
                },
                trackInfo_m: {
                    'data-track-category': '千帆产品页wise-底部导航',
                    'data-track-name': '社区与资源',
                    'data-track-value': 'API平台'
                }
            },
            {
                id: 5,
                text: '培训与认证',
                href: 'https://cloud.baidu.com/doc/support/index.html#training',
                trackInfo: {
                    'data-track-category': '千帆产品页PC-底部导航',
                    'data-track-name': '社区与资源',
                    'data-track-value': '培训与认证'
                },
                trackInfo_m: {
                    'data-track-category': '千帆产品页wise-底部导航',
                    'data-track-name': '社区与资源',
                    'data-track-value': '培训与认证'
                }
            }
        ]
    },
    service: {
        title: '服务与支持',
        list: [
            {
                id: 0,
                text: '支持热线：400-920-8999转1'
            },
            {
                id: 1,
                text: '我的工单',
                href: 'https://console.bce.baidu.com/ticket/#/ticket/list',
                // href: 'https://console.bce.baidu.com/support/?u=ModelBuilder#/ticket/#/ticket/create?productId=279',
                trackInfo: {
                    'data-track-category': '千帆产品页PC-底部导航',
                    'data-track-name': '服务与支持',
                    'data-track-value': '我的工单'
                },
                trackInfo_m: {
                    'data-track-category': '千帆产品页wise-底部导航',
                    'data-track-name': '服务与支持',
                    'data-track-value': '我的工单'
                }
            },
            {
                id: 2,
                text: '合作咨询',
                href: 'https://cloud.baidu.com/znzs/pre-sale#/znzs/presale/https%253A%252F%252Fcloud.baidu.com%252Fsupport%252Fplan.html',
                trackInfo: {
                    'data-track-category': '千帆产品页PC-底部导航',
                    'data-track-name': '服务与支持',
                    'data-track-value': '合作咨询'
                },
                trackInfo_m: {
                    'data-track-category': '千帆产品页wise-底部导航',
                    'data-track-name': '服务与支持',
                    'data-track-value': '合作咨询'
                }
            }
        ]
    },
    qrCode: require('assets/wenxin/rcode.png').default
};
