/**
 * @file 注册弹窗
 */

import {useOnMount, useStateRef} from '@baidu/bce-hooks';
import {UModalMask} from './UModalMask';
import {getEnv} from '~/utils/getEnv';
import {pushProduct2Crm} from '~/utils/pushProduct2Crm';

/**
 * 激活弹窗
 * @param {object} props
 * @param {boolean} props.maskClosable mask是否可点
 * @param {boolean} props.hideCloseIcon 是否显示
 * @param {function} props.onOk 登录成功
 * @param {function} props.onCancel 登录失败
 * @returns JSX.Element
 */
export const UActivateModal = (props) => {
    const url =
        getEnv() === 'SANDBOX'
            ? 'https://qasandbox.bcetest.baidu.com/iam/#/iam/user/v2/activate~hideBar=1'
            : 'https://console.bce.baidu.com/iam/#/iam/user/v2/activate~hideBar=1';
    const [show, setShow] = useStateRef(true);
    const cancelHandler = () => {
        setShow(false);
        props.onCancel && props.onCancel();
    };

    const close = () => {
        setShow(false);
    };

    useOnMount(() => {
        const handleMessage = (e) => {
            if (e.data && e.data.activateSuccess) {
                close();
                pushProduct2Crm();
                props.onOk && props.onOk();
            }
        };
        window.addEventListener('message', handleMessage, false);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    });

    return (
        <UModalMask show={show} maskClosable={props.maskClosable} onClose={cancelHandler}>
            <>
                <iframe
                    className="portal-activate-iframe"
                    width="700"
                    height="560"
                    src={url}
                />
                {!props.hideCloseIcon && <div className="portal-activate-iframe-close-icon" onClick={cancelHandler} />}
            </>
        </UModalMask>
    );
};
