import {Support} from '~/components/sections/wenxin/index';
const mp4 = 'https://aip-static.cdn.bcebos.com/landing/product/last-screen-bg-2024-3-20-0.mp4';

export default function Product(props) {
    const {products} = props;
    return (
        <div id="products" style={{paddingTop: '80px'}}>
            <div className="Products">
                <video muted autoPlay loop playsinline="true" webkit-playsinline="true">
                    <source src={mp4} type="video/mp4" />
                </video>
                <div className="Products-main">
                    <Support support={products} className="products-support-item" />
                </div>
            </div>
        </div>
    );
}
