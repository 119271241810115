/**
 * @file: bml.js
 */
import {specialOffer} from './specialOffer';
// 百度云 https://cloud.baidu.com/product/bml
const whenCloudPlat = (a, b) => (process.env.IS_CLOUD ? a : b);

export default {
    config: {
        trackType: 'BML官网',
        layout: 'bml',
        page: 'bml'
    },
    banner: {
        name: 'bml',
        title: whenCloudPlat('飞桨BML 全功能AI开发平台', 'BML 全功能AI开发平台'),
        subTitle:
            '为企业及个人开发者提供机器学习和深度学习一站式AI开发服务，并提供高性价比的算力资源，助力企业快速构建高精度AI应用',
        // bg: require('assets/banner/bmll.jpg').default,
        align: 'center',
        href: 'https://ai.baidu.com/forum/topic/show/991352?track=bmlbanner',
        tag: whenCloudPlat('飞桨BML入选Forrester领导者象限', 'BML入选Forrester领导者象限'),
        buttonGroup: [
            {
                name: '立即使用',
                href: 'https://ai.baidu.com/bml/app/overview',
                size: 'small',
                type: 'primary',
                target: '_self',
                trackTag: '立即使用_官网上方',
                attrs: {
                    'data-track-action': 'click',
                    'data-track-category': 'BML立即使用',
                    'data-track-name': 'BML-产品页面-立即使用',
                    'data-track-value': ''
                }
            },
        ],
        bmlBanner: [
            {
                href: 'https://www.wavesummit.com.cn/#/',
                bg: require('assets/banner/bml-wavesummit.jpg').default
            },
            {
                href: 'https://ai.baidu.com/ai-doc/BML/1lb1z0yk5',
                bg: require('assets/banner/bml-upgrade.png').default
            },
            {
                href: 'https://aistudio.baidu.com/aistudio/topic/1000?track=BMLguanwang',
                bg: require('assets/banner/bml-estate.jpg').default
            },
            {
                href: 'https://ai.baidu.com/ai-doc/BML/ql15tvq1f',
                bg: require('assets/banner/bml-consoles.jpg').default
            },
            {
                href: 'https://www.paddlepaddle.org.cn/wenxin',
                bg: require('assets/banner/bml-cardModelNlp.png').default
            }
        ],
        cardAD: {
            title: whenCloudPlat('飞桨BML 全功能AI开发平台', 'BML 全功能AI开发平台'),
            intro: [
                {
                    img: require('assets/bml/bml-document.png').default,
                    title: '使用文档',
                    href: 'https://ai.baidu.com/ai-doc/BML/Ekhvg0smz',
                    target: '_blank'
                },
                {
                    img: require('assets/bml/bml-consult.png').default,
                    title: '项目咨询',
                    href: 'https://ai.baidu.com/easydl/custom/',
                    target: '_blank'
                },
                {
                    img: require('assets/bml/bml-video.png').default,
                    title: '视频教程',
                    href: '#video',
                    target: ''
                }
            ],
            ad: [
                {
                    tag: '线上直播',
                    desc: 'AI快车道- BML线上课程集锦',
                    href: 'https://ai.baidu.com/forum/topic/show/991391?track=guanwang'
                },
                {
                    tag: '产品上新',
                    desc: 'BML全新上线可视化建模Beta版本',
                    href: 'https://ai.baidu.com/forum/topic/show/1780757'
                },
                {
                    tag: '热门活动',
                    desc: '飞桨BML【3天AI进阶实战营】',
                    href: 'https://ai.baidu.com/forum/topic/show/991352?track=guanwang'
                },
                {
                    tag: '亮点功能',
                    desc: 'BML智能边缘控制台全新上线',
                    href: 'https://ai.baidu.com/forum/topic/show/991172?track=guanwang'
                }
            ],
            bg: require('assets/bml/bml-module.png').default,
            video: 'https://baidu-ai-ar-1512380202189-8487.bj.bcebos.com/20210518-百度BML-修改（高清字幕版）01.mp4',
            txtbg: require('assets/bml/bml-txtbg.png').default,
            iconVideo: require('assets/bml/icon-video.png').default,
        }
    },
    productOverview: {
        title: '完备功能支持一站式AI开发',
        image: require('assets/bml/summary.png').default,
        use: {
            text: '立即使用',
            href: 'https://ai.baidu.com/bml/app/overview'
        }
    },
    specialOffer,
    productAdvantages: {
        title: '兼具效果与性能的卓越产品体验',
        tag: '产品优势',
        data: [
            {
                // icon: 'stable',
                img: require('assets/bml/advantage-1.png').default,
                name: '高性能的算力',
                desc: '支持多机多卡分布式训练<br/>提供丰富算力机器资源选型<br/>按需获取资源，灵活便捷',
                linkText: '了解详情',
                linkHref: 'https://ai.baidu.com/ai-doc/BML/kkhvg3brj#高性能的算力支持'
            },
            {
                // icon: 'app',
                img: require('assets/bml/advantage-2.png').default,
                name: '强大的数据处理服务',
                desc:
                    '业界首个开放的数据采集和清洗服务<br/>支持自动数据增强，助力模型效果有效提升<br/>智能标注与多人标注双管齐下，标注效率提升<b>80%</b>',
                linkText: '了解详情',
                linkHref: 'https://ai.baidu.com/ai-doc/BML/kkhvg3brj#强大的数据处理服务'
            },
            {
                // icon: 'app',
                img: require('assets/bml/advantage-3.png').default,
                name: '高精度训练效果',
                desc:
                    '预置百度超大规模视觉预训练模型<br/>预置业界领先的NLP预训练模型ERNIE<br/>支持自动超参搜索、不平衡数据处理等特性<br/>支持视觉、文本、机器学习等丰富任务类型',
                linkText: '了解详情',
                linkHref: 'https://ai.baidu.com/ai-doc/BML/kkhvg3brj#高精度训练效果'
            },
            {
                // icon: 'hexagon',
                img: require('assets/bml/advantage-4.png').default,
                name: '丰富完善的开发方案',
                desc: whenCloudPlat(
                    // eslint-disable-next-line max-len
                    '超越原生<b>Jupyter Notebook</b>的云端开发环境<br />适合<b>AI</b>初学者的脚本调参开发方式<br />支持多种框架的作业建模<br />本地客户端<b>飞桨BML CodeLab</b>，预置海量插件',
                    // eslint-disable-next-line max-len
                    '超越原生<b>Jupyter Notebook</b>的云端开发环境<br />适合<b>AI</b>初学者的脚本调参开发方式<br />支持多种框架的作业建模<br />本地客户端<b>BML CodeLab</b>，预置海量插件'
                ),
                linkText: '了解详情',
                linkHref: 'https://ai.baidu.com/ai-doc/BML/kkhvg3brj#丰富完善的开发方案'
            },
            {
                // icon: 'app',
                img: require('assets/bml/advantage-5.png').default,
                name: '广泛适配的模型推理服务',
                desc:
                    // eslint-disable-next-line max-len
                    '云服务：<b>SLA99.99%</b>，弹性扩缩容<br />本地服务器部署：多平台多语言支持<br />设备端SDK：<b>适配15+芯片</b>，最快提升<b>10</b>倍推理速度<br />软硬一体方案：<b>6</b>种方案全场景覆盖',
                linkText: '了解详情',
                linkHref: 'https://ai.baidu.com/ai-doc/BML/kkhvg3brj#广泛适配的模型推理服务'
            },
            {
                // icon: 'app',
                img: require('assets/bml/advantage-6.png').default,
                name: '全面丰富的交付方案',
                desc:
                    '<b>3</b>大交付方案，满足不同需求<br />公有云交付，即刻上手体验<br />私有化交付，保障数据安全<br />一体机交付，满足不同算力需求',
                linkText: '了解详情',
                linkHref: 'https://ai.baidu.com/ai-doc/BML/kkhvg3brj#全面丰富的交付方案'
            }
        ]
    },
    startUse: {
        title: whenCloudPlat('飞桨BML全功能AI开发平台', 'BML全功能AI开发平台'),
        desc: '为企业和个人开发者提供兼具广度与深度的机器学习栈',
        buttonGroup: [
            {
                text: '立即使用',
                trackTag: '立即使用_官网下方',
                href: 'https://ai.baidu.com/bml/app/overview',
                type: 'primary',
                attrs: {
                    'data-track-action': 'click',
                    'data-track-category': 'BML立即使用',
                    'data-track-name': 'BML-产品页面-立即使用',
                    'data-track-value': ''
                }
            },
            {
                text: 'AI项目定制',
                trackTag: '合作咨询_官网下方',
                href: `${process.env.CLOUDCOOPERATION}bml`,
                type: 'default-primary'
            }
        ],
        products: [
            {
                title: whenCloudPlat('飞桨EasyDL零门槛AI开发平台', 'EasyDL零门槛AI开发平台'),
                desc: '零算法基础定制高精度AI模型，无需深度学习专业知识，在线全流程可视化操作',
                url: 'https://ai.baidu.com/easydl'
            },
            {
                title: whenCloudPlat('飞桨BML私有化解决方案', 'BML私有化解决方案'),
                desc: '企业级AI开发平台，促进企业智能转型的新生产力',
                url: 'https://cloud.baidu.com/product/ai_bml.html'
            }
        ]
    },
    deliver: {
        title: '满足个人开发者与企业客户不同需求',
        id: 'deliver',
        tag: '交付方式',
        data: [
            {
                img: require('assets/bml/chain.png').default,
                title: '公有云服务',
                desc: '飞桨BML公有云平台是支持深度学习和机器学习的优异云端开发环境，按需按时获取算力资源，快速上手，即刻体验。',
                href: 'https://ai.baidu.com/bml/app/overview',
                linkName: '立即开始',
                tag: ['算力性价比高', '部署方式广'],
                application: '企业开发者'
            },
            {
                img: require('assets/bml/shareable.png').default,
                title: '私有化中台',
                desc: '飞桨BML产品支持纯软件私有化交付方式，可按需选择，在保障企业核心生产数据私密性和安全性的同时，提供灵活高效的个性化服务。',
                href: 'https://cloud.baidu.com/product/ai_bml.html',
                linkName: '查看详情',
                tag: ['企业资源、权限管理', '高级定制功能'],
                application: '大型企业客户'
            },
            {
                // icon: 'stable',
                img: require('assets/bml/deliver-3.png').default,
                title: '飞桨一体机',
                desc: '飞桨BML平台支持软硬件一体化交付方式，满足多种不同算力需求。在国内最早推出AI一体机，能力覆盖机房勘查、交付咨询、交付实施等一站式服务。',
                href: 'https://cloud.baidu.com/product/ape.html',
                linkName: '查看详情',
                tag: ['国产自主可控', '软硬一体'],
                application: '大型企业客户'
            }
        ]
    },
    paddleParadigm: {
        title: '集成飞桨全流程开发套件和丰富的产业场景使用范例',
        paradigm: {
            paradigmTitle: '飞桨产业级范例库',
            paradigmType: [
                {
                    name: '智慧城市',
                    icon: require('assets/bml/paddle_city.svg').default,
                    iconHover: require('assets/bml/paddle_city_hover.svg').default,
                    type: '5'
                },
                {
                    name: '工业/能源',
                    icon: require('assets/bml/paddle_energy.svg').default,
                    iconHover: require('assets/bml/paddle_energy_hover.svg').default,
                    type: '2'
                },
                {
                    name: '智慧安防',
                    icon: require('assets/bml/paddle_security.svg').default,
                    iconHover: require('assets/bml/paddle_security_hover.svg').default,
                    type: '3'
                },
                {
                    name: '智慧交通',
                    icon: require('assets/bml/paddle_traffic.svg').default,
                    iconHover: require('assets/bml/paddle_traffic_hover.svg').default,
                },
                {
                    name: '智慧金融',
                    icon: require('assets/bml/paddle_finance.svg').default,
                    iconHover: require('assets/bml/paddle_finance_hover.svg').default,
                },
                {
                    name: '互联网',
                    icon: require('assets/bml/paddle_internet.svg').default,
                    iconHover: require('assets/bml/paddle_internet_hover.svg').default,
                },
                {
                    name: '体育',
                    icon: require('assets/bml/paddle_sports.svg').default,
                    iconHover: require('assets/bml/paddle_sports_hover.svg').default,
                },
                {
                    name: '智慧教育',
                    icon: require('assets/bml/paddle_education.svg').default,
                    iconHover: require('assets/bml/paddle_education_hover.svg').default,
                },
                {
                    name: '智慧零售',
                    icon: require('assets/bml/paddle_resale.svg').default,
                    iconHover: require('assets/bml/paddle_resale_hover.svg').default,
                    type: '1'
                },
                {
                    name: '智慧农业',
                    icon: require('assets/bml/paddle_farming.svg').default,
                    iconHover: require('assets/bml/paddle_farming_hover.svg').default,
                    type: '4'
                },
            ],
            exploitTitle: '飞桨全流程开发套件',
            external: {
                vision: [
                    {
                        name: 'PaddleDetection',
                        href: '/bml/paddlepaddle#paddleDetection'
                    },
                    {
                        name: 'PaddleClas',
                        href: '/bml/paddlepaddle#paddleClas'
                    },
                    {
                        name: 'PaddleSeg',
                        href: '/bml/paddlepaddle#paddleSeg'
                    }
                ],
                voice: [
                    {
                        name: 'PaddleOCR',
                        href: '/bml/paddlepaddle#paddleOCR'
                    },
                    {
                        name: 'PaddleNLP',
                        href: '/bml/paddlepaddle#paddleNLP'
                    },
                    {
                        name: 'PaddleSpeech',
                        href: '/bml/paddlepaddle#paddleSpeech'
                    }
                ],
                science: [
                    {
                        name: 'PaddleScience',
                        href: '/bml/paddlepaddle#paddleScience'
                    }
                ],
                bottom: {
                    name: 'PaddleHub',
                    href: '/bml/paddlepaddle#paddleHub'
                }
            },
            img: require('assets/bml/paddle_exploit.png').default,
        },
    },
    characteristic: {
        title: '打造特色功能，让AI开发更简单',
        unfold: require('assets/bml/characteristic/bml-unfold.svg').default,
        hide: require('assets/bml/characteristic/bml-hide.svg').default,
        data: [
            {
                title: '数据处理',
                data: [
                    {
                        title: '智能标注',
                        desc: `用户只需标注少量数据，即可发起智能标注任务完成剩余数据的标注，从而大幅降低标注的时间成本。
                        在智能标注过程中，系统也会抽取置信度较低的难例由用户确认，在提升效率的同时保证标注质量。`,
                        image: require('assets/bml/characteristic/bml-characteristic1.1.png').default
                    },
                    {
                        title: '多人标注',
                        desc: `BML平台提供了多人标注功能，以方便大规模数据标注中的团队协作，从而提高数据标注效率。
                        任务发起后，系统会向团队成员自动分发任务量，标注成员提交任务后，管理员可以进行标注结果校验。`,
                        image: require('assets/bml/characteristic/bml-characteristic1.2.png').default
                    },
                    {
                        title: '数据清洗',
                        desc: `用户可以一键对数据集中质量不佳或冗余的数据进行清洗处理，提升数据质量。
                        目前支持去除模糊、近似等冗余图片，及过滤无人脸/人体图片等高级清洗功能，也可清洗文本的表情符号和url，以及繁体转简体。`,
                        image: require('assets/bml/characteristic/bml-characteristic1.3.png').default
                    }
                ]
            },
            {
                title: '模型构建',
                data: [
                    {
                        title: '独家超大规模预训练模型',
                        desc: `BML平台的预置模型调参模式，提供了百度独家的超大规模预训练模型、百度文心模型、飞桨精选模型等一系列优质的深度学习模型，
                        对比常规的预训练模型具有更高的精度和性能，有利于用户更快达到精度和性能目标。`,
                        image: require('assets/bml/characteristic/bml-characteristic2.1.png').default
                    },
                    {
                        title: '自动数据增强',
                        desc: `针对频发的训练数据集规模不足的问题，BML平台提供了丰富的数据增强算子，并内置自动搜索策略。开启自动数据增强后, 
                        系统会根据用户训练数据情况生成对应数据增强方案，从而扩展训练数据的规模，提升模型精度。`,
                        image: require('assets/bml/characteristic/bml-characteristic2.2.png').default
                    },
                    {
                        title: '自动超参搜索',
                        desc: `在模型开发过程中，网络配置的超参数将显著影响模型效果，开发者往往需要通过大量实验调整才能得到合适超参数，效率较低。针对这一问题，
                        BML平台开放了自动超参搜索策略，支持对超参数进行自动调优，提升模型精度。`,
                        image: require('assets/bml/characteristic/bml-characteristic2.3.png').default
                    }
                ]
            },
            {
                title: '模型管理',
                data: [
                    {
                        title: '本地导入',
                        desc: `BML平台支持用户将本地已训练好的模型导入到模型仓库中进行模型纳管，
                        将模型部署为在线服务，或转换为可进行离线部署的SDK等。`,
                        image: require('assets/bml/characteristic/bml-characteristic3.1.png').default
                    },
                    {
                        title: '多模型评估',
                        desc: `在项目中，开发者经常需要对多个模型测试、对比，选择性能最优的模型。针对该需求，BML平台提供多模型评估的功能，
                        支持用户创建评估任务，对比选定模型的多个版本在基准数据集下的性能，从而筛选出最优的模型。`,
                        image: require('assets/bml/characteristic/bml-characteristic3.2.png').default
                    },
                    {
                        title: '智能归因',
                        desc: `【智能归因】功能沉淀了百度在深度学习模型调优领域的最佳实践，通过样本特征与模型效果指标的深入关联性分析，自动定位影响模型效果的关键因素，
                        并提供一键式的数据增强和预置调参机制，帮助开发者针对性地快速优化模型。`,
                        image: require('assets/bml/characteristic/bml-characteristic3.3.png').default
                    }
                ]
            },
            {
                title: '在线服务',
                data: [
                    {
                        title: 'AB Test',
                        desc: `BML在线服务中，同一API服务支持同时部署两个模型上线，并且可以自由的调节流量分配占比，
                        便于用户在调试之后根据效果选择更合适的模型上线使用。`,
                        image: require('assets/bml/characteristic/bml-characteristic4.1.png').default
                    },
                    {
                        title: '休眠策略',
                        desc: 'BML平台的在线服务支持休眠策略，用户可以将闲时设置为休眠时间，从而减少不必要的支出。',
                        image: require('assets/bml/characteristic/bml-characteristic4.2.png').default
                    },
                    {
                        title: '批量预测',
                        desc: `针对大批量、低频次的推理预测任务场景，BML平台提供了批量预测功能，用户可以上传大批量数据进行推理，在数据处理完成后自动停止。
                        针对一些定时场景（如每天10点上传），也支持用户设置定时循环任务。`,
                        image: require('assets/bml/characteristic/bml-characteristic4.3.png').default
                    }
                ]
            },
            {
                title: '本地部署',
                data: [
                    {
                        title: '适配广',
                        desc: `BML本地部署服务覆盖图像分类、物体检测、文本分类等众多场景，覆盖x86系列CPU、
                        NV系列GPU，Jetson系列及ARM系列等20+种芯片，以及Linux、Windows、IOS等常见操作系统，便于用户应对更多的场景。`,
                        image: require('assets/bml/characteristic/bml-characteristic5.1.png').default
                    },
                    {
                        title: '一键模型优化',
                        desc: 'BML内置了模型裁剪及量化压缩算法，支持一键模型优化并发布为加速版SDK。加速版SDK普遍在包大小、内存占用、识别速度等方面表现更优。',
                        image: require('assets/bml/characteristic/bml-characteristic5.2.png').default
                    },
                    {
                        title: '端云协同',
                        desc: 'BML端云协同服务能够便捷地将BML定制模型的推理能力拓展至应用现场，提供临时离线、低延时的计算服务。',
                        image: require('assets/bml/characteristic/bml-characteristic5.3.png').default
                    }
                ]
            }
        ]
    },
    scene: {
        title: '成功案例，致力成为您值得信赖的合作伙伴',
        data: [
            {
                name: '智慧城市',
                title: '共享单车停放监控',
                image: require('assets/bml/scene01.jpg').default,
                desc:
                    '对共享单车乱停乱放现象进行监控，实现对共享单车倒地、线外停车、侵占盲道等违规现象的智能识别与报警，提高问题的发现与处理效率，协助城市形象的提升',
                imgs: [
                    {
                        name: '慧安信科',
                        src: require('assets/bml/logo-01.png').default
                    }
                ]
            },
            {
                name: '智慧农业',
                title: '智能植保',
                image: require('assets/bml/scene02.jpg').default,
                desc: '针对农业虫害场景，识别诱捕器中害虫具体种类，针对性杀虫，保障农作物健康生长',
                imgs: [
                    {
                        name: '依科曼',
                        src: require('assets/bml/logo-02.png').default
                    }
                ]
            },
            {
                name: '智慧能源',
                title: '输电线路安全巡检',
                image: require('assets/bml/scene03.jpg').default,
                desc:
                    '地域分布广、环境复杂多变等给输电线路的安全运行提出了严峻的挑战。通过对输电通道的可视化改造以及智能分析极大的提升了线路安全巡检的效率，为输电线路安全稳定运行提供了可靠保障',
                imgs: [
                    {
                        name: '国家电网',
                        src: require('assets/bml/logo-03.png').default
                    }
                ]
            },
            {
                name: '企业智能',
                title: '企业信息分类',
                image: require('assets/bml/scene04.jpg').default,
                desc:
                    '咨询公司使用文本分类将人才信息的行业和职级的分类模型进行训练，并能将积累十余年的百万条经营数据进行企业内部信息结构化分类，改变人工分类方式',
                imgs: [
                    {
                        name: '潮才咨询',
                        src: require('assets/bml/logo-04.png').default,
                        href: `${process.env.CUSTOMER}/hczx`
                    }
                ]
            }
        ]
    },
    videoTutorial: {
        title: '视频教程',
        data: [
            {
                name: '手把手POC，实现高精度AI业务模型',
                cover: require('assets/bml/video-a.png').default,
                url: 'https://bce-doc-on.bj.bcebos.com/bce-documentation/BML/BML_Model.mp4'
            },
            {
                name: '从模型到应用，打通AI落地最后一公里',
                cover: require('assets/bml/video-b.png').default,
                url: 'https://bce-doc-on.bj.bcebos.com/bce-documentation/BML/BML_Application.mp4'
            },
            {
                name: '工业检测多场景实战',
                cover: require('assets/bml/video-c.png').default,
                url: 'https://bce-doc-on.bj.bcebos.com/bce-documentation/BML/BML_Train.mp4'
            }
        ]
    },
    cardModelNlp: {
        title: '内置文心大模型基座',
        data: {
            img: require('assets/public/model-cardModelNlp.png').default,
            title: ['产业级知识增强大模型', '千行百业AI开发的首选基座大模型'],
            toLink: [
                {
                    name: '了解详情',
                    href: 'https://www.paddlepaddle.org.cn/wenxin',
                },
                {
                    name: '使用介绍',
                    href: 'https://ai.baidu.com/ai-doc/BML/hkww6ksyw',
                }
            ],
            tag: [
                {
                    icon: require('assets/public/cardModelNlp-skill.png').default,
                    name: '技术领先'
                },
                {
                    icon: require('assets/public/cardModelNlp-all.png').default,
                    name: '全场景覆盖'
                },
                {
                    icon: require('assets/public/cardModelNlp-sill.png').default,
                    name: '零门槛使用'
                },
                {
                    icon: require('assets/public/cardModelNlp-real.png').default,
                    name: '真实可落地'
                }
            ]

        }

    },
};
