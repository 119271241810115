import {useEffect, useState, useRef, useCallback} from 'preact/hooks';
import ReactDOM from 'react-dom';

/**
props: {
    children: JSX.Element;
    show: boolean;
    onClose?: () => void;
    maskClosable?: boolean;
    style?: React.CSSProperties;
    className?: string;
    isResetBody?: boolean;
}
 */
export function UModalMask(props) {
    const {isResetBody = true, className, style, onClose, maskClosable, show} = props;
    const [opacity, setOpacity] = useState('0');
    const [display, setDisplay] = useState('none');
    const rootNode = useRef(null);
    const isDestroy = useRef(false);
    const html = useRef(null);
    const body = useRef(null);
    const scrollTop = useRef(null);

    const resetBodyAttr = useCallback(() => {
        setOpacity('0');
        isDestroy.current = true;
        if (isResetBody && document.body.classList.contains('header-body-no-scroll')) {
            document.body.classList.remove('header-body-no-scroll');
            document.body.style.top = null;
            document.body.style.overflowY = null;
            window.scrollTo({top: scrollTop.current});
            // 重置scrollTop
            scrollTop.current = null;
        }
    }, [isResetBody]);

    useEffect(() => {
        html.current = document.querySelector('html');
        body.current = document.body;
        if (show) {
            setDisplay('block');
            setTimeout(() => {
                setOpacity('1');
            }, 100);
            isDestroy.current = false;
            // body 不可移动
            // 判断是否要重新计算scrollTop
            if (!scrollTop.current) {
                scrollTop.current = window.scrollY;
            }
            document.body.classList.add('header-body-no-scroll');
            document.body.style.top = `-${scrollTop.current || 0}px`;
            // 判断body本身是否可以滚动，如果可以滚动加上scroll，保证有滑动条
            if (document.body.scrollHeight > document.body.clientHeight) {
                document.body.style.overflowY = 'scroll';
            }
        } else {
            resetBodyAttr();
        }
        return () => {
            resetBodyAttr();
        };
    }, [show, resetBodyAttr]);

    const clickMaskHandler = () => {
        if (maskClosable) {
            resetBodyAttr();
        }
    };

    const onTransitionEndHandler = isDestroy => {
        if (isDestroy) {
            onClose && onClose();
            const parent = rootNode.current.parentElement;
            const parentTagName = parent?.tagName;
            const rootEle = parentTagName === 'DIV' ? parent : rootNode.current;
            ReactDOM.unmountComponentAtNode(rootEle);
            rootEle.remove();
        }
    };

    return (
        <div
            onTransitionEnd={() => onTransitionEndHandler(isDestroy.current)}
            className={`no_rem-u-modal-mask ${className || ''}`}
            style={{...style, opacity, display}}
            onClick={clickMaskHandler}
            ref={rootNode}
        >
            <div
                onClick={e => {
                    e.stopPropagation();
                }}
                className="u-modal-mask-content-container"
                style={{opacity}}
            >
                {
                    props.children
                }
            </div>
        </div>
    );
}
