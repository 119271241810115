/**
 * easydl/index 中用到组件
 */
import {AButton, AButtonGroup} from 'components/base/aButton';
import ALink from 'components/base/aLink';
import {useEffect, useState} from 'preact/hooks';
import {Swiper, SwiperSlide} from 'swiper/react';
import VideoPlayer, {useVideoPlayer} from 'components/videoPlayer';
import {innerHtml, useSwiperPaginationHoverChange} from 'utils';

export function Banner(props) {
    const {children, banner} = props;
    return (
        <div className="banner">
            <section className="section">
                <div className="banner-title">
                    <h1 className="banner-title-text">{banner.title}</h1>
                    <AButtonGroup align="center">
                        {banner.buttonGroup.map((item) => {
                            const {text, ...prop} = item;
                            return (
                                // eslint-disable-next-line react/jsx-key
                                <AButton {...prop} style={{width: '158px', letterSpacing: '4px', fontWeight: 500}}>
                                    {text}
                                </AButton>
                            );
                        })}
                    </AButtonGroup>
                </div>
                <div className="banner-main">
                    <BannerLeftNav {...banner.left} />
                    <div className="banner-main-swiper">
                        <div>
                            {children || <BannerSwiper slides={props.slides} />}
                        </div>
                        <div className="banner-main-swiper_bottom">
                            <div className="banner-main-swiper_bottom_container">
                                {banner.right.realTimeInfo.map((item, index) => {
                                    return (
                                        // eslint-disable-next-line react/jsx-key
                                        <ALink href={item.href} trackTag={`Banner_固定${index + 1}号位`}>
                                            {item.text}
                                        </ALink>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

function BannerLeftNav(props) {
    const {title, data = [], tag} = props;
    return (
        <div className="banner-main-nav">
            {tag && <div className="banner-main-nav_tag">{tag}</div>}
            {innerHtml(<div className="banner-main-nav_title" innerHtml={title} />)}
            <ul>
                {data.map(({text, href, video, icon}) => {
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <li className={video ? '' : 'max-content'}>
                            {video ? (
                                <BannerLeftNavVideo text={text} video={video} icon={icon} />
                            ) : (
                                <BannerLeftNavLink text={text} href={href} icon={icon} />
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
function BannerLeftNavLink(props) {
    const {href, text, icon} = props;
    return (
        <div className="banner-main-nav_item">
            <ALink trackTag={text} href={href}>
                {text}
            </ALink>
            <i className="tzfont tzfont-arrow-right-2 icon" />
            {icon && (
                <div className="img-icon-wrap">
                    <img src={icon} className="img-icon1" />
                </div>
            )}
        </div>
    );
}
function BannerLeftNavVideo(props) {
    const {video, text, icon} = props;
    const {visible, open, close} = useVideoPlayer();
    return (
        <>
            <VideoPlayer src={video} visible={visible} onClose={close} />
            <div className="banner-main-nav_item" onClick={open}>
                <ALink trackTag={text}>{text}</ALink>

                {icon ? (
                    <div className="img-icon-wrap">
                        <img src={icon} className="img-icon" />
                    </div>
                ) : (
                    <i className="tzfont tzfont-arrow-right-2 icon" />
                )}
            </div>
        </>
    );
}

const swiperConfig = {
    slidesPerView: 1,
    loop: true,
    effect: 'fade',
    autoplay: {
        delay: 5000,
        disableOnInteraction: false
    },
    pagination: {
        el: '.lite-swiper-pagination',
        clickable: true,
        type: 'bullets',
        bulletClass: 'lite-pagination-bullet',
        bulletActiveClass: 'lite-pagination-bullet-active'
    }
};

export function BannerSwiper(props) {
    useSwiperPaginationHoverChange('.lite-swiper-pagination');
    const {slides} = props;
    const [isText, setIsText] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = slides[0].bg;
        const hideAll = () => {
            setIsText(true);
        };
        img.addEventListener('load', hideAll);
        return () => {
            img.removeEventListener('load', hideAll);
        };
    }, [slides]);
    if (typeof window === 'undefined') {
        return;
    }
    return (
        <div>
            <Swiper {...swiperConfig}>
                {slides.map(({name, title, subTitle, bg, buttonGroup, align}, index) => {
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <SwiperSlide key={name}>
                            <ALink
                                href={buttonGroup.length === 1 && buttonGroup[0].href}
                                className={`slide slide-${align} slide-${name}`}
                                style={{backgroundImage: `url(${bg})`}}
                                trackTag={`Banner_轮播${index + 1}号位`}
                            >
                                <div className="slide-container">
                                    {isText && innerHtml(<div className="slide-title" innerHtml={title} />)}
                                    {isText && innerHtml(<p className="slide-desc" innerHtml={subTitle} />)}
                                    {buttonGroup[0].name && isText && (
                                        <AButtonGroup align={align}>
                                            {buttonGroup.map(({name, ...prop}) => (
                                                // eslint-disable-next-line react/jsx-key
                                                <AButton {...prop}>{name}</AButton>
                                            ))}
                                        </AButtonGroup>
                                    )}
                                </div>
                            </ALink>
                        </SwiperSlide>
                    );
                })}
                <div className="lite-swiper-pagination" />
            </Swiper>
        </div>
    );
}

export function ModelType(props) {
    const {data} = props;
    return (
        <div className="modeltype lite">
            {data.map(({icon, title, type, desc, href, tag}) => {
                return (
                    // eslint-disable-next-line react/jsx-key
                    <ALink href={href} className="modeltype-item" trackTag={`技术方向_${title}`}>
                        <div className="modeltype-icon">
                            <img src={icon} alt={title} />
                        </div>
                        <div className="modeltype-title">
                            {title}
                            {tag ? <span>{tag}</span> : ''}
                            <i className="tzfont tzfont-arrow-right-2" />
                        </div>
                        <div className="modeltype-type">
                            {type.split(' ').map((item) => (
                                // eslint-disable-next-line react/jsx-key
                                <span>{item}</span>
                            ))}
                        </div>
                        <div className="modeltype-desc">{desc}</div>
                    </ALink>
                );
            })}
        </div>
    );
}

export function Scene(props) {
    const {data} = props;
    return (
        <div className="scene lite">
            {data.map(({image, title, desc, category}) => {
                const href = category ? `https://console.bce.baidu.com/easydl/scene/details?category=${category}` : 'javascript:void(0);';
                return (
                    // eslint-disable-next-line react/jsx-key
                    <ALink href={href} className={`scene-item ${href ? 'scene-hover' : ''}`} trackTag={`场景_${title}`}>
                        <div className="scene-img_wrap">
                            <img src={image} alt={title} className="scene-img" />
                        </div>
                        <div className="scene-title">{title}</div>
                        <p className="scene-desc">{desc}</p>
                    </ALink>
                );
            })}
        </div>
    );
}
