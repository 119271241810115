import classnames from 'classnames';
import {Tab, TabGroup} from 'components/base/tab';
import {useState} from 'preact/hooks';
import {genClassName} from 'utils';
import {chunk} from 'utils/collection';
import css from './specialOffer.less';

const cls = genClassName(css);

const template = (s, injects) => {
    return Object.keys(injects).reduce((memo, k) => {
        return memo.replace(`{{${k}}}`, injects[k] || '');
    }, s);
};

const isFree = (n) => !n || Number.parseFloat(n) === 0;

const Price = (props) => {
    const {price, unit, formatter} = props;
    const unitText = template(formatter || '', {unit, price: ''});

    return (
        <p className={cls('discount')}>
            <span className={cls('big')}>￥{price}</span>
            {!isFree(price) && <span className={cls('sm')}>{unitText}</span>}
        </p>
    );
};

const PrevPrice = (props) => {
    const {price, unit, formatter} = props;
    const priceWord = template(formatter || '{{price}}', {unit, price: `￥${price}`});

    return isFree(price) ? undefined : <p className={cls('prev-price')}>原价：{priceWord}</p>;
};

const Tag = (props) => {
    return (
        <span className={classnames(cls('tag'), {[cls('tag-highlighted')]: props.highlighted})}>{props.children}</span>
    );
};

const SepcialOfferCard = (props) => {
    const {name, description, tag, keywords, before, after, formatter} = props.info;
    const [afterPrice, afterUnit] = after.split('/');
    const [beforePrice, beforeUnit] = before.split('/');
    const [hovering, setHovering] = useState(false);

    return (
        <div
            className={classnames(cls('special-offer-card'), {[cls('active')]: hovering})}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
        >
            <div className={cls('name')}>{name}</div>
            <p className={cls('desc')}>{description}</p>

            <div className={classnames(cls('keywords'), {[cls('narrow')]: keywords.length >= 3})}>
                <Tag highlighted>{tag}</Tag>
                {keywords.map((k) => (
                    <Tag key={k}>{k}</Tag>
                ))}
            </div>

            <Price price={afterPrice} unit={afterUnit || beforeUnit} formatter={formatter} />
            <PrevPrice
                price={beforePrice}
                unit={afterUnit || beforeUnit}
                formatter={afterPrice !== '0' ? '' : formatter}
            />

            <a className={cls('foot-button')} href={props.info.link}>
                <button>{props.info.button || '立即抢购'}</button>
            </a>
        </div>
    );
};

export const SpecialOfferContent = (props) => {
    const {items, expanded, onExpanded} = props;
    const toggleExpanded = () => {
        onExpanded(!expanded);
    };

    const chunkedItems = chunk(items, 4);
    const toggler =
        chunkedItems.length > 1 ? (
            <div className={cls('toggler')}>
                <a onClick={toggleExpanded}>
                    {expanded ? '收起全部' : '展开全部'}
                    <i
                        className={classnames('tzfont tzfont-down-arrow', cls('icon'), {
                            [cls('expanded')]: expanded
                        })}
                    />
                </a>
            </div>
        ) : undefined;

    const visibleChunkedItems = expanded ? chunkedItems : chunkedItems.slice(0, 1);

    return (
        <div>
            <div className={classnames(cls('card-wrapper'))}>
                {visibleChunkedItems.map((c) => (
                    <div key={c} className={cls('row')}>
                        {c.map((it) => (
                            <SepcialOfferCard key={it} info={it} />
                        ))}
                    </div>
                ))}
            </div>

            {toggler}
        </div>
    );
};

export const SpecialOffer = (props) => {
    const [expanded, setExpanded] = useState(false);

    const tabs = props.offers.map((group) => (
        <Tab tab={group.category} key={group.category}>
            <SpecialOfferContent items={group.items} expanded={expanded} onExpanded={setExpanded} />
        </Tab>
    ));

    return (
        <div className={cls('special-offer')}>
            <TabGroup>{tabs}</TabGroup>
        </div>
    );
};
