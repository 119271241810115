export default function Support(props) {
    const {support, className} = props;
    return (
        <div className="support" id="support">
            <h2>{support.name}</h2>
            <div className="support-main">
                {support.arr.map((item, idx) => {
                    return (
                        <div className={className} key={item.name}>
                            <p className="support-item-title">{item.title}</p>
                            <p className="support-item-content no_rem_fs_16">{item.content}</p>
                            <div className="support-box">
                                {item.links.map((link, cidx) => {
                                    return (
                                        <a
                                            className="support-link"
                                            data-track-category={`千帆产品页PC-${support.name}`}
                                            data-track-name={item.title}
                                            data-track-value={link.text}
                                            href={link.url}
                                            key={link}
                                        >
                                            <span className="link">{link.text}</span>
                                            <span className="link-play"></span>
                                        </a>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
