import {createContext} from 'preact';
import {useContext, useEffect, useState} from 'preact/hooks';

const GlobalTooltipContext = createContext<HTMLDivElement>(null);

export const useGlobalTooltipContext = () => useContext(GlobalTooltipContext);

export const GlobalPopperProvider = ({children}) => {
    const [container, setContainer] = useState(null);

    useEffect(() => {
        const container = document.createElement('div');
        container.id = 'global-tooltip-container';
        document.body.appendChild(container);
        setContainer(container);

        return () => {
            if (container) {
                document.body.removeChild(container);
            }
        };
    }, []);

    return <GlobalTooltipContext.Provider value={container}>{children}</GlobalTooltipContext.Provider>;
};
