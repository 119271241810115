import {useEffect, useState} from 'preact/hooks';
import style from './index.less';
import {queryString, $useTrack} from 'utils';
const BMLKEY = 'BML_WELCOME';

export const ProUserWelcome = () => {
    const [isShow, setIsShow] = useState(() => {
        if (typeof window !== 'undefined') {
            try {
                if (!!window.localStorage.getItem(BMLKEY)) {
                    return false;
                }
                return new URL(window.location.href).searchParams.get('referrer') === 'bml';
            } catch (error) {
                return false;
            }
        }
        return false;
    });
    const close = () => {
        if (typeof window !== 'undefined') {
            window.localStorage.setItem(BMLKEY, 'true');
        }
        setIsShow(false);
    };

    return (
        <div className={style.welcome} style={{display: isShow ? 'block' : 'none'}}>
            <div className={style.mask} />
            <div className={style.modal}>
                <div className={style.close} style={{cursor: 'pointer'}} onClick={close}>
                    <embed style={{pointerEvents: 'none'}} src={require('assets/welcome/close.svg').default} />
                </div>
                <div className={style.title}>EasyDL专业版已升级为BML 全功能AI开发平台</div>
                <div className={style.content}>
                    <p>我们将在全新功能架构之上为您提供更加丰富的AI开发能力，</p>
                    <p>
                        温馨提示：您的数据已经自动完成了迁移（
                        <a href="//ai.baidu.com/ai-doc/BML/Fkhzqid71" target="_blank" rel="noopener noreferrer">
                            了解迁移规则
                        </a>
                        ），欢迎您继续使用BML进行AI应用开发。
                    </p>
                </div>
                <div className={style.seen}>
                    <a href="//ai.baidu.com/bml/app/overview" target="_blank" rel="noopener noreferrer">
                        <div className={style.seen_mask} />
                        <span>立即体验BML</span>
                    </a>
                </div>
                <div
                    className={style.footer}
                    style={{backgroundImage: `url(${require('assets/welcome/pro.png').default})`}}
                />
            </div>
        </div>
    );
};

const GRAVITY_KEY = 'GRAVITY2022_WELCOME';

export const GravityWelcome = () => {
    const {track, ACTIONS} = $useTrack('万有引力2022');
    const [isShow, setIsShow] = useState(() => {
        if (typeof window !== 'undefined') {
            const {from} = queryString();
            if (from && from === 'gravity') {
                return false;
            }
            try {
                return !window.localStorage.getItem(GRAVITY_KEY);
            } catch (error) {
                return false;
            }
        }
        return false;
    });
    const close = () => {
        if (typeof window !== 'undefined') {
            window.localStorage.setItem(GRAVITY_KEY, 'true');
        }
        setIsShow(false);
    };
    useEffect(() => track(ACTIONS.VISIT, '活动弹窗展现'), []);
    return isShow ? (
        <div className={style.welcome} style={{display: 'block'}}>
            <div className={style.mask} />
            <div className={style.gravity_modal}>
                <img src={require('assets/gravity/guide.png').default} />
                <a
                    href="/easydl/universal-gravitation"
                    target="_blank" rel="noopener noreferrer"
                    // eslint-disable-next-line
                    onClick={() => track(ACTIONS.CLICK, '活动弹窗点击')}
                >
                    <img className={style.gravity_button} src={require('assets/gravity/guide-button.png').default} />
                </a>
                <img
                    className={style.gravity_close}
                    onClick={close}
                    src={require('assets/gravity/guide-close.png').default}
                />
            </div>
        </div>
    ) : null;
};
