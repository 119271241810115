import SwiperCore, {Autoplay, EffectFade, Pagination, Navigation} from 'swiper/core';
import {Router} from 'preact-router';

import EasyDL from 'routes/easydl/getHFIndex';
import BML from 'routes/bml/getHFIndex';
import OCR from 'routes/easydl/getHFOcrIndex';
import Wenxin from 'routes/wenxin/getHFIndex';

// import useGenerateLayout from 'utils/useGenerateLayout';
import GetHeaderFooterLayout from 'layouts/getHF';
import {GlobalPopperProvider} from '~/components/Tooltip/GlobalTooltipContext.tsx';

import 'style/common.less';
import 'style/easydl/product.less';

SwiperCore.use([Autoplay, Pagination, EffectFade, Navigation]);

export default function App() {
    // useGenerateLayout(true);

    return (
        <div id="app">
            <GetHeaderFooterLayout>
                <GlobalPopperProvider>
                    <Router>
                        <EasyDL path="/product/easydl" />
                        <OCR path="/product/easydl/ocr" />
                        <BML path="/product/bml" />
                        <Wenxin path={['/product/qianfan', '/product/wenxinworkshop', '/']} />
                    </Router>
                </GlobalPopperProvider>
            </GetHeaderFooterLayout>
        </div>
    );
}
