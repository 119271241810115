/**
 * @file util
 * @author shenqiqi@baidu.com
 */

// import {Abtest} from '@baidu/bce-abtest';
import {isSandBoxEnv} from './getEnv';
// import {getAcsToken} from '../track/slideVerify';

export const escapeHtml = (text) => {
    return text
        .replace(/&/g, '&amp;')
        .replace(/>/g, '&gt;')
        .replace(/</g, '&lt;')
        .replace(/"/g, '&quot;')
        .replace(/“/g, '&ldquo;')
        .replace(/”/g, '&rdquo;')
        .replace(/ /g, '&nbsp;')
        .replace(/×/g, '&times;');
};

export const setCookie = (name, value, host, days) => {
    let time = '';
    if (days) {
        let d = new Date();
        const offset = 8;
        const utc = d.getTime() + d.getTimezoneOffset() * 60000;
        const nd = utc + 3600000 * offset;
        const exp = new Date(nd);
        exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000);
        time = `expires=${exp.toGMTString()};`;
    }

    document.cookie = `${name}=${value};path=/;${time}domain=.${host};`;
};

export const getCookie = (name) => {
    const arr = document.cookie.match(new RegExp('(^| )' + name + '=([^;]*)(;|$)'));
    if (arr != null) {
        return decodeURI(arr[2]);
    }
    return null;
};

export const delCookie = (name, host) => {
    var cval = getCookie(name);
    if (cval != null) {
        document.cookie = `${name}=${cval};domain=.${host};expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
    }
};

export const getParamValue = (url, key) => {
    const regex = new RegExp(key + '=([^&]*)', 'i');
    return url.match(regex) ? url.match(regex)[1] : null;
};

function formatParams(data) {
    let arr = [];
    for (let name in data) {
        arr.push(encodeURIComponent(name) + '=' + encodeURIComponent(data[name]));
    }
    arr.push(('t=' + Math.random()).replace('.', ''));
    return arr.join('&');
}

function setHeaders(xhr, headers) {
    for (const [header, value] of Object.entries(headers)) {
        xhr.setRequestHeader(header, value);
    }
}

export const ajax = (options) => {
    options = options || {};
    options.type = (options.type || 'GET').toUpperCase();
    options.dataType = options.dataType || 'json';
    options.headers = options.headers || {};

    let params = options.data ? formatParams(options.data) : '';

    if (options.contentType && options.contentType === 'application/json') {
        params = JSON.stringify(options.data);
    }

    const getSendData = params ? '?' + params : '';

    if (options.dataType === 'jsonp') {
        let callbackName = options.success;
        let elem = document.createElement('script');
        elem.src = options.url + '?callback=' + callbackName;
        document.head.appendChild(elem);
    } else {
        let xhr = null;
        // 创建 - 非IE6 - 第一步
        if (window.XMLHttpRequest) {
            xhr = new XMLHttpRequest();
        } else if (window.ActiveXObject) {
            xhr = new ActiveXObject('Microsoft.XMLHTTP');
        } else {
            xhr = null;
            return;
        }

        // 是否携带cookie
        if (options.withCredentials) {
            xhr.withCredentials = true;
        }

        // 接收 - 第三步
        xhr.onreadystatechange = function () {
            if (Number(xhr.readyState) === 4) {
                let status = xhr.status;
                if (status >= 200 && status < 300) {
                    options.success && options.success(xhr.responseText, xhr.responseXML);
                } else {
                    options.fail && options.fail(status);
                }
            }
        };

        // 连接 和 发送 - 第二步
        if (options.type === 'GET') {
            xhr.open('GET', options.url + getSendData, true);
            setHeaders(xhr, options.headers);
            xhr.send(null);
        } else if (options.type === 'POST') {
            xhr.open('POST', options.url, true);
            setHeaders(xhr, options.headers);
            // 设置表单提交时的内容类型
            xhr.setRequestHeader('Content-Type', options.contentType || 'application/x-www-form-urlencoded');
            xhr.send(params);
        }
    }
};

// ele: 元素; ev: 事件类型; callback: 回调函数
export const bind = (ele, ev, callback) => {
    if (document.all) {
        ele.attachEvent('on' + ev, callback);
    } else {
        ele.addEventListener(ev, callback, false);
    }
};

export const unbind = (ele, ev, callback) => {
    if (document.all) {
        ele.detachEvent('on' + ev, callback);
    } else {
        ele.removeEventListener(ev, callback, false);
    }
};

export const siblings = (ele) => {
    let childArrTem = [];
    let childArr = ele.parentNode.children;
    for (let i = 0, len = childArr.length; i < len; i++) {
        if (childArr[i] !== ele && childArr[i].nodeType === 1) {
            childArrTem.push(childArr[i]);
        }
    }
    return childArrTem;
};

export const addEventListener = (ele, ev, callback) => {
    if (typeof ele.attachEvent === 'function') {
        ele.attachEvent('on' + ev, callback);
    } else {
        ele.addEventListener(ev, callback, false);
    }
};

export const getValueByPath = (obj, path) => {
    let result;
    let pathArr = path.split('.');

    if (pathArr.length > 0) {
        let [curKey, ...restPath] = pathArr;

        let curValue = obj && obj[curKey];

        if (curValue) {
            if (restPath && restPath.length > 0) {
                result = getValueByPath(curValue, restPath.join('.'));
            } else {
                result = curValue;
            }
        }

        return result;
    }
};

// export const isEmptyObject = (obj) => {
//     for (let key in obj) {
//         return !obj.hasOwnProperty(key);
//     }
//     return true;
// };

export const isMobile = () => {
    const mobiles = [
        'midp',
        'j2me',
        'avant',
        'docomo',
        'novarra',
        'palmos',
        'palmsource',
        '240x320',
        'opwv',
        'chtml',
        'pda',
        'windows ce',
        'mmp/',
        'blackberry',
        'mib/',
        'symbian',
        'wireless',
        'nokia',
        'hand',
        'mobi',
        'phone',
        'cdm',
        'up.b',
        'audio',
        'sie-',
        'sec-',
        'samsung',
        'htc',
        'mot-',
        'mitsu',
        'sagem',
        'sony',
        'alcatel',
        'lg',
        'eric',
        'vx',
        'NEC',
        'philips',
        'mmm',
        'xx',
        'panasonic',
        'sharp',
        'wap',
        'sch',
        'rover',
        'pocket',
        'benq',
        'java',
        'pt',
        'pg',
        'vox',
        'amoi',
        'bird',
        'compal',
        'kg',
        'voda',
        'sany',
        'kdd',
        'dbt',
        'sendo',
        'sgh',
        'gradi',
        'jb',
        'dddi',
        'moto',
        'iphone',
        'android',
        'ipod',
        'incognito',
        'webmate',
        'dream',
        'cupcake',
        'webos',
        's8000',
        'bada',
        'googlebot-mobile',
        'bai du yun',
        'vivo'
    ];

    const ua = navigator.userAgent.toLowerCase();
    let isMobile = false;
    for (let i = 0; i < mobiles.length; i++) {
        if (ua.indexOf(mobiles[i]) > 0) {
            isMobile = true;
            break;
        }
    }
    return isMobile;
};

export const unique = (arr) => {
    for (let i = 0; i < arr.length; i++) {
        for (let j = i + 1; j < arr.length; j++) {
            if (arr[i].name?.trim() === arr[j].name?.trim()) {
                arr.splice(j, 1);
                j--;
            }
        }
    }
    return arr;
};

export const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        const context = this;
        const args = arguments;
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
        timer = setTimeout(() => {
            fn.apply(context, args);
        }, delay);
    };
};

export const throttle = (fn, interval) => {
    let start = 0;

    return function (evt) {
        const now = Date.now();
        if (now - start >= interval) {
            fn.call(this, evt);
            start = now;
        }
    };
};

// 删除节点
export function deleteNode(node) {
    node.parentNode.removeChild(node);
}

export function delOldEle(selector) {
    const oldEle = document.querySelector(selector);
    if (oldEle) {
        deleteNode(oldEle);
    }
}

export function addNewEle(html, isAppend = true) {
    const bodyEle = document.querySelector('body');
    let newEle = document.createElement('div');
    // bca-disable-line
    newEle.innerHTML = html;
    newEle = newEle.firstChild;
    if (isAppend) {
        bodyEle.appendChild(newEle);
    } else {
        bodyEle.insertBefore(newEle, bodyEle.children[0]);
    }
    return newEle;
}

export function fetchHotData() {
    let url = 'https://bce.bdstatic.com/portal-search/online.js';

    // 判断是否是在沙盒环境
    if (isSandBoxEnv()) {
        url = 'https://bce.bdstatic.com/portal-search/qa.js';
    }
    return new Promise((resolve) => {
        ajax({
            url: url,
            type: 'GET',
            dataType: 'json',
            success: (e) => {
                resolve(JSON.parse(e));
            }
        });
    });
}

export const sendMonitor = (params) => {
    if (window.require) {
        // 使用 window.require 避免 webpack 将其作为 cjs 来解析
        window.require(['monitor'], (Monitor) => {
            Monitor.default.trackBehavior(params);
        });
    } else if (window.Monitor) {
        window.Monitor.default.trackBehavior(params);
    } else {
        // 给点延迟，因为首屏加载出来的时候，fe-monitor还没加载
        setTimeout(() => {
            window.Monitor && window.Monitor.default.trackBehavior(params);
        }, 500);
    }
};

export const getScrollTop = () => document.documentElement.scrollTop || document.body.scrollTop;

export const setScrollTop = (top) => {
    document.documentElement.scrollTop = top;
    document.body.scrollTop = top;
};

