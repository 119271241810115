import lax from 'lax.js';
import { useEffect } from 'preact/hooks';
import { MODEL_FEATURE } from '~/locals/wenxin';
import gapImg from 'assets/wenxin/model/gap-icon.png';

export default function ModelFeatures() {

    useEffect(() => {
        const trggleEle = document.querySelector('.model-features-container');
        let { top: trggleEleTop } = trggleEle.getBoundingClientRect();

        const trggleDomDistance2DocTop = Math.floor(window.scrollY + trggleEleTop);

        // 滚动条的距离 + trggleEleTop = dom距离文档顶部的距离
        // 触发元素出来占整个屏幕的20%开始触发
        const start_height = trggleDomDistance2DocTop - window.innerHeight * 0.8;
        // 到划出来%80结束
        const end_height = trggleDomDistance2DocTop - window.innerHeight * 0.2;

        lax.init();

        lax.addDriver('scrollY', function () {
            return window.scrollY;
        });


        lax.addElements('.product-features',
            {
                scrollY: {
                    opacity: [
                        [start_height, end_height],
                        [1, 0.6]
                    ],
                    scale: [
                        [start_height, end_height],
                        [1, 0.82]
                    ],
                    // 'border-radius': [
                    //     [start_height, start_height + 6],
                    //     [16, 16]
                    // ]
                },
            });

        lax.addElements('#bg-op',
            {
                scrollY: {
                    'background-color': [
                        [start_height, start_height + 6],
                        [1, 0],
                        {
                            cssFn: function (val) {
                                return `rgba(255, 255, 255, ${val})`;
                            }
                        }
                    ]
                }
            },
            {
                style: {
                    transition: 'all .3s linear'
                }
            });

        return () => {
            lax.removeElement();
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <section className="model-features-container" id='fucFeatures'>
            <div className="model-features-title">{MODEL_FEATURE.title}</div>
            <div className="model-features-types">
                {MODEL_FEATURE.types.map((type) => {
                    return (
                        <div className="types-container" key={type.title}>
                            <div className="model-features-card reasoning">
                                <div className="card-title">
                                    <span className="gradual">{type.title}</span>
                                </div>
                                <div className="card-list">
                                    {type.list.map((card, idx) => (
                                        <div key={card.key}>
                                            <div className="card-item" key={card.key}>
                                                <div className="card-icon">
                                                    <img src={card.img} />
                                                </div>
                                                <span className='no_rem_fs_16'>{card.label}</span>
                                            </div>
                                            {
                                                idx !== 3 && (
                                                    <div className='card-icon2'>
                                                        <img src={gapImg} />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="btn-container">
                                {type.btns.map((btn) => (
                                    <div className="btn" key={btn.key}>
                                        <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href={btn.href}
                                            className='content'
                                            data-track-category={`"千帆产品页PC-${MODEL_FEATURE.title}"`}
                                            data-track-name={type.title}
                                            data-track-value={btn.label}
                                        >
                                            {btn.label}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                })}
            </div>
        </section>
    );
}
