import classnames from 'classnames';
import {AButtonGroup, AButton} from 'components/base/aButton';
import {ProUserWelcome} from 'components/welcome';
import {PreLink} from 'components/base/aLink';
import VideoTutorial from 'components/sections/videoTutorial';
import SpecialOffer from 'components/sections/specialOffer';
import Affix from 'components/affix';
import Section, {
    TrackType,
    WithComponent,
    CardIntroApply,
    CardIntro,
    TabCard,
    BMLStartUse,
    IconHoverCards,
    TabCardFeature,
    CardModelNlp
} from 'components/sections';
import bannerCss from 'components/banner/index.less';
import {CardAD} from 'components/bml/CardAD';
import AD from 'components/bml/AD';
import localData from 'locals/bml';
import Features from 'components/bml/Features';
import 'style/bml.less';
import {BannerSwiper} from 'components/bml/BannerSwiper';
import useGenerateLayout from 'utils/useGenerateLayout';
import {jumpToURL} from 'utils';

const BmlSection = WithComponent(Section)({
    sectionWidth: 'bml'
});

const Link = PreLink({
    target: '_self'
});

export default function Bml() {
    useGenerateLayout(true);
    const {
        config,
        banner,
        productOverview,
        characteristic,
        productAdvantages,
        deliver,
        startUse,
        scene,
        videoTutorial,
        specialOffer,
        cardModelNlp
    } = localData;
    const {name, bmlBanner, href, bg, cardAD, tag, title, subTitle, buttonGroup, align = 'left'} = banner;
    return (
        <TrackType.Provider value={config}>
            <ProUserWelcome />
            <div className={classnames(bannerCss.banner, bannerCss[`banner_${name}`])}>
                <div className={'banner-bg'}>
                    {bmlBanner.length ? (
                        <BannerSwiper bmlBanner={bmlBanner} />
                    ) : (
                        <div
                            className={'banner-bg'}
                            style={bmlBanner.length ? '' : {backgroundImage: `url(${bg})`}}
                            onClick={() => {
                                jumpToURL(href);
                            }}
                        ></div>
                    )}
                </div>
            </div>
            <div className={'bml-cardad'}>
                <CardAD cardAD={cardAD} buttonGroup={buttonGroup} />
            </div>
            <BmlSection title={specialOffer.title} style={{paddingTop: '286px'}}>
                <SpecialOffer offers={specialOffer.data} />
            </BmlSection>

            <BmlSection bg="ghostWhite" title={productOverview.title} style={{paddingTop: '170px'}}>
                <img className="pof-image" src={productOverview.image} alt={productOverview.title} />
            </BmlSection>
            <Section title={cardModelNlp.title}>
                <CardModelNlp data={cardModelNlp.data} />
            </Section>
            <Features />
            <BmlSection title={characteristic.title}>
                <TabCardFeature {...characteristic} />
            </BmlSection>
            <BmlSection title={scene.title}>
                <TabCard {...scene} />
            </BmlSection>
            <BmlSection title={deliver.title}>
                <CardIntroApply {...deliver} />
            </BmlSection>
            <BmlSection title={videoTutorial.title} id="video">
                <VideoTutorial videos={videoTutorial.data} />
            </BmlSection>
            <div className="bml-shading">
                <BMLStartUse
                    titleClassName={startUse.titleClassName}
                    title={startUse.title}
                    desc={startUse.desc}
                    buttonGroup={startUse.buttonGroup}
                    products={startUse.products}
                />
            </div>
            <Affix target={'bml'} />
        </TrackType.Provider>
    );
}
