/**
 * @file 登录modal
 * @author luhuan
 */

import {useOnMount, useStateRef} from '@baidu/bce-hooks';

import {UModalMask} from './UModalMask';

/**
props: {
    maskClosable?: boolean;
    hideCloseIcon?: boolean;
    needQualify?: boolean; // 是否需要实名认证
    onOk?: (info: {activateSuccess?: boolean, qualifySuccess?: boolean}) => void;
    onCancel?: () => void;
}
 */

export const ULoginModal = (props) => {
    const [show, setShow] = useStateRef(true);
    const cancelHandler = () => {
        setShow(false);
        props.onCancel && props.onCancel();
    };

    const close = () => {
        setShow(false);
    };

    useOnMount(() => {
        let regSuccess = false;
        const handleMessage = (e) => {
            if (e.data?.loginSuccess) {
                close();
                props.onOk && props.onOk({regSuccess});
            }
            if (e.data?.activateSuccess) {
                close();
                props.onOk && props.onOk({activateSuccess: true});
            }
            if (e.data?.qualifySuccess) {
                close();
                props.onOk && props.onOk({qualifySuccess: true});
            }
            if (e.data?.regSuccess) {
                regSuccess = true;
            }
        };
        window.addEventListener('message', handleMessage, false);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    });

    return (
        <UModalMask show={show} maskClosable={props.maskClosable} onClose={cancelHandler}>
            <>
                <iframe className="portal-login-iframe" width="700" height="560" src={props.href} />
                {!props.hideCloseIcon && (
                    <div className="portal-login-iframe-close-icon" onClick={() => setShow(false)} />
                )}
            </>
        </UModalMask>
    );
};
