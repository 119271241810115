import { useEffect, useState } from 'preact/hooks';
import { productFeature } from '~/locals/wenxin/index';

const Index = () => {
    const { title } = productFeature;
    return (
        <div className="product-container" id='productFeatures'>
            <div className="product-features">
                <h3 className="title">{title}</h3>
                <div className="content"></div>
            </div>
            <div id="bg-op"></div>
        </div>

    );
};

export default Index;
