import {useState, useEffect} from 'preact/hooks';
import Modal from '../base/modal';
import {consoleModaltitle, parentTypes, consoleType, consoleLegend} from 'locals/consoleModal';
import useModal from 'utils/useModal';

import style from './index.less';

function getChunks(types) {
    const kvs = types.reduce((memo, one) => {
        const legend = consoleLegend.find((l) => l.code === one.type);

        if (!memo[one.type]) {
            memo[one.type] = {
                title: legend.name,
                color: legend.value,
                suffix: legend.suffix,
                items: [one]
            };
            return memo;
        }
        memo[one.type].items.push(one);
        return memo;
    }, {});
    const splitIntoCols = (g) => {
        g.items = g.items.length > 4 ? [g.items.slice(0, 4), g.items.slice(4)] : [g.items];
        return g;
    };

    let colCounter = 0;
    // 防止一列过多，分 chunk
    return Object.values(kvs).reduce((memo, g) => {
        const normalizedGroup = splitIntoCols(g);
        const latest = memo[memo.length - 1];

        if (!latest || colCounter >= 4) {
            const row = [normalizedGroup];
            memo.push(row);
            colCounter = normalizedGroup.items.length;
            return memo;
        }

        latest.push(normalizedGroup);
        colCounter += normalizedGroup.items.length;
        return memo;
    }, []);
}

function ConsoleModalGroup(props) {
    const {title, color, items, suffix, lite} = props;
    const chunks = items;
    return (
        <div className={style.group}>
            <div className={style.group_head}>
                {!lite && (
                    <div className={style.group_title}>
                        <i className={style.circle} style={{backgroundColor: `#${color}`}} />
                        {title}
                        {suffix && <span className={style[suffix.class]}>{suffix.name}</span>}
                    </div>
                )}
            </div>

            <div className={style.group_body}>
                {chunks.map((chunk) => (
                    <div key={chunk} className={style['group_col']}>
                        {chunk.map(({name, icon, href, isNew}) => (
                            <a key={name} href={href || 'javascript:void(0);'} className={style['group-item']} native>
                                <img className={style['group-item_icon']} src={icon} alt="" />
                                <span className={style[('group-item_name', isNew ? 'newtag' : '')]}>{name}</span>
                            </a>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default function ConsoleModal(props) {
    const {visible, onClose} = props;
    const [showConsoleType, setShowConsoleType] = useState([...consoleType].filter((it) => !it.local));
    const [showLocalType, setLocalType] = useState([...consoleType].filter((it) => it.local));
    useEffect(() => {
        const pathName = window.location.pathname;
        let pType = '';
        for (let i = 0; i < parentTypes.length; i += 1) {
            if (new RegExp(`/${parentTypes[i]}(/?)`).test(pathName)) {
                pType = parentTypes[i];
                break;
            }
        }

        if (pType) {
            setShowConsoleType(consoleType.filter((ct) => ct.type === pType && !ct.local));
            setLocalType(consoleType.filter((ct) => ct.type === pType && ct.local));
        }
    }, []);
    // 直接导航
    const redirect = showConsoleType.length === 1;
    const cloudChunks = getChunks(showConsoleType);
    const localChunks = getChunks(showLocalType);
    const hasLocal = Boolean(showLocalType.length);
    const modal = () => (
        <Modal visible onClose={onClose} title={consoleModaltitle} className={style.modal}>
            <div className={style['consoleModal']}>
                <div className={`${style.section} ${style.left}`}>
                    {hasLocal && <h3 className={style.title}>在线使用</h3>}

                    <div className={style['group-wrapper']}>
                        {cloudChunks.map((chunk) => (
                            <div className={style['group-row']} key={chunk}>
                                {chunk.map((g) => (
                                    <ConsoleModalGroup
                                        key={g.title}
                                        title={g.title}
                                        color={g.color}
                                        suffix={g.suffix}
                                        items={g.items}
                                        lite={!hasLocal}
                                    />
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
                {hasLocal && (
                    <div className={`${style.section} ${style.right}`}>
                        <h3 className={style.title}>本地使用</h3>

                        <div className={style['group-wrapper']}>
                            {localChunks.map((chunk) => (
                                <div className={style['group-row']} key={chunk}>
                                    {chunk.map((g) => (
                                        <ConsoleModalGroup
                                            key={g.title}
                                            title={g.title}
                                            color={g.color}
                                            items={g.items}
                                        />
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );

    return <>{visible ? <>{redirect ? <Open to={showConsoleType[0].href} callback={onClose} /> : modal()}</> : null}</>;
}

function Open(props) {
    const {to, callback} = props;
    useEffect(() => {
        window.open(to);
        if (typeof callback === 'function') callback();
    }, [callback, to]);
    return null;
}

export function useConsoleModal() {
    return useModal('.js-consoleModalBtn');
}
