/**
 * @file 将产品页的激活线索推送到crm
 */

import {ajax} from './util';

// 需要推送的host列表
const needPushHostList = ['qianfan.cloud.baidu.com', 'wenxinworkshop-test.now.baidu.com'];

export const pushProduct2Crm = () => {
    return new Promise((resolve, reject) => {
        if (needPushHostList.includes(location.host)) {
            ajax({
                url: '/api/portal/leads',
                type: 'POST',
                contentType: 'application/json',
                data: {
                    // sourcePath: location.pathname
                    sourcePath: location.origin
                },
                success: () => {
                    resolve(true);
                },
                fail: (err) => {
                    reject(err);
                }
            });
        }
    });
};
