import {useEffect, useState, useRef} from 'preact/hooks';

import {productNav} from '~/locals/wenxin/index';
import {scrollToAim} from '~/utils';

const nav_btn_left = (
    <svg
        width="12px"
        height="12px"
        viewBox="0 0 12 12"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>icon-left</title>
        <g id="page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="锚点-居中" transform="translate(-270, -234)">
                <g id="navigation" transform="translate(0, 216)">
                    <g id="更多" transform="translate(248, 0)">
                        <g id="icon-left" transform="translate(22, 18)">
                            <rect id="矩形" x="0" y="0" width="12" height="12"></rect>
                            <polyline
                                id="路径-3"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                transform="translate(6, 6) scale(-1, 1) rotate(-90) translate(-6, -6)"
                                points="2 4 6 8 10 4"
                            ></polyline>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const nav_btn_right = (
    <svg
        width="12px"
        height="12px"
        viewBox="0 0 12 12"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>icon-right</title>
        <g id="page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="锚点-居中" transform="translate(-1022, -126)">
                <g id="navigation" transform="translate(0, 108)">
                    <g id="更多" transform="translate(1000, 0)">
                        <g id="icon-right" transform="translate(22, 18)">
                            <rect id="矩形" x="0" y="0" width="12" height="12"></rect>
                            <polyline
                                id="路径-3"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                transform="translate(6, 6) rotate(-90) translate(-6, -6)"
                                points="2 4 6 8 10 4"
                            ></polyline>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const projectArr = [
    'productFeatures',
    'fucFeatures',
    'productAdvantages',
    'typicalCase',
    'support',
    'serviceMode',
    'products'
];

const hashMap = {
    产品架构: {id: 'productFeatures', offset: 100, index: 0},
    核心功能: {id: 'fucFeatures', offset: 100, index: 1},
    产品优势: {id: 'productAdvantages', offset: 100, index: 2},
    应用场景: {id: 'typicalCase', offset: 100, index: 3},
    交流支持: {id: 'support', offset: 100, index: 4},
    服务模式: {id: 'serviceMode', offset: 100, index: 5},
    生态产品: {id: 'products', offset: 48, index: 6}
};
const idMap = {
    productFeatures: {title: '产品架构', offset: 100, index: 0},
    fucFeatures: {title: '核心功能', offset: 100, index: 1},
    productAdvantages: {title: '产品优势', offset: 100, index: 2},
    typicalCase: {title: '应用场景', offset: 100, index: 3},
    support: {title: '交流支持', offset: 100, index: 4},
    serviceMode: {title: '服务模式', offset: 100, index: 5},
    products: {title: '生态产品', offset: 48, index: 6}
};

// 导航栏高度
const nav_height = 48 + 50;
const title_height = 63;
const Nav = (props) => {
    const {title, nav, buttonGroup} = productNav;
    const {bannerRef, navContentRef: nav_content} = props;
    const itemsRef = useRef(null);
    const [activeNav, setActiveNav] = useState(0);
    const [leftBtnShow, setLeftBtnShow] = useState(false);
    const [rightBtnShow, setRightBtnShow] = useState(false);

    const scrollEventListener = () => {
        const headerNav = document.querySelector('#productTitle');
        const nav_contentReact = nav_content.current.getBoundingClientRect();
        const groupList = Array.from(nav_content.current.children);
        groupList.splice(0, 1, ...groupList[0].children);
        if (nav_contentReact) {
            groupList.map((item, index) => {
                const itemReact = item.getBoundingClientRect();
                if (itemReact.y <= nav_height && itemReact.y + itemReact.height > nav_height) {
                    const node = getMap().get(index);
                    const line = document.getElementById('LINE');
                    line.style.width = window.getComputedStyle(node).width;
                    line.style.left = node.offsetLeft + 'px';
                    setActiveNav(index);
                }
            });
            // const bannerHeight = bannerRef.current.base.getBoundingClientRect().height;
            if (nav_contentReact.y <= 0) {
                headerNav.style = 'top:0px;opacity: 1';
            } else {
                headerNav.style = `top:-${title_height}px;opacity: 1;`;
            }
        }
    };

    const targetHash = () => {
        const hash = location.hash.replace('#', '');
        return hashMap[decodeURI(hash)];
    };

    const getMap = () => {
        if (!itemsRef.current) {
            itemsRef.current = new Map();
        }
        return itemsRef.current;
    };

    const handleNavClick = (id) => {
        const selectItem = document.getElementById(projectArr[id]);
        // const offsetTop = id === 6 ? 48 : 100;
        window.location.hash = `${idMap[projectArr[id]].title}`;
        if (id === 0) {
            const features = document.getElementById(projectArr[1]);
            // 第三屏的高度
            const height = features.getBoundingClientRect().height;
            scrollToAim(features, height);
        } else {
            scrollToAim(selectItem, 0);
        }
    };

    const handleScrollLeftClick = () => {
        const scrollDom = document.querySelector('.navScroll ul');
        scrollDom.scrollTo({left: 0, behavior: 'smooth'});
        setRightBtnShow(true);
        setLeftBtnShow(false);
    };

    const handleScrollRightClick = () => {
        const scrollDom = document.querySelector('.navScroll ul');
        const width = scrollDom.getBoundingClientRect().width;
        scrollDom.scrollTo({left: scrollDom.scrollWidth - width, behavior: 'smooth'});
        setLeftBtnShow(true);
        setRightBtnShow(false);
    };

    const navScrollEventListener = () => {
        const scrollDom = document.querySelector('.navScroll ul');
        const width = scrollDom.getBoundingClientRect().width;
        if (scrollDom.scrollLeft === 0) {
            setLeftBtnShow(false);
        } else if (scrollDom.scrollLeft + width === scrollDom.scrollWidth) {
            setRightBtnShow(false);
        } else {
            setRightBtnShow(true);
            setLeftBtnShow(true);
        }
    };

    useEffect(() => {
        const scrollDom = document.querySelector('.navScroll ul');
        scrollDom.addEventListener('scroll', navScrollEventListener);
        return () => {
            scrollDom.removeEventListener('scroll', navScrollEventListener);
        };
    }, []);

    useEffect(() => {
        document.addEventListener('scroll', scrollEventListener);
        return () => {
            document.removeEventListener('scroll', scrollEventListener);
        };
    }, []);

    useEffect(() => {
        const target = targetHash();
        if (target) {
            const element = document.getElementById(target.id);
            scrollToAim(element, 0);
        } else {
            const hash = location.hash.replace('#', '');
            if (hash && projectArr.includes(hash)) {
                const element = document.getElementById(hash);
                scrollToAim(element, 0);
            }
        }
    }, []);

    // 导航出现左右切换按钮
    useEffect(() => {
        const handleResize = () => {
            const rightDom = document.querySelector('.productTitleContainer .right');
            if (window.innerWidth > 1200) {
                rightDom.style.marginLeft = '4px';
                setRightBtnShow(false);
                setLeftBtnShow(false);
            } else if (window.innerWidth < 1200 && window.innerWidth > 1164) {
                rightDom.style.marginLeft = 4 - (1200 - window.innerWidth) + 'px';
                setRightBtnShow(false);
                setLeftBtnShow(false);
            } else if (window.innerWidth < 1164) {
                rightDom.style.marginLeft = '0px';
                setRightBtnShow(true);
            } else {
                setRightBtnShow(false);
                setLeftBtnShow(false);
            }
        };
        window.addEventListener('resize', function () {
            handleResize();
        });
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="no_rem_productTitle" id="productTitle">
            <div className="productTitleContainer">
                <div className="title">{title}</div>
                <div className="navScroll">
                    {leftBtnShow && (
                        <div className="navBtnWraper navBtnWraperLeft">
                            <div className="navBtn navBtnlLeft" onClick={() => handleScrollLeftClick()}>
                                {nav_btn_left}
                            </div>
                        </div>
                    )}
                    <ul>
                        {nav.map((item) => {
                            return (
                                <li
                                    key={item.id}
                                    className={activeNav === item.id ? 'current' : ''}
                                    onClick={() => handleNavClick(item.id)}
                                    ref={(node) => {
                                        const map = getMap();
                                        if (node) {
                                            map.set(item.id, node);
                                        } else {
                                            map.delete(item.id);
                                        }
                                    }}
                                    data-track-category="千帆产品页PC-内部导航"
                                    data-track-name="导航按钮"
                                    data-track-value={item.title}
                                >
                                    <span>{item.title}</span>
                                </li>
                            );
                        })}
                        <li className="line" id="LINE" style={{width: '56px'}}></li>
                    </ul>
                    {rightBtnShow && (
                        <div className="navBtnWraper navBtnWraperRight">
                            <div className="navBtn navScrollRight" onClick={() => handleScrollRightClick()}>
                                {nav_btn_right}
                            </div>
                        </div>
                    )}
                </div>
                <div className="right">
                    {buttonGroup.map((item) => {
                        const {id, href, text, trackInfo} = item;
                        return (
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={href}
                                key={id}
                                className={item.class}
                                {...trackInfo}
                            >
                                {text}
                            </a>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Nav;
