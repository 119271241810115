import {useState} from 'preact/hooks';

import VideoPlayer, {useVideoPlayer} from 'components/videoPlayer';
import {genClassName, innerHtml} from 'utils';

import style from './AD.less';
const classname = genClassName(style);

const AD = ({data}) => {
    const [videoSrc, setVideoSrc] = useState('');
    const {visible, open, close} = useVideoPlayer();

    const visibleVideo = (src) => {
        setVideoSrc(src);
        open();
    };

    return (
        <div className={style['bml-ad']}>
            {data.map((it) => {
                const content = (
                    <>
                        <div className={style['bml-ad-item_icon']}>
                            <img src={it.img} />
                        </div>
                        <span className={style['bml-ad-item_title']}>{it.title}</span>
                        {innerHtml(<p className={style['bml-ad-item_desc']} innerHtml={it.desc} />)}
                    </>
                );
                return it.href ? (
                    <a className={style['bml-ad-item']} href={it.href} key={it.title} rel="noreferrer" target="_blank">
                        {content}
                    </a>
                ) : it.video ? (
                    <div
                        className={classname('bml-ad-item', 'bml-ad-item_pointer')}
                        key={it.title}
                        onClick={() => visibleVideo(it.video)}
                    >
                        {content}
                    </div>
                ) : (
                    <div className={style['bml-ad-item']} key={it.title}>
                        {content}
                    </div>
                );
            })}
            <VideoPlayer src={videoSrc} visible={visible} onClose={close} autoplay />
        </div>
    );
};

export default AD;
