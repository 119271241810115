import { reason } from '~/locals/wenxin';
import { useEffect } from 'preact/hooks';
import { throttle } from 'lodash';

export default function Reason() {

    useEffect(() => {
        const trggleEle = document.querySelector('.reason-container');

        const handleScroll = throttle(function () {
            const rect = trggleEle.getBoundingClientRect();
            const distanceToTop = rect.top;
            const windowHeight = window.innerHeight;
            if (windowHeight - distanceToTop > 420) {
                // 2. 添加类名
                trggleEle.classList.add('visible');
            } else {
                trggleEle.classList.remove('visible');
            }
        }, 100);

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <section className="reason-container" id='productAdvantages'>
            <div className="bJyoiK"></div>
            <div className="reason-content">
                <div className="reason-title">{reason.title}</div>
                <div className="reason-list">
                    {
                        reason.list.map(item => (
                            <div className="reason-card" key={item.key}>
                                <div
                                    className="special one"
                                    data-track-category={`千帆产品页PC-${reason.title}`}
                                    data-track-name={item.label1}
                                    data-track-value="EMPTY"
                                >
                                    {item.label1}
                                </div>
                                <div className="special two">{item.label2}</div>
                                <div className="special three">{item.label3}</div>
                                <img src={item.img} alt="" />
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
}