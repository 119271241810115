/**
 * @file 涉及dom操作的函数放在这里
 * @Author: v_panfeng01@baidu.com
 * @Date: 2020-11-05 14:55:24
 * @Last Modified by: v_panfeng01
 * @Last Modified time: 2020-11-28 21:02:29
 */

import {useEffect} from 'preact/hooks';

export function getBodyScrollTop() {
    return document.documentElement && document.documentElement.scrollTop
        ? document.documentElement.scrollTop
        : document.body.scrollTop;
}

export function scrollToAim(selector, top = 120) {
    window.scrollTo({top: getScrollLength(selector) - +top, behavior: 'smooth'});
}

export function getScrollLength(selector) {
    let oParent = typeof selector === 'object' ? selector : document.getElementById(selector);
    let domScrollTop = 0;
    while (oParent) {
        domScrollTop += oParent.offsetTop;
        oParent = oParent.offsetParent;
    }
    return domScrollTop;
}

export function sleep(delay) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

export function isVisible(ele) {
    return ele.offsetWidth > 0 || ele.offsetHeight > 0;
}

export function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}

export const useScrollToAnchor = () => {
    useEffect(() => {
        const hash = location.hash.replace('#', '');
        const target = document.getElementById(hash);
        if (target) {
            scrollToAim(target);
        }
    }, []);
};

export const getPositionType = (dom, top) => {
    if (top > dom.offsetTop) {
        dom.classList.add('fixed');
    } else {
        dom.style = 'position: relative';
    }
};

export function getElementTop(element) {
    return getScrollTop() + element.getBoundingClientRect().top;
}

export const getScrollTop = () => document.documentElement.scrollTop || document.body.scrollTop;

export const setScrollTop = (top) => {
    document.documentElement.scrollTop = top;
    document.body.scrollTop = top;
};
