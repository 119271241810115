import {useEffect, useMemo, useRef, useState} from 'preact/hooks';
import {useScrollToAnchor} from '~/utils';
import {getBceAuthInfo} from 'utils';
import {
    Banner,
    ProductFeatures,
    TypicalCase,
    ModelFeatures,
    Reason,
    Support,
    ServiceMode,
    Product,
    Nav,
    Header,
    Footer
} from '~/components/sections/wenxin/index';
import {banner, typicalCase, support, serviceMode, products} from '~/locals/wenxin/index';

import '~/style/wenxin/index.less';
// import poster from '~/assets/wenxin/foundation-poster-fff.png';

// const mp4 = 'https://aip-static.cdn.bcebos.com/landing/wenxinworkshop/foundation-fff-20230904.mp4';

export default function Wenxin() {
    const [userInfo, setUserInfo] = useState();
    const navContentRef = useRef(null);
    const bannerRef = useRef(null);

    return (
        <>
            <Header bannerRef={bannerRef} navContentRef={navContentRef} userInfo={userInfo} />
            <Banner ref={bannerRef} />
            <Nav navContentRef={navContentRef} bannerRef={bannerRef} />
            <div ref={navContentRef} style={{background: '#EBF4FF'}}>
                <div className="scroll-container">
                    <ProductFeatures />
                    <ModelFeatures />
                </div>
                <Reason />
                <TypicalCase typicalCase={typicalCase} />
                <Support support={support} className="support-item" />
                <ServiceMode serviceMode={serviceMode} />
                <Product products={products} />
            </div>
            <Footer />
        </>
    );
}
