import ALink from 'components/base/aLink';
import Section, {
    TrackType,
    EasyDLStartAbout,
    HighLightNumber,
    CooperativePartner,
    ProductForm,
    ChipDataCard,
    CardModelNlp,
    TabCardBox
} from 'components/sections';
import {Banner, BannerSwiper, ModelType, Scene} from 'components/sections/easydl';
import {
    config,
    banner,
    easydlBanner,
    modelType,
    modelCustomization,
    productForm,
    advantage,
    scene,
    cooperativePartner,
    start,
    chip,
    cardModelNlp
} from 'locals/easydl/easyDL';
import 'style/easydl/index.less';
import Affix from 'components/affix';
import useGenerateLayout from 'utils/useGenerateLayout';
import {useState, useEffect} from 'preact/hooks';
import {send} from 'utils';

const blackList = ['cloud.baidu.com'];

export default function Easydl() {
    useGenerateLayout(true);
    const [chipData, SetChipData] = useState({...chip});
    useEffect(() => {
        if (blackList.some((one) => window.location.href.includes(one))) {
            return;
        }
        send({method: 'common/userInfo'})
            .then((response) => {
                const login = response.result;
                if (login) {
                    SetChipData({
                        ...chip,
                        attrs: {...chip.attrs, 'data-track-name': `${login.email},${login.phone}`}
                    });
                }
            })
            .catch((e) => console.error(e));
    }, []);
    return (
        <TrackType.Provider value={config}>
            <Banner banner={banner} slides={easydlBanner} />
            <Section className="modal_section" title={modelType.title} style={{paddingTop: '0'}}>
                <ModelType data={modelType.data} />
            </Section>
            <Section title={modelCustomization.title} style={{paddingTop: '20px', paddingBottom: '40px'}}>
                <img className="lite modelCustom" src={modelCustomization.image} alt={modelCustomization.title} />
            </Section>
            <Section title={cardModelNlp.title}>
                <CardModelNlp data={cardModelNlp.data} />
            </Section>
            <Section title={chip.title}>
                <ChipDataCard {...chipData} />
            </Section>
            <Section title={productForm.title}>
                <ProductForm data={productForm.data} />
            </Section>
            <Section title={advantage.title} style={{paddingTop: '40px', paddingBottom: '40px'}}>
                <HighLightNumber data={advantage.data} />
            </Section>
            <Section title={scene.title} style={{paddingTop: '40px', paddingBottom: '0px'}}>
                {/* <Scene data={scene.data} /> */}
                <TabCardBox {...scene} />
                <div className="scene_tolink">
                    <ALink href='https://console.bce.baidu.com/easydl/scene' trackTag="场景范例">
                        查看更多场景范例 &gt;
                    </ALink>
                </div>
            </Section>
            <Section title={cooperativePartner.title} style={{paddingTop: '40px', paddingBottom: '40px'}}>
                <CooperativePartner {...cooperativePartner} />
                <div className="partner-more">
                    <ALink href={cooperativePartner.moreLink} trackTag="更多企业">
                        {cooperativePartner.more}
                        <i className="tzfont tzfont-arrow-right-3" />
                    </ALink>
                </div>
            </Section>
            <EasyDLStartAbout {...start} />
            <Affix target={'bml'} />
        </TrackType.Provider>
    );
}
