/*
 * @Author: v_houlin02@baidu.com
 * @Date: 2020-11-28 17:33:07
 * @LastEditTime: 2020-11-28 19:16:02
 * @file: 获取header footer
 */
/* eslint-disable max-len */
import {useLayoutEffect} from 'preact/hooks';

const createScript = (src) => {
    let script = document.createElement('script');
    script.src = src;
    document.body.appendChild(script);
    return new Promise((resolve) => {
        script.onload = () => {
            resolve();
        };
    });
};

export default function useGenerateLayout(layout) {
    useLayoutEffect(() => {
        if (layout) {
            createScript('https://bce.bdstatic.com/fe-static/baidu/bce-portal/portal-common/load.online.js').then(
                () => {
                    window.require(['portalCommon'], function (portalCommon) {
                        portalCommon.setup({
                            showHeader: true,
                            showPcFooter: true,
                            showWapFooter: true,
                            showFixedBox: false
                        });
                    });
                    window.require(['monitor'], (Monitor) => {
                        Monitor.default.init({
                            // TODO getEnv
                            appTag: 'ai-portal-pc',
                            autoTrack: true
                        });
                    });
                }
            );
            window.require(['solution'], function (page) {
                page.init();
            });
        } else {
            createScript('https://bce.bdstatic.com/fe-monitor/latest/fe-monitor.min.js').then(() => {
                window.require(['monitor'], (Monitor) => {
                    Monitor.default.init();
                });
            });
        }
    }, []);
}
