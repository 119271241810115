const OFFERS = {
    EASYEDGE_SDK: {
        name: '通用小型设备部署授权',
        tag: '5折',
        keywords: ['SDK封装', '离线服务', '适配广'],
        description:
            '将训练好的模型封装成适配智能硬件的SDK，支持IOS、Android、Linux、Windows操作系统的离线预测并获得结果',
        before: '200',
        after: '100',
        formatter: '{{price}}/个起',
        link: 'https://console.bce.baidu.com/ai/?#/ai/bml/serial/shopCart~type=device'
    },
    EASYEDGE_INTEGRATION: {
        name: '专项硬件部署授权',
        tag: '5折',
        keywords: ['软硬一体', 'SDK部署', '离线服务'],
        description:
            'BML软硬一体方案的软件部分，将模型封装成深度适配EdgeBoard、Jetson硬件产品的SDK，建议在 AI市场购买整体方案',
        before: '240',
        after: '120',
        formatter: '{{price}}/个起',
        link: 'https://console.bce.baidu.com/ai/?#/ai/bml/special/shopCart~type=device'
    },
    EASYEDGE_ON_PREMISE_API: {
        name: '服务器部署-私有API授权',
        tag: '5折',
        keywords: ['离线服务', '服务器部署'],
        description: '以API形式将训练模型部署在本地私有CPU/GPU服务器上，支持离线预测并获得结果',
        before: '10000',
        after: '5000',
        formatter: '{{price}}/年起',
        link: 'https://console.bce.baidu.com/ai/?#/ai/bml/package/buyApi~type=api'
    },
    EASYEDGE_ON_PREMISE_SDK: {
        name: '服务器部署-SDK授权',
        tag: '5折',
        keywords: ['离线服务', '服务器部署'],
        description: '以SDK形式将训练模型部署在本地私有CPU/GPU服务器上，支持离线预测并获得结果',
        before: '10000',
        after: '5000',
        formatter: '{{price}}/年起',
        link: 'https://console.bce.baidu.com/ai/?#/ai/bml/package/buySdk~type=sdk'
    },
    NEWBIE_SCRIPTIVE_RUNTIME_P40: {
        name: '训练算力-预置模型调参',
        tag: '新人',
        keywords: ['预置模型调参', 'GPU_P40'],
        description: '50小时特定训练算力（TeslaGPU_P40_24G显存单卡_12核CPU_40G）新人免费试用',
        before: '1080/50',
        after: '0',
        formatter: '{{price}}/{{unit}}（小时*节点）',
        link: 'https://ai.baidu.com/bml/app/project/script/vis',
        button: '限时免费'
    },
    NEWBIE_NOTEBOOK_RUNTIME_V100: {
        name: '训练算力-Notebook',
        tag: '新人',
        keywords: ['Notebook', 'GPU_V100'],
        description: '50小时特定训练算力（TeslaGPU_V100_32GB显存单卡_8核CPU_32GB），新人免费试用',
        before: '1500/50',
        after: '0',
        formatter: '{{price}}/{{unit}}（小时*节点）',
        link: 'https://ai.baidu.com/bml/app/project/notebook',
        button: '限时免费'
    },
    NEWBIE_CLOUD: {
        name: '公有云部署',
        tag: '新人',
        keywords: ['公有云部署', 'CPU'],
        description: '按量后付费，100小时公有云部署免费配额，可以通过访问在线API实时获取模型预测结果',
        before: '40/100',
        after: '0',
        formatter: '{{price}}/{{unit}}（小时*配额）',
        link: 'https://ai.baidu.com/bml/app/release/cloud/list',
        button: '限时免费'
    },
    NEWBIE_BATCH_PREDICTION: {
        name: '批量预测',
        tag: '新人',
        keywords: ['批量预测', 'GPU_P4'],
        description: '100小时特定批量预测服务（TeslaGPU_P4_8G显存单卡_12核CPU_40G内存）新人免费试用',
        before: '1680/100',
        after: '0',
        formatter: '{{price}}/{{unit}}（小时*节点）',
        link: 'https://ai.baidu.com/bml/app/release/predict',
        button: '限时免费'
    },
    NEWBIE_ML_RUNTIME_P40: {
        name: '训练算力-自定义作业',
        tag: '新人',
        keywords: ['自定义作业', 'GPU_P40'],
        description: '50小时特定训练算力（TeslaGPU_P40_24G显存单卡_12核CPU_40G）新人免费试用',
        before: '1080/50',
        after: '0',
        formatter: '{{price}}/{{unit}}（小时*节点）',
        link: 'https://ai.baidu.com/bml/app/project/script/vis',
        button: '限时免费'
    },
    NEWBIE_ML_RUNTIME_CPU: {
        name: '训练算力-自定义作业',
        tag: '新人',
        keywords: ['自定义作业', '4核16G'],
        description: '100小时特定训练算力（CPU 4核16GB内存）新人免费试用',
        before: '150/100',
        after: '0',
        formatter: '{{price}}/{{unit}}（小时*节点）',
        link: 'https://ai.baidu.com/bml/app/project/script/vis',
        button: '限时免费'
    },
    NEWBIE_SCRIPTIVE_RUNTIME_P4: {
        name: '训练算力-预置模型调参',
        tag: '新人',
        keywords: ['预置模型调参', 'GPU_P4'],
        description: '100小时特定训练算力（TeslaGPU_P4_8G显存单卡_12核CPU_40G）新人免费试用',
        before: '1680',
        after: '0',
        formatter: '{{price}}/（小时*节点）',
        link: 'https://ai.baidu.com/bml/app/project/script/vis',
        button: '限时免费'
    },
    NEWBIE_NOTEBOOK_RUNTIME_CPU: {
        name: '训练算力-Notebook',
        tag: '新人',
        keywords: ['Notebook', '2核8GB'],
        description: '特定训练算力（CPU 2核8GB内存），新人免费试用',
        before: '',
        after: '0',
        link: 'https://ai.baidu.com/bml/app/project/notebook',
        button: '限时免费'
    },

    RUNTIME_P4: {
        name: '训练算力-GPU_P4',
        tag: '8折',
        keywords: ['预置模型调参', '自定义作业'],
        description:
            '特定训练算力（TeslaGPU_P4_8G显存单卡_12核CPU_40G内存）8折优惠，适用于预置模型调参和自定义作业两种训练方式',
        before: '16.8',
        after: '13.44',
        formatter: '{{price}}/（小时*节点）',
        link: 'https://console.bce.baidu.com/ai/?_=1630999261051#/ai/bml/training/create'
    },
    RUNTIME_P40: {
        name: '训练算力-GPU_P40',
        tag: '8折',
        keywords: ['预置模型调参', '自定义作业'],
        description:
            '特定训练算力（TeslaGPU_P40_24G显存单卡_12核CPU_40G内存）8折优惠，适用于预置模型调参和自定义作业两种训练方式',
        before: '21.6',
        after: '17.28',
        formatter: '{{price}}/（小时*节点）',
        link: 'https://console.bce.baidu.com/ai/?_=1630999261051#/ai/bml/training/create'
    },
    RUNTIME_V100: {
        name: '训练算力-GPU_V100',
        tag: '8折',
        keywords: ['预置模型调参', '自定义作业'],
        description:
            '特定训练算力（TeslaGPU_V100_16G显存单卡_12核CPU_56G内存）8折优惠，适用于预置模型调参和自定义作业两种训练方式',
        before: '27',
        after: '21.6',
        formatter: '{{price}}/（小时*节点）',
        link: 'https://console.bce.baidu.com/ai/?_=1630999261051#/ai/bml/training/create'
    },
    NOTEBOOK_RUNTIME_V100: {
        name: '训练算力-GPU_V100',
        tag: '8折',
        keywords: ['Notebook', 'GPU_V100'],
        description: '特定训练算力（TeslaGPU_V100_32GB显存单卡_8核CPU_32GB内存）8折优惠，适用于Notebook训练',
        before: '30',
        after: '24',
        formatter: '{{price}}/（小时*节点）',
        link: 'https://console.bce.baidu.com/ai/?_=1630999261051#/ai/bml/training/create'
    },
    RUNTIME_CPU_4: {
        name: '训练算力-4核16G',
        tag: '8折',
        keywords: ['自定义作业', '4核16G'],
        description: '特定训练算力（CPU 4核16GB内存）8折优惠，适用自定义作业训练方式',
        before: '1.5',
        after: '1.2',
        formatter: '{{price}}/（小时*节点）',
        link: 'https://console.bce.baidu.com/ai/?_=1630999261051#/ai/bml/training/create'
    },
    RUNTIME_CPU_16: {
        name: '训练算力-16核64G',
        tag: '8折',
        keywords: ['自定义作业', '16核64G'],
        description: '特定训练算力（CPU 16核64G内存）8折优惠，适用自定义作业训练方式',
        before: '5.52',
        after: '4.416',
        formatter: '{{price}}/（小时*节点）',
        link: 'https://console.bce.baidu.com/ai/?_=1630999261051#/ai/bml/training/create'
    },
    RUNTIME_CPU_64: {
        name: '训练算力-64核256G',
        tag: '8折',
        keywords: ['自定义作业', '64核256G'],
        description: '特定训练算力（CPU 64核256G内存）8折优惠，适用自定义作业训练方式',
        before: '21.6',
        after: '17.28',
        formatter: '{{price}}/（小时*节点）',
        link: 'https://console.bce.baidu.com/ai/?_=1630999261051#/ai/bml/training/create'
    },
    CLOUD_CPU: {
        name: '公有云部署',
        tag: '8折',
        keywords: ['公有云部署', 'CPU'],
        description:
            '按量后付费，100小时公有云部署免费配额（1核cpu和4G内存折算为1配额）,您可以通过访问在线API实时获取模型预测结果',
        before: '0.4',
        after: '0.32',
        formatter: '{{price}}/（小时*配额）',
        link: 'https://console.bce.baidu.com/ai/?_=1631002342677#/ai/bml/quota/create'
    },

    BATCH_PREDICTION_P4: {
        name: '批量预测-GPU_P4',
        tag: '8折',
        keywords: ['后付费', 'GPU_P4'],
        description: '按量后付费，特定算力（TeslaGPU_P4_8G显存单卡_12核CPU_40G内存）8折优惠',
        before: '16.8',
        after: '13.44',
        formatter: '{{price}}/（小时*节点）',
        link: 'https://console.bce.baidu.com/ai/?#/ai/bml/training/create'
    },
    BATCH_PREDICTION_P40: {
        name: '批量预测-GPU_P40',
        tag: '8折',
        keywords: ['后付费', 'GPU_P40'],
        description: '按量后付费，特定算力（TeslaGPU_P40_24G显存单卡_12核CPU_40G内存）8折优惠',
        before: '21.6',
        after: '17.28',
        formatter: '{{price}}/（小时*节点）',
        link: 'https://console.bce.baidu.com/ai/?#/ai/bml/training/create'
    },
    BATCH_PREDICTION_V100: {
        name: '批量预测-GPU_V100',
        tag: '8折',
        keywords: ['后付费', 'GPU_V100'],
        description: '按量后付费，特定算力（TeslaGPU_V100_32GB显存单卡_8核CPU_32GB内存）8折优惠，适用于Notebook预测',
        before: '30',
        after: '24',
        formatter: '{{price}}/（小时*节点）',
        link: 'https://console.bce.baidu.com/ai/?#/ai/bml/training/create'
    },
    BATCH_PREDICTION_CPU_4: {
        name: '批量预测-4核16G',
        tag: '8折',
        keywords: ['后付费', '4核16G'],
        description: '按量后付费，特定算力（CPU 4核16GB内存）8折优惠',
        before: '1.5',
        after: '1.2',
        formatter: '{{price}}/（小时*节点）',
        link: 'https://console.bce.baidu.com/ai/?#/ai/bml/training/create'
    },
    BATCH_PREDICTION_CPU_16: {
        name: '批量预测-16核64G',
        tag: '8折',
        keywords: ['后付费', '16核64G'],
        description: '按量后付费，特定算力（CPU 16核64G内存）8折优惠',
        before: '5.52',
        after: '4.416',
        formatter: '{{price}}/（小时*节点）',
        link: 'https://console.bce.baidu.com/ai/?#/ai/bml/training/create'
    },
    BATCH_PREDICTION_CPU_64: {
        name: '批量预测-64核256G',
        tag: '8折',
        keywords: ['后付费', '64核256G'],
        description: '按量后付费，特定算力（CPU 64核256G内存）8折优惠',
        before: '21.6',
        after: '17.28',
        formatter: '{{price}}/（小时*节点）',
        link: 'https://console.bce.baidu.com/ai/?#/ai/bml/training/create'
    }
};

export const specialOffer = {
    title: '近期特惠',
    data: [
        {
            category: '热门产品',
            items: [
                OFFERS.EASYEDGE_SDK,
                OFFERS.NEWBIE_SCRIPTIVE_RUNTIME_P40,
                OFFERS.NEWBIE_NOTEBOOK_RUNTIME_V100,
                OFFERS.NEWBIE_CLOUD
            ]
        },
        {
            category: '新人专享',
            items: [
                OFFERS.NEWBIE_SCRIPTIVE_RUNTIME_P40,
                OFFERS.NEWBIE_NOTEBOOK_RUNTIME_V100,
                OFFERS.NEWBIE_CLOUD,
                OFFERS.NEWBIE_BATCH_PREDICTION,
                OFFERS.NEWBIE_ML_RUNTIME_P40,
                OFFERS.NEWBIE_ML_RUNTIME_CPU,
                OFFERS.NEWBIE_SCRIPTIVE_RUNTIME_P4,
                OFFERS.NEWBIE_NOTEBOOK_RUNTIME_CPU
            ]
        },
        {
            category: '训练计费',
            items: [
                OFFERS.RUNTIME_P4,
                OFFERS.RUNTIME_P40,
                OFFERS.RUNTIME_V100,
                OFFERS.NOTEBOOK_RUNTIME_V100,
                OFFERS.RUNTIME_CPU_4,
                OFFERS.RUNTIME_CPU_16,
                OFFERS.RUNTIME_CPU_64
            ]
        },
        {category: '公有云部署', items: [OFFERS.CLOUD_CPU]},
        {
            category: '离线部署',
            items: [
                OFFERS.EASYEDGE_SDK,
                OFFERS.EASYEDGE_INTEGRATION,
                OFFERS.EASYEDGE_ON_PREMISE_API,
                OFFERS.EASYEDGE_ON_PREMISE_SDK
            ]
        },
        {
            category: '批量预测',
            items: [
                OFFERS.BATCH_PREDICTION_P4,
                OFFERS.BATCH_PREDICTION_P40,
                OFFERS.BATCH_PREDICTION_V100,
                OFFERS.BATCH_PREDICTION_CPU_4,
                OFFERS.BATCH_PREDICTION_CPU_16,
                OFFERS.BATCH_PREDICTION_CPU_64
            ]
        }
    ]
};
