export {default as ModelFeatures} from './ModelFeatures';
export {default as Reason} from './Reason';

export { default as TypicalCase} from './typicalCase';
export { default as Support} from './support';
export { default as ServiceMode} from './serviceMode';

export {default as Banner} from './Banner';
export {default as Header} from './Head';
export {default as Nav} from './Nav';
export {default as ProductFeatures} from './ProductFeatures';
export {default as Product} from './aiProduct';
export {default as Footer} from './Footer';