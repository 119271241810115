/*
 * @Author: v_houlin02@baidu.com
 * @Date: 2020-11-20 14:07:04
 * @file: lite.js
 */

import {cooperativePartner as cooperativePartnerData} from 'locals/easydl/common';
// 百度云 https://cloud.baidu.com/product/easydl
const whenCloudPlat = (a, b) => (process.env.IS_CLOUD ? a : b);

export const config = {
    trackType: 'EasyDL官网',
    layout: 'easydl',
    page: 'easydl'
};
export const banner = {
    title: whenCloudPlat('飞桨EasyDL零门槛AI开发平台', 'EasyDL零门槛AI开发平台'),
    buttonGroup: [
        {
            text: '立即使用',
            className: 'js-consoleModalBtn',
            type: 'primary',
            trackTag: '立即使用_官网上方',
            attrs: {
                'data-track-action': 'click',
                'data-track-category': 'EasyDL立即使用',
                'data-track-name': 'EasyDL-产品页面-立即使用',
                'data-track-value': ''
            }
        },
        {
            text: '使用文档',
            href: 'https://ai.baidu.com/ai-doc/EASYDL/pk38n3odv',
            type: 'default-primary',
            trackTag: '使用文档_官网上方'
        }
    ],
    left: {
        title: '零算法基础定制<br/>高精度AI模型',
        data: [
            {
                text: whenCloudPlat('【了解】飞桨EasyDL', '【了解】EasyDL简介'),
                video: 'http://hbnm3eqf9mju26279qj.exp.bcevod.com/mda-jh4m627fahdu4wkn/mda-jh4m627fahdu4wkn.mp4',
                icon: require('assets/easydl/video-rect.svg').default
            },
            {
                text: '【上手】新手教程',
                href: 'https://console.bce.baidu.com/easydl/tutorial/model/objdct/models',
                icon: require('assets/easydl/tag.svg').default
            },
            {
                text: '【实践】场景范例',
                href: 'https://console.bce.baidu.com/easydl/scene',
                icon: require('assets/easydl/tag.svg').default
            },
            {text: '【购买】平台定价', href: 'https://ai.baidu.com/ai-doc/EASYDL/Xl2tvkkx8'}
        ],
        tag: 'EasyDL入选Forrester领导者象限'
    },
    // 首页广告
    right: {
        realTimeInfo: [
            {
                text: 'EasyDL场景范例',
                href: 'https://console.bce.baidu.com/easydl/scene'
            },
            {
                text: 'EasyDL桌面版全新发布',
                href: 'https://ai.baidu.com/easydl/paddle'
            },
            {
                text: 'EasyDL训练算力限额免费',
                href: 'https://ai.baidu.com/forum/topic/show/1912505'
            }
        ]
    }
};
// banner update
export const easydlBanner = [
    // {
    //     name: 'ai-wavesummit',
    //     title: 'WAVE SUMMIT 深度学习开发者峰会2023',
    //     subTitle: '2023.05.31 北京望京凯悦酒店',
    //     bg: require('assets/banner/easydl-wavesummit.jpg').default,
    //     align: 'center',
    //     buttonGroup: [
    //         {
    //             name: '立即报名',
    //             href: 'https://www.wavesummit.com.cn',
    //             size: 'mini',
    //             round: 'half'
    //         }
    //     ]
    // },
    {
        name: 'ai-wavesummit',
        title: 'WAVE SUMMIT + 深度学习开发者大会2023',
        subTitle: '2023.12.28 北京·新云南皇冠假日酒店',
        bg: require('assets/banner/easydl-wavesummit.jpg').default,
        align: 'left',
        buttonGroup: [
            {
                name: '立即报名',
                href: 'https://www.wavesummit.com.cn/#/',
                size: 'mini',
                round: 'half'
            }
        ]
    },
    {
        name: 'ai-wenxin',
        title: '玩转文心大模型定制开发',
        subTitle: '多场景创作能力&emsp;少样本训练&emsp;任务效果领先',
        bg: require('assets/banner/easydl-wenxin.png').default,
        align: 'left',
        buttonGroup: [
            {
                name: '立即解锁',
                href: 'https://ai.baidu.com/easydl/nlp/',
                size: 'mini',
                round: 'half'
            }
        ]
    },
    {
        name: 'ai-scene',
        title: 'AI场景范例实战营',
        subTitle: '零基础AI建模，30分钟快速落地应用',
        bg: require('assets/banner/ai-scene.png').default,
        align: 'left',
        buttonGroup: [
            {
                name: '立即参与',
                href: 'https://console.bce.baidu.com/easydl/scene',
                size: 'mini',
                round: 'half'
            }
        ]
    },
    // {
    //     name: 'easydlCase',
    //     title: whenCloudPlat('飞桨EasyDL经典案例集锦', 'EasyDL经典案例集锦'),
    //     subTitle: '工业 零售 互联网等多业务场景应用实例',
    //     bg: require('assets/banner/easydlCase.jpg').default,
    //     align: 'left',
    //     buttonGroup: [
    //         {
    //             name: '立即查看',
    //             href: `${process.env.CUSTOMER}?industry=0&technology=8&clickType=industry`,
    //             size: 'mini',
    //             round: 'half'
    //         }
    //     ]
    // },
];
export const modelType = {
    title: '丰富的技术方向与模型类型',
    // modeTypeLable: '',
    data: [
        {
            icon: require('assets/easydl/modelTypeIcon/vision.png').default,
            title: whenCloudPlat('飞桨EasyDL图像', 'EasyDL图像'),
            href: process.env.SUBVIS,
            type: '图像分类 物体检测 图像分割',
            tag: '文心大模型',
            desc: '以文心大模型为基座，实现图像内容理解分类、图中物体检测定位等，适用于图片内容检索、安防监控、工业质检等场景，支持较少标注样本情景的模型定制。'
        },
        {
            icon: require('assets/easydl/modelTypeIcon/nlp.png').default,
            title: whenCloudPlat('飞桨EasyDL文本', 'EasyDL文本'),
            href: process.env.SUBNLP,
            type: '分类任务 匹配任务 序列标注任务',
            tag: '文心大模型',
            desc: '以文心大模型为基座，提供多种NLP模型的零门槛定制与应用能力，广泛适用于咨询分类、内容审核、内容生产等各类文本处理与分析的场景'
        },
        {
            icon: require('assets/easydl/modelTypeIcon/audio.png').default,
            title: whenCloudPlat('飞桨EasyDL语音', 'EasyDL语音'),
            href: process.env.SUBAUDIO,
            type: '语音识别 声音分类',
            desc: '定制语音识别模型，精准识别业务专有名词，适用于数据采集录入、语音指令、呼叫中心等场景，以及定制声音分类模型，用于区分不同声音类别'
        },
        {
            icon: require('assets/easydl/modelTypeIcon/ocr.png').default,
            title: whenCloudPlat('飞桨EasyDL OCR', 'EasyDL OCR'),
            href: process.env.SUBOCR,
            type: '文字识别',
            desc: '定制化训练文字识别模型，结构化输出关键字段内容，满足个性化卡证票据识别需求，适用于证照电子化审批、财税报销电子化等场景'
        },
        {
            icon: require('assets/easydl/modelTypeIcon/video.png').default,
            title: whenCloudPlat('飞桨EasyDL视频', 'EasyDL视频'),
            href: process.env.SUBVIDEO,
            type: '目标跟踪 视频分类',
            desc: '定制化分析视频片段内容、跟踪视频中特定的目标对象，适用于视频内容审核、人流/车流统计、养殖场牲畜移动轨迹分析等场景'
        },
        {
            icon: require('assets/easydl/modelTypeIcon/structdata.png').default,
            title: whenCloudPlat('飞桨EasyDL结构化数据', 'EasyDL结构化数据'),
            href: process.env.SUBSTRUCTDATA,
            type: '表格预测 时序预测',
            desc: '挖掘数据中隐藏的模式，解决二分类、多分类、回归等问题，适用于客户流失预测、欺诈检测、价格预测等场景'
        },
        {
            icon: require('assets/easydl/modelTypeIcon/crossModal.png').default,
            title: whenCloudPlat('飞桨EasyDL跨模态', 'EasyDL跨模态'),
            href: process.env.SUBVQA,
            type: '图文匹配',
            tag: '文心大模型',
            desc: '以文心大模型为基座，定制图文匹配模型。融合语言、视觉等多模态信息，输出图文内容的匹配度，适用于内容质量评价、图文素材推荐等场景'
        },
        {
            icon: require('assets/easydl/modelTypeIcon/retail.png').default,
            title: whenCloudPlat('飞桨EasyDL零售行业版', 'EasyDL零售行业版'),
            href: process.env.RETAIL,
            type: '商品检测 排面占比统计 货架拼接 陈列层数识别 陈列场景识别 翻拍识别',
            desc: '定制化商品检测模型，基于百度大规模零售数据预训练模型及数据增强合成技术，提供多种可选服务功能和成熟AI能力，全面满足零售快消行业商品识别相关业务需求'
        }
    ]
};
export const modelCustomization = {
    title: '一站式模型定制',
    image: require('assets/easydl/model-customization.png').default
};
export const productForm = {
    title: '多样的使用方式',
    data: [
        {
            name: 'onLine-easydl',
            title: '在线使用',
            desc: whenCloudPlat(
                '在云端使用飞桨EasyDL完成一站式模型训练及部署，即刻上手体验，无需依赖本地算力环境。云端产出的模型可灵活支持在线、离线多种部署方案',
                '在云端使用 EasyDL完成一站式模型训练及部署，即刻上手体验，无需依赖本地算力环境。云端产出的模型可灵活支持在线、离线多种部署方案'
            ),
            href: [
                {text: '立即使用', href: 'https://console.bce.baidu.com/easydl/overview'},
                {text: '使用文档', href: '//ai.baidu.com/ai-doc/EASYDL/pk38n3odv'}
            ]
        },
        {
            name: 'local-easydl',
            title: '本地使用',
            desc: '在本地安装飞桨EasyDL-桌面版，可实现本地离线完成一站式模型训练及部署流程，数据与产出的模型可在本地高效管理',
            href: [{text: '立即使用', href: 'https://ai.baidu.com/easydl/paddle'}]
        }
    ]
};
export const advantage = {
    title: '领先的功能特性',
    data: [
        {
            title: '零门槛',
            subtitle: '最快<b>15</b>分钟定制AI服务',
            button: '了解详情',
            href: `${process.env.EASYDL_ADVANTAGE}#零门槛`,
            desc: ['一站式自动化训练', '无需关注算法细节', '可视化拖拽式便捷操作']
        },
        {
            title: '高精度',
            subtitle: '<b>95</b>+%平均准确率',
            button: '了解详情',
            href: `${process.env.EASYDL_ADVANTAGE}#高精度`,
            desc: ['自动超参搜索、自动数据增强', '内置文心大模型预训练基座', '极小目标检测、不平衡数据处理等先进机制']
        },
        {
            title: '低成本',
            subtitle: '<b>80</b>+%采集/标注效率提升',
            button: '了解详情',
            href: `${process.env.EASYDL_ADVANTAGE}#低成本`,
            desc: ['首个端云一体的数据采集方案', '支持5种数据格式，11种标注模板', '智能标注与多人标注大幅提升效率']
        },
        {
            title: '可交易',
            subtitle: '<b>40</b>+%模型训练成本降低',
            button: '了解详情',
            href: `${process.env.EASYDL_ADVANTAGE}#可交易`,
            desc: [
                '3分钟将模型开放售卖，获取额外收益',
                '首家支持已购模型再训练，有效提升模型精度',
                '探索丰富的智能硬件，加快集成应用'
            ]
        }
    ]
};
export const scene = {
    title: '精选场景范例',
    desc: '完整的AI落地全流程指导，包括问题定位、方案设计、数据处理、模型训练、模型部署及优化思路的一站式操作指导',
    data: [
        {
            name: '工业制造',
            title: '工业零件划痕自动识别',
            image: require('assets/scene/workpiece.jpg').default,
            tag: '物体检测、零件划痕、工业制造',
            desc: '通过训练物体检测定制化AI模型，实现对于“工业零件划痕”的智能识别，广泛应用于工业质检、智能制造等领域。',
            category: 16
        },
        {
            name: '智慧城市',
            title: '明厨亮灶厨师帽识别',
            category: 18,
            image: require('assets/scene/chef-cap.png').default,
            tag: '物体检测、厨师帽、明厨亮灶、智慧城市',
            desc: '通过训练物体检测定制化AI模型，实现对于“厨师是否佩戴厨师帽”的智能行为识别，广泛应用于明厨亮灶、食品安全管理等领域。'
        },
        {
            name: '电子商务',
            title: '电商UGC图片自动分类',
            category: 13,
            image: require('assets/scene/shoe.jpg').default,
            tag: '图像分类、鞋子识别、营销、电子商务',
            desc: '通过训练图像分类定制化AI模型，实现对于“电商网站UGC商品拍图”的智能识别，广泛应用于精准推送、互动营销等领域。'
        },
        {
            name: '能源',
            title: '新能源发电量预测',
            category: 26,
            image: require('assets/scene/new-energy.png').default,
            tag: '时序预测、能源、新能源发电',
            desc: '通过EasyDL时序预测功能快速训练新能源发电预测模型，综合考虑发电性能、天气环境等因素，准确预测发电量波动，辅助工业厂区能源管理、电力企业能源调度。'
        },
        {
            name: '零售',
            title: '零售店商品销量预估',
            category: 24,
            image: require('assets/scene/supermarket.png').default,
            tag: '结构化数据、表格预测、零售、运营管理',
            desc: '通过训练表格预测定制化AI模型，实现零售行业的销量预测，广泛应用于零售行业的运营管理场景，帮助零售商合理安排生产采购、供应链以及促销方案，提高整体利润。'
        }
    ]
};
export const cardModelNlp = {
    title: '内置文心大模型基座',
    data: {
        img: require('assets/public/model-cardModelNlp.png').default,
        title: ['产业级知识增强大模型', '千行百业AI开发的首选基座大模型'],
        toLink: [
            {
                name: '了解详情',
                href: 'https://wenxin.baidu.com'
            },
            {
                name: '使用介绍',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/Ckwud0hk2'
            }
        ],
        tag: [
            {
                icon: require('assets/public/cardModelNlp-skill.png').default,
                name: '技术领先'
            },
            {
                icon: require('assets/public/cardModelNlp-all.png').default,
                name: '全场景覆盖'
            },
            {
                icon: require('assets/public/cardModelNlp-sill.png').default,
                name: '零门槛使用'
            },
            {
                icon: require('assets/public/cardModelNlp-real.png').default,
                name: '真实可落地'
            }
        ]
    }
};
export const cooperativePartner = {
    title: whenCloudPlat('飞桨EasyDL助力客户与伙伴成功', 'EasyDL助力客户与伙伴成功'),
    more: '更多企业',
    moreLink: `${process.env.CUSTOMER}?industry=0&technology=8&clickType=tech`,
    data: cooperativePartnerData
};
export const start = {
    title: whenCloudPlat('开始定制飞桨EasyDL模型', '开始定制EasyDL模型'),
    desc: [
        {
            label: '平台用户',
            text: '无需深度学习专业知识，在线全流程可视化操作'
        },
        {
            label: '专家定制',
            text: '专家定制服务，项目制交付端到端满足AI赋能需求'
        }
    ],

    buttonGroup: [
        {
            name: '在线训练',
            className: 'js-consoleModalBtn',
            type: 'primary',
            trackTag: '在线训练_官网下方',
            attrs: {
                'data-track-action': 'click',
                'data-track-category': 'EasyDL立即使用',
                'data-track-name': 'EasyDL-产品页面-在线训练',
                'data-track-value': ''
            }
        },
        // {
        //     name: '专家定制',
        //     href: 'https://ai.baidu.com/easydl/custom/',
        //     type: 'default-primary',
        //     trackTag: '专家定制_官网下方'
        // }
    ],
    aboutLink: [
        {
            title: 'BML 全功能AI开发平台',
            href: 'https://ai.baidu.com/bml/',
            desc: '适合有一定AI开发基础，并且有灵活调参需求的企业开发者'
        },
        {
            title: '飞桨PaddlePaddle',
            href: 'https://www.paddlepaddle.org.cn/',
            desc: whenCloudPlat(
                '源于产业实践的开源深度学习平台，飞桨EasyDL基于飞桨构建',
                '源于产业实践的开源深度学习平台，EasyDL基于飞桨构建'
            )
        }
    ]
};
export const chip = {
    title: '全面的边缘部署能力',
    desc: ['云边端全场景覆盖  30+芯片广泛适配', '专项优化加速 极致推理性能'],
    href: 'https://ai.baidu.com/easydl/app/bie/adapt/lite',
    name: '查看模型算法推理性能',
    bg: require('assets/public/bg-adaptive.png').default,
    attrs: {
        'data-track-action': 'click',
        'data-track-category': '首页查看大表',
        'data-track-name': '',
        'data-track-value': ''
    }
};
