import classnames from 'classnames';

export default function serviceMode(props) {
    const {serviceMode} = props;
    return (
        <div className="serviceMode" id="serviceMode">
            <h2>多种服务模式满足企业级标准化交付</h2>
            <div className="serviceMode-box">
                {serviceMode.map((item, idx) => {
                    return (
                        <div key={item.title} className={classnames(idx === 1 && 'mode-active', 'serviceMode-item')}>
                            <p className="serviceMode-item-title">{item.title}</p>
                            <div className="serviceMode-item-content">
                                <img src={item.content_icon} alt="" />
                                <span className="no_rem_fs_16">{item.content}</span>
                            </div>
                            <div className="serviceMode-desc-box">
                                {item.desc.map((des, cindex) => {
                                    return (
                                        <div className="serviceMode-desc" key={des}>
                                            <img src={require('assets/wenxin/check-mark.png').default} alt="" />
                                            <span>{des}</span>
                                        </div>
                                    );
                                })}
                                <a
                                    className="serviceMode-button"
                                    data-track-category="千帆产品页PC-多种服务模式满足企业级标准化交付"
                                    data-track-name={item.title}
                                    data-track-value={item.btn}
                                    href={item.link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span className="serviceMode-button-link">{item.btn}</span>
                                </a>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
