import {useEffect, useState, useRef} from 'preact/hooks';
import {Swiper, SwiperSlide} from 'swiper/react';
import {throttle} from 'lodash';

import Tooltip from '~/components/Tooltip';
import {innerHtml} from '~/utils';
import poster from '~/assets/wenxin/banner-bg.jpg';
import {banner, model, notice} from '~/locals/wenxin/index';
import arrowIcon from '~/assets/wenxin/icon_play.png';

const mp4 = 'https://aip-static.cdn.bcebos.com/landing/product/home-screen-bg-2024-3-20.mp4';

const Banner = () => {
    const config = {
        effect: 'cards',
        observer: true,
        observeParents: true,
        slidesPerView: 4.576,
        spaceBetween: 14,
        freeMode: true,
        centeredSlides: true,
        loop: true,
        speed: 10000,
        loopPreventsSlide: false,
        autoplay: {
            delay: 500,
            stopOnLastSlide: false,
            disableOnInteraction: false
        },
        navigation: {
            clickable: true
        },
        loopFillGroupWithBlank: true,
        normalizeSlideIndex: true,
        onSlideChange: () => {
            const currentPage = swiperRef.current?.swiper.activeIndex;
            const pageIndex = currentPage - 4;
            const totalPage = model.data.length + 1;
            const progressContainer = document.querySelector('.progress-bar-container');
            const progressBar = document.querySelector('.progress-bar');
            const progressContainerlength = Number(window.getComputedStyle(progressContainer).width.slice(0, -2));
            const progressBarlength = (pageIndex / totalPage) * progressContainerlength;
            progressBar.style.width = progressBarlength + 'px';
            if (currentPage === 0 && currentPageRef.current) {
                swiperRef.current?.swiper.slideTo(currentPageRef.current - 1, 500);
            }
        }
        // breakpoints: {
        //     1900: {
        //         slidesPerView: 5,
        //         spaceBetween: 14
        //     },
        //     2300: {
        //         slidesPerView: 6,
        //         spaceBetween: 14
        //     },
        //     2800: {
        //         slidesPerView: 7,
        //         spaceBetween: 14
        //     },
        //     3200: {
        //         slidesPerView: 8,
        //         spaceBetween: 14
        //     },
        //     3700: {
        //         slidesPerView: 9,
        //         spaceBetween: 14
        //     }
        // }
        // navigation: {
        //     // nextEl:'background-card background-card-left'
        //     nextEl: '.swiper-button-next',
        //     prevEl: '.swiper-button-prev'
        // }
    };
    const [swiperConfig, setSwiperConfig] = useState(config);
    const [desHeight, setDesHeight] = useState(null);
    const {title, des, buttonGroup} = banner;
    const swiperRef = useRef();
    const cardDesRef = useRef();
    const currentPageRef = useRef();

    const handleMouseenter = () => {
        if (swiperRef.current && swiperRef.current.swiper.autoplay) {
            const instance = swiperRef.current.swiper.getTranslate();
            swiperRef.current.swiper.setTranslate(instance);
            swiperRef.current.swiper.autoplay.stop();
        }
    };
    const handleMouseleave = () => {
        if (swiperRef.current && swiperRef.current.swiper.autoplay) {
            swiperRef.current.swiper.autoplay.start();
        }
    };

    const slideNext = throttle(() => {
        swiperRef.current?.swiper.slideNext(500);
    }, 500);

    const slidePrev = () => {
        currentPageRef.current = swiperRef.current?.swiper.activeIndex;
        swiperRef.current?.swiper.slidePrev(500);
    };

    const getMap = () => {
        if (!cardDesRef.current) {
            // 首次运行时初始化 Map。
            cardDesRef.current = {copy: {}};
        }
        return cardDesRef.current;
    };

    const overflowDes = (nameOverflow, h) => {
        const desTwoHeight = desHeight['des-two']?.clientHeight;
        const desThreeHeight = desHeight['des-three']?.clientHeight;
        if (nameOverflow) {
            return h > desTwoHeight;
        } else {
            return h > desThreeHeight;
        }
    };

    const setDesRef = (node, nameOverflow) => {
        const map = getMap();
        if (node) {
            if (nameOverflow) {
                if (!map['des-two']) {
                    map['des-two'] = node;
                }
            } else {
                if (!map['des-three']) {
                    map['des-three'] = node;
                }
            }
        }
    };

    useEffect(() => {
        setDesHeight(getMap());
        setSwiperConfig({...config, speed: 3500, autoplay: {...config.autoplay, delay: 0}});
    }, []);

    return (
        <div className="wx-banner no_rem_banner">
            <video muted autoPlay loop poster={poster} playsinline="true" webkit-playsinline="true">
                <source src={mp4} type="video/mp4" />
            </video>
            <div className="banner-container">
                <div className="notice no_rem-notice">
                    <span className="tag no_rem_fs_16">{notice.title}</span>
                    <span className="des no_rem_fs_16">{notice.desc}</span>
                    {/* <img src={arrowIcon} alt="箭头" className="arrow" /> */}
                </div>
                <h2 className="banner-title">
                    <span className="gradual">{title}</span>
                </h2>
                <p className="banner-des">{des}</p>
                <div className="button-list">
                    {buttonGroup.map((item) => {
                        const {href, id, trackInfo, text} = item;
                        if (id === 1 || id === 2) {
                            return (
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={href}
                                    key={id}
                                    className={item.class}
                                    {...trackInfo}
                                >
                                    <span className="gradient">{text}</span>
                                </a>
                            );
                        }
                        return (
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={href}
                                key={id}
                                className={item.class}
                                {...trackInfo}
                            >
                                {text}
                            </a>
                        );
                    })}
                </div>
                <div
                    className="banner-swiper-wrapper no_rem_max_width"
                    onMouseEnter={handleMouseenter}
                    onMouseLeave={handleMouseleave}
                >
                    <Swiper {...swiperConfig} ref={swiperRef}>
                        {model.data.map((item, index) => {
                            const {
                                name,
                                nameDesc,
                                brand,
                                type,
                                desc,
                                support,
                                onlineTest,
                                deployment,
                                doc,
                                tag,
                                tagClass,
                                nameOverflow,
                                applyTest,
                                icon
                            } = item;
                            const modelLabel = [...brand, type];
                            const namePopperContent = nameDesc ? innerHtml(<div innerHtml={nameDesc} />) : '';
                            const descPopperContent = innerHtml(<div innerHtml={desc} />);
                            let isOverflowDes = false;
                            if (desHeight) {
                                const HEIGHT = desHeight?.copy?.[index]?.offsetHeight;
                                isOverflowDes = overflowDes(nameOverflow, HEIGHT);
                            }
                            return (
                                <SwiperSlide key={name}>
                                    <div className="modelcard" key={name}>
                                        {icon ? (
                                            <img src={icon} />
                                        ) : (
                                            <span className={`custom-model-icon custom_icon${index % 5}`}>
                                                {name.slice(0, 1)}
                                            </span>
                                        )}
                                        {tag && <span className={`tag ${tagClass}`}>{tag}</span>}
                                        {namePopperContent ? (
                                            <h4 className="name">
                                                <Tooltip
                                                    popperContent={namePopperContent}
                                                    popperClassName="modelcard-name-popper"
                                                    placement="top-start"
                                                    popperAppendToBody={false}
                                                >
                                                    {name}
                                                </Tooltip>
                                            </h4>
                                        ) : (
                                            <h4 className="name">{name}</h4>
                                        )}
                                        <div className="model-label-list">
                                            {modelLabel.map((value) => {
                                                return (
                                                    <span className="model-label" key={value}>
                                                        {value}
                                                    </span>
                                                );
                                            })}
                                        </div>
                                        <div className="modelcard-desc-wrapper">
                                            {isOverflowDes ? (
                                                <Tooltip
                                                    popperContent={descPopperContent}
                                                    popperClassName="modelcard-desc-popper"
                                                    className="modelcard-desc"
                                                    placement="top-start"
                                                >
                                                    <p
                                                        ref={(node) => {
                                                            setDesRef(node, nameOverflow);
                                                        }}
                                                        className={
                                                            nameOverflow
                                                                ? 'no_rem_fs_16 des des-two'
                                                                : 'no_rem_fs_16 des des-three'
                                                        }
                                                    >
                                                        {descPopperContent}
                                                    </p>
                                                </Tooltip>
                                            ) : (
                                                <p
                                                    ref={(node) => {
                                                        setDesRef(node, nameOverflow);
                                                    }}
                                                    className={
                                                        nameOverflow
                                                            ? 'no_rem_fs_16 des des-two'
                                                            : 'no_rem_fs_16 des des-three'
                                                    }
                                                >
                                                    {desc}
                                                </p>
                                            )}
                                            <p
                                                key={name}
                                                ref={(node) => {
                                                    const map = getMap();
                                                    if (node) {
                                                        map.copy[index] = node;
                                                    }
                                                }}
                                                className="des-copy no_rem_fs_16"
                                            >
                                                {desc}
                                            </p>
                                        </div>
                                        <div className="banner-support">{support}</div>
                                        <div className="link">
                                            {onlineTest && (
                                                <a
                                                    href={onlineTest}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    data-track-category="千帆产品页PC-banner"
                                                    data-track-name={name}
                                                    data-track-value="在线体验"
                                                >
                                                    在线体验
                                                </a>
                                            )}
                                            {deployment && (
                                                <a
                                                    href={deployment}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    data-track-category="千帆产品页PC-banner"
                                                    data-track-name={name}
                                                    data-track-value="快速部署"
                                                >
                                                    快速部署
                                                </a>
                                            )}
                                            {doc && (
                                                <a
                                                    href={doc}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    data-track-category="千帆产品页PC-banner"
                                                    data-track-name={name}
                                                    data-track-value="API文档"
                                                >
                                                    API文档
                                                </a>
                                            )}
                                            {applyTest && (
                                                <a
                                                    href={applyTest}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    data-track-category="千帆产品页PC-banner"
                                                    data-track-name={name}
                                                    data-track-value="申请测试"
                                                >
                                                    申请测试
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                        <SwiperSlide key="last-card">
                            <div className="modelcard more-model" key="last-card">
                                <h4 className="more-model-title">更多模型</h4>
                                <p className="more-model-des">
                                    预置最全面最丰富的大模型，请进入千帆大模型平台-模型广场查看
                                </p>
                                <div className="more-model-link">
                                    <a
                                        href="https://console.bce.baidu.com/qianfan/modelcenter/model/buildIn/list"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        data-track-category="千帆产品页PC-banner"
                                        data-track-name="更多模型"
                                        data-track-value="体验更多"
                                    >
                                        进入模型广场
                                    </a>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div onClick={slidePrev} className="swiper-button swiper-button-prev">
                        <i className="tzfont tzfont-arrow-left-2 icon"></i>
                    </div>
                    <div onClick={slideNext} className="swiper-button swiper-button-next">
                        <i className="tzfont tzfont-arrow-right-2 icon"></i>
                    </div>
                    {/* <div className="background-card background-card-left"></div>
                    <div className="background-card background-card-right"></div> */}
                </div>
            </div>
            <div className="progress-bar-container">
                <div className="progress-bar" id="progress-bar"></div>
            </div>
        </div>
    );
};

export default Banner;
