import {useState, useMemo} from 'preact/hooks';
import classnames from 'classnames';
import css from './tab.less';
import {genClassName} from 'utils';

const cls = genClassName(css);

export const TabGroup = (props) => {
    const keys = props.children.map((c) => c.key);

    const [currentKey, setCurrentKey] = useState(props.defaultKey || keys[0]);
    const currentTab = useMemo(() => props.children.find((com) => com.key === currentKey), [
        currentKey,
        props.children
    ]);

    const heads = useMemo(
        () =>
            props.children.map((one) => {
                const tab = one.props.tab;
                const key = one.key;

                const handleClick = () => setCurrentKey(key);
                const className = classnames([
                    cls('tab-group_tab'),
                    {[cls('tab-group_active-tab')]: key === currentKey}
                ]);
                return (
                    <div key={tab} className={className} onClick={handleClick}>
                        {tab}
                    </div>
                );
            }),
        [props.children, currentKey, setCurrentKey]
    );

    return (
        <div className={cls('tab-group')}>
            <div className={cls('tab-group_head')}>{heads}</div>
            <div className={cls('tab-group_body')}>{currentTab}</div>
        </div>
    );
};

export const Tab = (props) => {
    return <div>{props.children}</div>;
};
